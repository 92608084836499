@charset "utf-8";
/* CSS Document */

/******* Fonts Face CSS Start **********/
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

/* font-family: "MyriadPro"; font-weight: 800; */
/* font-family: "MyriadPro"; */

@font-face {
	font-family: 'GrotaSansRd';
	src: url('../fonts/GrotaSansRd-HeavyIt.eot');
	src: url('../fonts/GrotaSansRd-HeavyIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GrotaSansRd-HeavyIt.woff2') format('woff2'),
		url('../fonts/GrotaSansRd-HeavyIt.woff') format('woff'),
		url('../fonts/GrotaSansRd-HeavyIt.ttf') format('truetype'),
		url('../fonts/GrotaSansRd-HeavyIt.svg#GrotaSansRd-HeavyIt') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MyriadPro';
	src: url('../fonts/MyriadPro-Semibold.eot');
	src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
		url('../fonts/MyriadPro-Semibold.woff') format('woff'),
		url('../fonts/MyriadPro-Semibold.ttf') format('truetype'),
		url('../fonts/MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}



/********* Fonts Face CSS End **********/

/******* Common Element CSS Start ******/
body {
	background-color: #ffffff;
	color: var(--primary-color);
	font-family: "MyriadPro";
	font-size: 14px;
	line-height: 20px;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

* {
	margin: 0px;
	padding: 0px;
	outline: none !important;
}

.btn-check:focus+.btn,
.btn:focus {
	box-shadow: none !important;
}

img {
	border: 0px;
	max-width: 100%;
}

ul,
ol {
	list-style: none;
}

a,
a:hover,
a:focus,
a:focus,
a:active,
a:visited {
	outline: none;
	border: 0px;
	text-decoration: none;
}

:root {
	--primary-color: #000000;
	--secondary-color: #220071;
	--third-color: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #6074b3;
}

input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder {
	/* Firefox 19+ */
	color: #6074b3;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder {
	/* IE 10+ */
	color: #6074b3;
}

input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder {
	/* Firefox 18- */
	color: #6074b3;
}

.table-responsive,
.game-detail-left-box,
.game-detail-right-box {
	scrollbar-color: #5ce7ff rgba(76, 14, 42, 1);
	scrollbar-width: thin;
}

::-webkit-scrollbar {
	border-radius: 2px;
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-image: linear-gradient(to bottom right, #9122D8, #E345FC) !important;
	/* background: #f4ce00 !important; */
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-image: linear-gradient(to bottom right, #9122D8, #E345FC) !important;
}

/* ::-moz-scrollbar		{ border-radius: 2px; width: 8px; }
::-moz-scrollbar-track 	{ box-shadow: inset 0 0 5px grey; border-radius: 10px; }
::-moz-scrollbar-thumb 	{ background: #f4ce00 !important; border-radius: 10px; }
::-moz-scrollbar-thumb:hover	{ background: #b30000 !important;  } */
.common-link {
	color: #5ce7ff;
}

.mb-0 {
	margin-bottom: 0px !important;
}

textarea {
	resize: none;
}

.white-text,
.white-text * {
	color: #ffffff;
}

.theme-color {
	color: #180087;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure {
	margin: 0px;
	font-family: "MyriadPro";
	font-weight: 700;
}

.text-right {
	text-align: right;
}

h1 {
	font-size: 30px;
	line-height: 60px;
}

h2 {
	font-size: 40px;
	line-height: 50px;
}

h3 {
	font-size: 25px;
	line-height: 32px;
}

h4.big {
	font-size: 22px;
	line-height: 30px;
}

h4 {
	font-size: 20px;
	line-height: 25px;
}

h5 {
	font-size: 15px;
	line-height: 25px;
	font-weight: bold;
}

p {
	font-size: 15px;
	line-height: 20px;
}

p.big {
	font-weight: bold;
}

span.br {
	display: block;
}

.cmn-pad-50 {
	padding: 30px 0px;
}

.cmn-pad-top-50 {
	padding-top: 50px;
}

.pad-tb-25 {
	padding-top: 25px;
	padding-bottom: 25px;
}

.pad-btm-25 {
	padding-bottom: 25px;
}

.pad-lr-15 {
	padding-left: 15px;
	padding-right: 15px;
}

.pad-lr-25 {
	padding-left: 25px;
	padding-right: 25px;
}

.pad-50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.mar-top-50 {
	margin-top: 50px;
}

.mar-btm-50 {
	margin-bottom: 50px;
}

.mar-top-15 {
	margin-top: 15px;
}

.mar-top-25 {
	margin-top: 25px;
}

.mar-top-20 {
	margin-top: 20px;
}

.mar-tb-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.no-mar {
	margin: 0px !important;
}

label {
	margin: 0px;
}

.cmn-pad+.cmn-pad {
	padding-top: 0px;
}

.cmn-pad {
	padding-top: 50px;
	padding-bottom: 50px;
}

.theme-btn {
	padding: 15px 45px;
	color: #ffd325;
	border: 1px solid #ffd325;
	transition: 0.5s;
	text-transform: capitalize;
	background: transparent;
	display: inline-block;
}

.theme-orange-btn {
	position: relative;
	color: #573C0F;
	padding: 3px;
	text-shadow: 1px 1px #FEFF38;
	border: none;
	font-size: 21px;
	line-height: 25px;
	border-radius: 15px;
	font-weight: 800;
	/* box-shadow: 0 0 7px rgba(0, 0, 0, 0.9); */
	/* background-image: linear-gradient(0deg, #5f3d01 0%, #d09600 27%, #feff91 100%); */
}

.theme-orange-btn span {
	padding: 10px 46px;
	z-index: 2;
	position: relative;
	/* background-color: #feff90; */
	background: #5ce7ff;
	color: #fff;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
	border-radius: 15px;
	display: inline-block;
	font-family: "GrotaSansRd";
}

.theme-orange-btn::before {
	position: absolute;
	border-radius: 15px;
	content: '';
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #deae27;
	background-image: linear-gradient(180deg, #deae27 0%, #c0771c 32%, #4b2f09 85%, #301e04 100%);
}

.theme-orange-btn::after {
	position: absolute;
	border-radius: 15px;
	content: '';
	left: 9px;
	top: 4px;
	height: calc(100% - 8px);
	width: calc(100% - 18px);
	background-color: #ecc32c;
	background-image: linear-gradient(180deg, #ecc32c 0%, #d49c24 33%, #b36e1a 66%, #301d03 100%);
}

.theme-orange-btn:hover {
	color: #000;
}

.theme-btn:hover,
.theme-btn:focus {
	background: var(--third-color);
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
}

/******* Common Element CSS End *********/


.contact-left-bar {
	padding: 40px 0px;
	text-align: left;
}

.contact-left-bar .contact-info-details {
	margin-bottom: 40px;
}

.contact-left-bar .contact-info-details p {
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	margin-bottom: 20px;

}

.contact-left-bar .address-sec {
	margin-bottom: 25px;
}

.contact-left-bar .address-sec h3 {
	font-size: 24px;
	line-height: 30px;
	color: #5ce7ff;
	font-weight: 900;
	margin-bottom: 15px;
}

.contact-left-bar .address-sec h6 {
	margin-bottom: 5px;
	font-weight: 700;
}

.contact-left-bar .address-sec p {
	font-size: 14px;
	line-height: 24px;
	margin-top: 0 !important;
}

.contact-left-bar .email-sec ul {
	padding-left: 0;
}

.contact-left-bar .email-sec ul li {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
	font-size: 16px;
	line-height: 22px;
}

.contact-left-bar .email-sec ul li span {
	font-size: 18px;
	color: #5ce7ff;
	font-weight: 900;
}

.contact-left-bar .email-sec ul li a {
	color: #fff;
}

.contact-left-bar .email-sec ul li a:hover,
.contact-left-bar .email-sec ul li a:focus {
	color: #5ce7ff;
}

.contact-left-bar {
	position: relative;
	width: 100%;
}

.contact-left-bar:after {
	position: absolute;
	top: 0;
	right: -20px;
	width: 1px;
	height: 100%;
	background: #5ce7ff;
	content: '';
}

/* ----------- Color Element CSS Start -----------*/
.white-text {
	color: var(--third-color);
}

.theme-bg {
	background: var(--secondary-color);
}

.sign-up-btn {
	margin: 35px 0px;
}

.sign-up-btn .btn-success {
	background: #37a31c;
	border-color: #37a31c;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	padding: 10px 15px;
	font-size: 15px;
	line-height: 20px;
	font-weight: bold;
	transition: 0.5s;
}

.sign-up-btn .btn-success:hover,
.sign-up-btn .btn-success:focus {
	background: transparent;
	color: #37a31c;
	border: 1px solid #37a31c;
}

.cmn-banner-bg {
	padding-top: 100px !important;
	background: url(../images/dashboard-common-bg.jpg) no-repeat center center / cover !important;

}

.cmn-banner-bg * {
	font-family: "MyriadPro" !important;
}

.cmn-banner-bg .page-title h3 {
	font-size: 28px;
	font-family: "MyriadPro";
	color: #581232;
	padding-bottom: 10px;
}

.cmn-banner-bg .page-title h3::before {
	background: #581232;
}

.cmn-banner-bg .page-title {
	margin-bottom: 25px;
}


/* ----------- Color Element CSS End -----------*/
/* ----------- 		Header Section CSS Start 	-----------*/
header {
	position: relative;
}

.navbar {
	min-height: 70px;
}

.navbar-brand img {
	max-width: 200px;
	position: fixed;
	z-index: 9999;
}


.navbar-brand p {
	font-size: 10px;
}

.navbar {
	background: #0f0626;
	padding: 15px 30px 20px;
	border-radius: 0px;
	box-shadow: none;
	transition: 0.5s ease-in-out;
	min-height: auto;
	margin: 0;
	border: 0;
	position: fixed;
	/* z-index: 9999; */
	z-index: 1049;
	left: 0;
	top: 0;
	width: 100%;
	transition: 0.5s ease-in-out;
	/* background: url('../images/header-bg.png') no-repeat top center; */
	align-items: flex-start;
	/* background: rgb(65, 24, 139); */
	/* background: linear-gradient(135deg, rgba(65, 24, 139, 1) 0%, rgba(66, 27, 146, 1) 100%); */
	/* background: #f4f4f4 url(../images/img/title-bar1.png) repeat center center; */
}

header {
	z-index: 9999;
}

body.sticky .navbar {
	z-index: 1;
}

body.sticky .navbar:after {
	content: '';
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	height: 75px;
	position: absolute;
	z-index: 1;
	background: #0f0626;
}

body.sticky .navbar .navbar-collapse,
body.sticky .navbar button,
body.sticky .navbar a {
	/* position: relative; */
	z-index: 2;
}

.navbar:before {
	content: '';
	display: none;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	position: absolute;
	z-index: 1;
	/* background-image: linear-gradient(#220071, #fff, #180087); */
}

.site-content {
	padding-top: 0;
}

.navbar-fixed+.site-content {
	padding-top: 70px;

}

.navbar-default .navbar-brand {
	display: block;
	height: auto;
}

header .nav-link {
	text-transform: uppercase;
	text-transform: uppercase;
	color: var(--third-color);
	transition: 0.5s;
	font-family: "MyriadPro";
	color: #fff;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
	color: #5ce7ff
}

.app-links li.nav-item {
	padding: 0px !important;
}

header .nav-link:hover,
header .nav-link:focus {
	color: #ffffff;
}

.navbar-expand-lg .navbar-nav .nav-link {
	padding: 0px;
	margin: 0 28px;
}

.navbar-fixed.navbar {
	padding: 5px 30px 20px;
	box-shadow: 0px 0px 20px #000000;
}

.navbar-fixed {
	box-shadow: 0px 0px 20px #000000;
}

/* .navbar-fixed .navbar-brand img { max-width: 175px; } */
/* .navbar-nav 					{ transition: 0.45s; } */
.navbar-nav>.nav-item {
	padding: 0 12px;
}

.logged-in .navbar-nav>.nav-item {
	padding: 0 50px;
}

.navbar-expand-md .navbar-nav .nav-link {
	padding: 0px;
	position: relative;
}

.navbar-expand-md .navbar-nav {
	display: flex;
	align-items: center;
}

.mx-autos {
	margin-left: auto !important;
}

.navbar-nav .nav-link:before {
	content: '';
	display: block;
	left: 50%;
	bottom: -15px;
	width: 50px;
	height: 12px;
	/* background: var(--third-color); */
	background: transparent;
	position: absolute;
	transition: 0.5s;
	transition-timing-function: linear;
	transform: translateX(-50%);
}

.navbar-nav .nav-link.active:before {
	/* background: url('../images/common-line-header.png')no-repeat center center/auto 100%; */
}

.navbar-nav .nav-link.active:before,
.navbar-nav .nav-link:hover:before,
.navbar-nav .nav-link:focus:before {
	color: #E9CB47;
}

/* .navbar-fixed .app-links img 	{ max-height: 25px; } */
.navbar-fixed .navbar-nav .nav-link:before {
	bottom: -5px;
}

.download-btn {}

/* Banner */
.banner-area:after {
	content: '';
	display: block;
	left: 0;
	top: 10px;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	/* -webkit-box-shadow: inset 22px 95px 49px -73px rgba(0, 0, 0, 0.75); */
	/* -moz-box-shadow: inset 22px 95px 49px -73px rgba(0, 0, 0, 0.75); */
	/* box-shadow: inset 22px 95px 49px -73px rgba(0, 0, 0, 0.75); */
}


.banner-area {
	/* background: url(../images/img/Rummy-One-Login-BG.png) no-repeat left center/ cover; */
	background: url(../images/main-slider-bg.jpg) no-repeat left center/ cover;
	/* background: rgb(65, 60, 162);
	background: linear-gradient(135deg, rgba(65, 60, 162, 1) 0%, rgba(76, 17, 133, 1) 100%); */

	height: calc(70vh);
	position: relative;
	-webkit-box-shadow: inset -11px -75px 49px -73px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: inset -11px -75px 49px -73px rgba(0, 0, 0, 0.75);
	box-shadow: inset -11px -75px 49px -73px rgba(0, 0, 0, 0.75);
	overflow: hidden;
	padding-top: 75px;
	position: relative;
}

.banner-area::after {
	content: '';
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background: url('../images/banner-overlay.png')no-repeat center center/cover;
}

.banner-area .row .col-md-5 {
	position: relative;
	z-index: 10;
	width: 35%;
}

.banner-area .container,
.banner-area .row {
	height: 100%;
}

.banner-area .rani-img {
	max-height: 90vh;
	max-width: 100%;
	margin-top: 50px;
}

.high-light {
	padding-top: 10px;
	padding-bottom: 10px;
}

.high-light li {
	padding: 0 35px;
}

.high-light li:not(:last-child) {
	border-right: 2px solid #220071;
}

.high-light p {
	font-size: 15px;
	line-height: 20px;
	color: #ffd325;
	font-family: "MyriadPro";
	font-weight: 500;
	margin-left: 10px;
}

.banner-slider {
	margin-bottom: 0px;
}

.banner-slider figure {
	text-align: center;
}

.banner-slider .slick-slide img {
	display: inline-block;
}

/* ----------- 		Header Section CSS End 		-----------*/
/* --------  Middle section CSS Start 	--------  */
.recent-blog,
.trust-rummy-site {
	/* url(../images/trust-site-bg.png)  */
	background: #F8F8F8;
	padding: 30px 0px !important;
}

.trust-rummy-site h3 {
	color: #6E1038;
	font-family: "MyriadPro";
	font-weight: 700;
	margin-bottom: 30px;
	margin-top: 0;
}

.trust-rummy-site {
	padding: 50px 0px;
}

.trusted-items-list {
	display: flex;
	padding: 20px 20px 0;
	background: #fff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.trusted-items-list .card-block {
	width: calc(100% / 3 - 20px);
	margin-right: 20px;
	margin-top: 0;
	margin-bottom: 20px;
	background: #F8F8F8;
	border: none;
	border-radius: 0;
	box-shadow: none;
	padding: 20px;

}

.trusted-items-list .card-block:nth-of-type(3n+3) {
	margin-right: 0px;
}

.block-content {
	flex-wrap: wrap;
}

.trusted-items-list .card-block .game-logo {
	transform: none;
}

.trusted-items-list .card-block .game-logo img {
	max-width: 85px;
	transform: scale(1);
}

.trusted-items-list .card-block .block-desc {
	margin: 0;
	margin-left: 15px;
}

.trusted-items-list .card-block .block-desc h5 {
	font-size: 15px !important;
	font-family: "MyriadPro";
	color: #6E1038;
	font-weight: 700;
}

.trusted-items-list .card-block .highlight-list li {
	font-size: 12px;
	font-family: "MyriadPro";
	margin-bottom: 5px;
}



.card-block {
	background: var(--third-color);
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	margin-top: 30px;
	border-radius: 15px;
	border: 2px solid #180087;
	width: calc(100% / 2);
	max-width: 450px;
}

.game-logo {
	transform: translateX(-40px) scale(1);
	display: flex;
	align-items: center;
}

.game-logo img {
	position: relative;
	z-index: 2;
	transform: scale(1.5);
	max-width: 140px;
}

.block-content h5 {
	font-family: "MyriadPro";
	margin-bottom: 10px;
}

.block-content>ul {
	justify-content: space-around;
}

.highlight-list li {
	padding-left: 20px;
	position: relative;
	font-size: 12px;
	line-height: 17px;
	color: var(--primary-color);
}

.block-desc {
	margin: 15px;
}

.highlight-list li:not(:last-child) {
	margin-bottom: 10px;
}

.highlight-list li:before {
	left: 0;
	top: 5px;
	content: '';
	display: block;
	position: absolute;
	z-index: 1;
	width: 13px;
	height: 10px;
	background: url(../images/sign-icon.svg) no-repeat center center / cover;
}

/* SideBar Section */

.sidebar-sec {
	padding: 25px 0px 0px;
	background: url(../images/lable-background.png) no-repeat center center / 110%;
	margin-top: -20px;
}

.game-overview {
	background: #fff;
	padding: 25px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
	text-align: center;
}

.game-overview h3 {
	margin-bottom: 25px;
	text-transform: capitalize;
	color: #6E1038;
	font-family: "MyriadPro";
	font-size: 26px;
}

.game-overview p {
	color: var(--primary-color);
	text-align: justify;
	font-weight: 600;
	text-align: center;
	font-family: "MyriadPro";
	font-size: 15px;
	margin-bottom: 15px;
}

.rr-info-tabs p {
	font-family: "MyriadPro";
	font-weight: 400;
}

.game-overview p:not(:last-child) {
	margin-bottom: 15px;
}

.tabbable .nav-tabs {
	flex-direction: column;
	margin-right: 30px;
	width: 100%;
	max-width: 350px;
	border: 0px;
}

.tabbable .nav-tabs li a {
	background: #f4f4f4;
	padding: 15px;
	border: 1px solid #dedede;
	transition: 0.5s;
	color: var(--secondary-color);
	display: block;
	margin-bottom: 10px;
	cursor: pointer;
}

.tabbable .nav-tabs li a.active {
	color: var(--third-color);
	background: var(--secondary-color);
}

.sign-up-btn i {
	position: relative;
}

.sign-up-btn button:focus i,
.sign-up-btn button:hover i {
	transform: translateX(5px);
}

.sidebar-sec .tab-content {
	width: calc(100% - 350px);
}

.tab-pane p.big {
	margin: 15px 0 10px;
	font-weight: 700;
}

#tabInnerContent .table tbody .no-data {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 50%);
}

.tab-content p:not(:last-child) {
	margin-bottom: 15px;
	color: #5ce7ff;
}

.tab-contents {
	color: black !important;
}


.tab-content ul li {
	background: var(--third-color);
	box-shadow: 0px 0px 20px #cccccc;
	width: calc(100% / 3 - 15px);
	margin: 0 15px;
}

.tab-content .player-filter ul li {
	background: transparent;
	box-shadow: unset;
	width: auto;
	margin: 0px;
}

.tab-content ul h4 {
	padding: 10px;
	background: var(--secondary-color);
	color: var(--third-color);
	text-align: center;
}

.tab-content ul p {
	padding: 10px;
}

.blog-data img {
	max-width: 200px;
	padding-right: 10px;
}

.blog-data p:not(:first-child) {
	margin-top: 10px;
}

/* Player Opinion */
.player-opinion {
	background: url(../images/img/curtain_bg.png) no-repeat center center/2000px;
	padding-bottom: 25px;
}

.deposit-page {
	background: url(../images/after-login-bg.png) no-repeat center center / cover;
}

.player-opinion h3 {
	padding: 25px 0px;
}

.player-bio {
	text-align: center;
	position: relative;
}

.slick-current.slick-active .player-bio:after {
	width: 5px;
}

.user-img {
	background: no-repeat center center / cover;
	border-radius: 50%;
	height: 142px;
	width: 142px;
	display: flex;
	align-items: center;
	border: 3px solid var(--third-color);
	justify-content: center;
	margin: 0 10px;
	position: relative;
}

.user-img:before {
	position: absolute;
	content: '';
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	transition: 0.5s;
}

.slider-for.slick-slider {
	margin: 50px 0px 0px;
}

.player-bio.white-text {
	margin: 50px 0px 0px;
}

.user-name {
	margin: 20px 0px 10px;
}

.player-role {
	background: var(--third-color);
	box-shadow: 0px 0px 20px #ccc;
}

.player-role li {
	padding: 20px;
	position: relative;
	width: 50%;
}

.player-role li:first-child:before {
	content: '';
	background: url(../images/verticle-divider-short.svg) no-repeat center center / 100% 100%;
	width: 3px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.img-area {
	width: 60px;
	margin-right: 15px;
	min-width: 50px;
}

.player-role h4 {
	color: #6E1038;
	font-weight: bold;
	margin-bottom: 5px;
	font-family: "MyriadPro";
	font-weight: 700;
}

.player-role p {
	font-size: 12px;
	line-height: 16px;
	font-family: "Ubuntu", sans-serif;

}

.slick-slider div {
	outline: none;
	margin: 0 auto;
}

.slick-slide .user-img {
	transition: none;
}

.player-testimonial .slick-prev {
	z-index: 1;
	width: 40px;
	height: 40px;
}

.player-testimonial .slick-next {
	z-index: 1;
	width: 40px;
	height: 40px;
}

.player-testimonial .slick-next:before,
.player-testimonial .slick-prev:before {
	content: '';
	font: normal normal normal 14px/1 FontAwesome;
	font-weight: 900;
	color: var(--third-color);
	font-size: 40px;
	line-height: 40px;
}

.player-testimonial .slick-prev:before {
	content: '\f104';
}

.player-testimonial .slick-next:before {
	content: '\f105';
}

.player-testimonial .slick-track {
	border: 0px;
	outline: none;
}

.player-testimonial .slick-dots {
	bottom: -30px;
}

.player-testimonial .slick-dots li,
.player-testimonial .slick-dots li button {
	width: 10px;
	height: 10px;
	padding: 0px;
}

.player-testimonial .slick-dots li button::before {
	font-size: 10px;
	padding: 0px;
	line-height: 10px;
	height: 10px;
	width: 10px;
}

.player-testimonial .slick-dots li button::before,
.player-testimonial .slick-dots li.slick-active button::before {
	color: var(--third-color);
	transition: 0.5s;
}

.player-testimonial .slider-nav.slider {
	margin: 0px;
}

.player-testimonial .profile-detail {
	transform: scale(0.7);
	transition: 0.5s;
	cursor: pointer;
	outline: none;
}

.player-testimonial .slick-current.slick-active .profile-detail {
	transform: scale(1);
}

.player-testimonial .slick-current.slick-active .user-img:before {
	background: rgba(0, 0, 0, 0);
}

/* --------  Middle section CSS End 	--------  */
/***** Bottom section CSS Start *******/
footer {
	position: relative;
	z-index: 10;
	overflow: hidden;
}


.footer-content {
	background: #0f0626;
	padding: 25px 0px;
}



.footer-logo a {
	display: block;
}

.footer-logo img {
	margin-bottom: 15px;
	max-width: 200px;
}

.support-logo-icon li:not(:last-child) {
	margin-right: 25px;
}

.support-logo-icon img {
	max-width: 100px;
}

.follow-text {
	text-transform: uppercase;
	color: #ffd325;
	font-weight: bold;
}

.social-icon {
	margin-top: 10px;
}

.social-icon li:not(:last-child) {
	margin-right: 15px;
}

.social-icon li a {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	text-align: center;
	color: var(--third-color);
	transition: 0.5s;
	line-height: 30px;
	display: block;
}

.footer-widget1 .link:hover {
	color: rgba(255, 255, 255, 0.7);
}

.fb a {
	background: #3b5998;
}

.tw a {
	background: #00acee;
}

.insta a {
	background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.yt a {
	background: #ff0000;
}

.social-icon li a:hover,
.social-icon li a:focus {
	background: var(--primary-color)
}

.widget-title {
	text-transform: uppercase;
	color: #d025ff;
	font-weight: bold;
	font-family: "MyriadPro";
	font-weight: 700;
	text-align: center;
	letter-spacing: 1.5px;
}

.footer-widget2 {
	text-align: center;
}


.quick-link-list {
	flex-wrap: wrap;
	margin-top: 15px;
	justify-content: flex-end;
}

.quick-link-list li {
	width: 50%;
	margin-bottom: 10px;
}

.quick-link-list li a {
	text-transform: capitalize;
	color: var(--third-color);
	font-size: 12px;
	line-height: 14px;
	transition: 0.5s;
	font-family: "MyriadPro";
	font-weight: 500;
}

.app-links li:not(:last-child) {
	margin-right: 25px;
}

.app-links img {
	max-height: 45px;
	transition: 0.5s;
}

.app-links a:hover,
.app-links a:focus {
	opacity: 0.7;
}

.quick-link-list li a:hover,
.quick-link-list li a:focus {
	opacity: 0.7;
}

.partner-listing {
	flex-wrap: wrap;
	margin-top: 10px;
}

.partner-listing img {
	max-width: 75px;
}

.copy-text {
	font-size: 12px;
	line-height: 16px;
	padding: 10px;
	color: #fff;
	background: #0f0626;
	font-family: "MyriadPro";
	border-top: 1px solid #57545e;
}

.copy-text p {
	font-family: "MyriadPro";
}

.payment-title {
	margin: 20px 0px;
}

.partner-listing li {
	margin: 0px 5px 5px 0px;
}

/****** Bottom section CSS End *******/
/* Tutorial Page */
.desc-overview p:not(:last-child) {
	margin-bottom: 15px;
}

.desc-overview p {
	color: var(--third-color);
	text-align: justify;
}

.tutorials-block {
	background: url(../images/tutorial-bg.jpg) no-repeat center center / cover;
}

.page-title {
	margin-bottom: 50px;
	color: #ffd325;
	text-align: center;
}

.page-title h3 {
	display: inline-block;
	position: relative;
	padding-bottom: 20px;
	text-transform: uppercase;
}

.page-title h3:before {
	content: '';
	display: block;
	left: 50%;
	bottom: 0;
	height: 2px;
	/* background: #ffd325; */
	position: absolute;
	width: calc(100% - 50%);
	transform: translateX(-50%);
	margin-top: 5px;
}

.tutorials-block .img-thumb {
	display: block;
}

.tutorials-block ul {
	margin: 0 -15px;
}

.tutorials-block li {
	width: calc(100% / 2);
	margin: 0 15px 15px;
}

.thumbnail-section img {
	max-width: 100px;
	margin: 0 auto;
}

.fancybox-slide>* {
	border: 0px;
	padding: 0;
	margin: 0px;
}

/* How To Play Block */
.how-to-play-block {
	position: relative;
	background: url(../images/after-login-bg.png) no-repeat center center / cover;
	overflow: hidden;
}

.middle-bar img {
	max-width: 150px;
	margin: 0 -25px -50px 0px;
}

.middle-bar {
	text-align: right;
	transition: 0.5s;
}

.middle-bar.scrollfix-bottom {
	margin-bottom: 0px;
}

.parallax {
	position: relative;
	background-attachment: fixed;
	background-position: center 0;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
}

.image-banner {
	text-align: center;
}

.image-banner img {
	width: 100%;
	max-width: 90%;
	margin: 20px auto;
}

.highlighted-sec p {
	color: var(--primary-color);
	margin-top: 15px;
}

.how-play-content ol {
	margin: 25px 0px;
	padding-left: 20px;
}

.how-play-content li {
	list-style: decimal;
}

.how-play-content li:not(:last-child) {
	margin-bottom: 15px;
}

.game-tab-panel .tab-pane ul li {
	box-shadow: none;
	width: 100%;
	margin: 0 0 5px 20px;
	list-style: disc;
}

/* FAQ's Page */
.faq-title {
	color: #fff !important;
}

.faq-title::before {
	background: #fff !important;
}

.faq-page .faq-title {
	position: absolute;
	left: 50%;
	top: -40px;
	width: 100%;
	max-width: 400px;
	transform: translateX(-50%);
	background: url('../images/common-header-bg.png') no-repeat;
	background-size: 100% 100%;
	padding: 20px 0px;
	z-index: 1;
	text-align: center;
	font-size: 24px;
	font-weight: 600;
	font-family: "MyriadPro";
	color: #ffd325 !important;
}

.faq-content {
	background: url('../images/common-bg.png') no-repeat;
	background-size: 100% 100%;
	padding: 50px 90px 80px;
	margin: 80px 0;
	z-index: 2;
	position: relative;
}

.faq {
	/* background-color: rgb(255, 255, 255); */
	margin-top: 25px;
}

.faq>div>div {
	color: #0f0826;
}

.card-header {
	border: none;
}

.faq-content .faq .card-header {
	background-color: #581232;
}

.faq-content .faq .card-header .btn-link {
	color: white;
}

.faq-content .faq .card-header .btn-link::selection {
	background-color: transparent;
}

.faq-content .faq .card-header .btn-link:before {
	color: white;
	top: 0;
}

#accordion {
	padding: 0px 0px;
	border: none;
}

.card {
	margin-bottom: 25px;
	border: 0px;
	background: #f8f8f8;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-header .btn-link {
	text-decoration: none;
	width: 100%;
	text-align: left;
	color: #000;
	font-family: "MyriadPro";
	padding: 0 20px 0 0;
	font-weight: 600;
	font-size: 16px;
}

.rr-info-tabs {
	margin-top: 20px;
}

.accordion__panel {
	font-family: "MyriadPro";
	font-size: 14px;
	padding: 10px;
	background: #f2f2f2;
}

.card-header .btn-link:before {
	content: '\f106';
	display: block;
	right: 20px;
	top: 5px;
	position: absolute;
	font: normal normal normal 14px/1 FontAwesome;
	font-weight: 900;
	color: var(--secondary-color);
	transition: 0.5s;
	font-size: 30px;
	line-height: 30px;
	transform: rotate(180deg);
}

.card-header .btn-link.active:before {
	content: '\f107';
}

/* Blog Page */
.blog-hero {
	background: no-repeat center center/ cover;
	height: 250px;
}

.blog-content {
	border: 1px solid #ffd325;
	transition: 0.5s;
	margin-bottom: 30px;
}

.blog-content:hover {
	box-shadow: 0px 0px 20px #999999;
}

.blog-desc {
	background: var(--secondary-color);
	padding: 20px 15px;
}

.date-icon,
.user-icon {
	position: relative;
	color: #ffd325;
	padding-left: 25px;
}

.user-icon:before,
.date-icon:before {
	content: '\f073';
	display: block;
	left: 0;
	top: 50%;
	color: #ffd325;
	font: normal normal normal 14px/1 FontAwesome;
	font-weight: 900;
	position: absolute;
	transform: translateY(-50%);
	font-size: 20px;
	line-height: 20px;
}

.user-icon:before {
	content: '\f007';
}

.short-desc {
	color: var(--third-color);
	margin: 15px 0px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	height: 40px;
}

/* Blog Inner */
.blog-inner-view {
	background-image: linear-gradient(#1435bb, #09195f);
}

.blog-inner-content {
	box-shadow: 0px 0px 20px #000000;
}

.blog-inner-content h3.title {
	color: var(--secondary-color);
}

.blog-inner-content .blog-hero {
	height: 550px;
	border: 5px solid #ffd325;
}

.blog-inner {
	background: var(--third-color);
}

.cmn-content p {
	color: var(--third-color);
}

.cmn-content p:not(:last-child) {
	margin-bottom: 15px;
}

.blog-inner-desc ul {
	margin-bottom: 25px;
}

.blog-inner-desc .user-icon::before,
.blog-inner-desc .date-icon::before,
.blog-inner-desc ul li {
	color: var(--secondary-color);
}

.blog-inner-desc .cmn-content p {
	color: var(--primary-color);
}

.cmn-content ol {
	list-style: decimal;
}

.cmn-content ul {
	list-style: disc;
}

.cmn-content ol,
.cmn-content ul {
	padding-left: 20px;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 15px;
}

.cmn-content ol li:not(:last-child),
.cmn-content ul li:not(:last-child) {
	margin-bottom: 15px;
}

.cmn-content ul li {
	color: var(--primary-color);
}

.cmn-content ol h5,
.cmn-content ul h5 {
	margin-bottom: 10px;
}

.recent-blog {
	padding: 30px 20px;
}

.recent-blog .social-icon {
	margin-bottom: 30px;
}

.blog-inner-content .blog-hero.recent-post {
	height: 250px;
}

/* About Us Page */

.about-page-section {
	position: relative;
}

.about-page-section .page-title {
	display: inline-block;
	background: url(../images/common-header-bg.png) no-repeat;
	font-size: 28px;
	background-size: 100% 100%;
	margin: 0;
	min-width: 380px;
	padding: 25px 20px 30px;
	top: 3%;
	left: 95px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	position: absolute;
	color: white;
	z-index: 1;
	font-family: "GrotaSansRd" !important;
	text-shadow: 3px 0 #0087f9, -3px 0 #0087f9, 0 3px #0087f9, 0 -3px #0087f9, 2px 2px #0087f9, -2px -2px #0087f9, 2px -2px #0087f9, -2px 2px #0087f9;
}

.about-page-section .page-title.refund-title {
	padding: 25px 45px 30px;
	font-size: 25px;
}

.contact-area,
.about-us-content {
	background: url(../images/dashboard-bg.png) no-repeat;
	background-size: 100% 100%;
	padding: 120px 90px 80px;
	font-family: "MyriadPro";
	font-weight: 600;
	position: relative;
	z-index: 2;
	background-position: top center;
}

.contact-area p,
.about-us-content p {
	color: #fff;
	font-family: "MyriadPro";
	font-size: 16px;
	font-weight: 600;
}

.about-us-content .content-sec {
	max-height: 500px;
	overflow: hidden;
	overflow-y: auto;
	color: white;
}

.about-us-content p:not(:first-child) {
	margin-top: 15px;
}

.desclaimer {
	margin-top: -9px;
	z-index: 2;
	position: relative;
}

.about-us-content p a,
.desclaimer ul li a {
	color: #ffffff;
	font-weight: 600;
	text-decoration: underline;
}

.about-us-content .about-details-sec {
	max-height: 500px;
	overflow: hidden;
	overflow-y: auto;
	color: #fff;
	min-height: 450px;
}



@media only screen and (max-width: 1023px) {
	.about-us-content .about-details-sec {
		margin-top: 30px !important;
		/* text-align: justify; */
	}

	.faq {
		margin-top: 40px;
	}

	.about-us-content .content-sec {
		margin-top: 40px;
	}

	.about-page-section .page-title {
		top: 14px !important;
	}

	.report-probblem-sec.about-page-section .report-inner-box>div {
		margin-top: 20px;
	}

	.contact-support .contact-support-title {
		top: -20px !important;
	}

	.contact-support .contact-support-title {
		top: -20px !important;
	}
}




.desclaimer ol,
.desclaimer ul {
	padding-left: 25px;
	margin-bottom: 15px;
}

.desclaimer ul li {
	list-style: disc;
	font-family: "MyriadPro";
	font-weight: 400;
}

.desclaimer ol>li {
	list-style: decimal;
}

.desclaimer ol li:not(:last-child),
.desclaimer ul li:not(:last-child) {
	margin-bottom: 15px;
}

/* Contact Page */
.contact-area h4 {
	color: var(--secondary-color);
}

.form-inline .form-group {
	width: 100%;
	margin-bottom: 15px;
	align-items: flex-start;
	display: flex;
}

.form-inline label {
	width: 150px;
	justify-content: flex-start;
}

.form-inline .form-control {
	width: calc(100% - 150px);
}

.form-inline .theme-btn {
	margin-left: 150px;
	background: var(--secondary-color);
	text-transform: uppercase;
}

.form-inline .theme-btn:hover,
.form-inline .theme-btn:focus {
	color: var(--third-color);
}

.contact-detail {
	margin: 50px 0px;
}

.contact-detail li {
	position: relative;
	padding-left: 40px;
	transition: 0.5s;
}

.contact-detail li a {
	color: var(--primary-color);
	transition: 0.5s;
	position: relative;
	display: inline-block;
	padding-bottom: 10px;
	font-family: "MyriadPro";
	font-weight: 400;
}

.contact-detail li a:before {
	content: '';
	display: block;
	left: 0;
	bottom: 0;
	height: 1px;
	width: 0;
	background: #ffd325;
	transition: 0.5s;
	position: absolute;
}

.contact-detail li a:hover {
	font-weight: bold;
}

.contact-detail li a:hover:before {
	width: 100%;
}

.contact-detail h5 {
	color: var(--secondary-color);
}

.contact-detail li:not(:last-child) {
	margin-bottom: 20px;
}

.contact-detail li:before {
	content: '';
	display: block;
	left: 0;
	top: 5px;
	position: absolute;
	font: normal normal normal 14px/1 FontAwesome;
	font-weight: 900;
	color: var(--secondary-color);
	font-size: 20px;
}

.contact-detail .mobile:before {
	content: '\f095';
}

.contact-detail .email:before {
	content: '\f0e0';
}

.contact-detail .location:before {
	content: '\f041';
}

/* How TO Play */

.how-to-play-page .how-to-play-title {
	display: inline-block;
	background: url(../images/common-header-bg.png) no-repeat;
	font-size: 28px;
	background-size: 100% 100%;
	margin: 0;
	min-width: 380px;
	padding: 30px 0 35px;
	top: 15px;
	left: 95px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	position: absolute;
	color: white;
	font-family: "GrotaSansRd" !important;
	text-shadow: 3px 0 #0087f9, -3px 0 #0087f9, 0 3px #0087f9, 0 -3px #0087f9, 2px 2px #0087f9, -2px -2px #0087f9, 2px -2px #0087f9, -2px 2px #0087f9;
}

.how-to-play-content {
	background: url(../images/dashboard-bg.png) no-repeat;
	background-size: 100% 100%;
	padding: 110px 90px 80px;
	font-family: "MyriadPro";
	font-weight: 600;
	position: relative;
	z-index: 2;
	color: white;
}

.how-to-play-content .how-to-play {
	max-height: 400px;
	overflow: hidden;
	overflow-y: auto;
}

.how-to-play-content .how-to-play .card-header {
	background-color: #ffffff;
}

.how-to-play-content .highlighted-sec p {
	color: white;
}

.how-to-play-area>.tabbable {
	flex-direction: column;
}

.how-to-play-area>.tabbable .nav-tabs {
	max-width: 100%;
	border-bottom: 1px solid #ececec;
	text-align: center;
}

.how-to-play-area>.tabbable .nav-tabs li a.active {
	color: #ffd325;
	border-color: var(--secondary-color);
}

.how-to-play-area .tab-content {
	width: 100%;
	margin-top: 15px;
}

.how-to-play-area>.tabbable .nav-tabs li+li a {
	border-left: 0px;
}

.how-to-play-area>.tabbable .nav-tabs li a {
	margin-bottom: 0px;
	cursor: pointer;
}

.hero-content .company-name {
	color: #01875F;
}

.hero-content>figure>img {
	max-width: 75px;
}

.hero-content h2 {
	margin-bottom: 5px;
}

.hero-content .company-desc {
	color: #7B7B7B;
}

.stats-content li p:last-child {
	font-size: 10px;
	line-height: 15px;
	color: #7b7b7b;
}

.stats-content li:not(:first-child) {
	border-left: 2px solid #d9d9d9;
	padding-left: 20px;
}

.rating {
	border: 1px solid #000000;
	padding: 5px;
	display: inline-block;
}

.download-btn a {
	padding: 15px 45px;
	display: block;
	color: #ffffff;
	font-size: 18px;
	line-height: 22px;
	background: #5ce7ff;
	text-align: center;
	border-radius: 8px;
}

.game-screenshot li {
	width: calc(100% / 3 - 10px);
}

.game-screenshot li img {
	border-radius: 10px;
}

.review-content p {
	color: #7B7B7B;
	margin-bottom: 15px;
}

.review-content ol {
	margin-top: 25px;
}

.review-content ol li {
	margin-bottom: 15px;
	color: #7B7B7B;
}

.new-highlight-list ul {
	padding-left: 15px;
}

.new-highlight-list ul li {
	list-style: disclosure-closed;
}

.rating-title {
	padding-top: 25px;
}

.star-list {
	color: #01875f;
}

.rating-list li {
	margin: 10px 0px;
}

.rating-list p {
	width: 35px;
}

.rating-list .progress {
	width: calc(100% - 35px);
}

.progress-bar {
	background: #01875f;
}

.desclaimer h3 {
	text-transform: capitalize;
}

/** after login **/
/* lobby */
.nav-tabs .nav-link {
	border: none;
}

.logged-in {
	/* margin-bottom: 80px; */
}

.after-login {
	padding-top: 0px;
	list-style-type: none;
	margin-right: 0 !important;
}

.after-login .navbar-nav {
	align-items: center;
}

.navbar-nav .message .nav-link img {
	filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.3));
}

.navbar-nav>.nav-item.add-wallet {
	display: flex;
}

.navbar-nav>.nav-item.add-wallet>div,
.club-info span {
	display: flex;
	margin: 0 5px;
	border-radius: 5px;
	padding: 5px;
	align-items: center;
	/* background-color: #14006A; */
	font-size: 15px;
	line-height: 17px;
	font-weight: 500;
	color: #fff;
	font-family: "MyriadPro";
	/* background: url(../images/img/BG_.png) no-repeat center / cover; */
	gap: 10px;

}

.navbar-nav>.nav-item.add-wallet>div span {
	padding: 0 10px;
}

.navbar-nav>.nav-item.add-wallet>div a {
	display: inline-block;
}

.navbar-nav>.nav-item.username>a {
	display: inline-flex;
	font-size: 14px;
	line-height: 17px;
	color: #5ce7ff;
	text-transform: none;
	align-items: center;
	max-width: 180px;
	margin-right: 15px;
}

.navbar-nav>.nav-item.username>a img,
.navbar div ul .nav-item.username>a img {
	margin-right: 15px;
	filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.3));
}

.navbar-nav>.nav-item.username>a::before,
.navbar-nav>.nav-item.message>a::before {
	content: none;
}

.navbar-nav>.nav-item.logout button {
	padding: 8px 20px;
	background-color: #A00000;
	border: none;
	border-radius: 6px;
	font-size: 15px;
	line-height: 18px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #fff;
}

.logoutbtn {
	padding: 13px 26px;
	background-color: #A00000;
	border: none;
	border-radius: 6px;
	font-size: 15px;
	line-height: 18px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #fff;
}

.navbar-nav>.nav-item:nth-last-child(-n+3) {
	padding: 0 12px;
}

.logged-in .navbar-nav>.nav-item.add-wallet,
.logged-in .navbar-nav>.nav-item.club-wrap {
	padding: 0 12px;
}

.navbar-nav>.nav-item:last-child {
	padding-right: 0 !important;
}

.nav-tabs .nav-link.common-btn {
	background: #000;
}

.after-login main,
.after-login .main-content {
	display: flex;
	padding: 20px 0;
	padding-top: 200px;
	background: url(../images/dashboard-common-bg.jpg) no-repeat center center / cover;
	/* background: url(../images/after-login-bg.png) no-repeat center / cover; */
	/* height: calc(100vh - 80px); */
	height: 100vh;
	flex-direction: column;
	overflow-y: auto;
}

.table-section {
	margin: auto 0;
	flex: 1;
	/* height: calc(100% - 65px); */
}

.table-section .container,
.table-section .container .tab-main {

	height: 100%;
}

.table-section .container .tab-main {
	background: url('../images/lobby-bg.png')no-repeat center center/100% 100%;
	padding-top: 65px;
	position: relative;
}

.lobby-headeing.table-heading {
	position: absolute;
	top: 32px;
	left: 100px;
	/* transform: translateX(-50%); */
	width: 100%;
	max-width: 400px;
	background: url('../images/common-header-bg.png') no-repeat;
	background-size: 100% 100%;
	background-position: center;
	padding: 30px 0;
	font-size: 35px;
	line-height: 30px;
	font-weight: 700;
	font-family: "GrotaSansRd";
	color: #fff;
	text-align: center;
	z-index: 1;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;

}

.point-table.table-section .container .tab-main {
	padding-bottom: 130px;
	display: flex;
	align-items: center;
}

.point-rummy-sec {
	max-width: 70%;
	margin: 0 auto;
	/* background: url('../images/points-rummy-bg.png')no-repeat center center/cover; */
	padding: 20px 40px;
	width: 100%;
}

.points-rummy-inner-sec {
	max-width: 70%;
	margin: 0 auto;
	max-height: 335px;
	overflow-y: auto;
}

.points-tabs .nav-tabs {
	background: url('../images/tabbar-bg.png')no-repeat center center/100% 100% !important;
	/* max-width: 60%; */
	margin: 0 auto;
	flex-wrap: wrap;
	gap: 0 !important;
	display: flex;
	padding: 10px;
}

.points-tabs .nav-tabs .nav-item {
	background: transparent !important;
	width: 50% !important;
	margin: 0 !important;
	padding: 0 !important;

}

.points-tabs .nav-tabs .nav-item:nth-child(even) a.nav-link.active {
	background: url('../images/tab-bg.png')no-repeat center center/100% 100% !important;
}

.points-tabs .nav-tabs li a.nav-link.active {
	background: url('../images/tab-bg.png')no-repeat center center/100% 100% !important;
}

.points-tabs .nav-tabs li a.nav-link {
	background: transparent !important;
}

.points-tabs .nav-tabs li a.nav-link:hover {
	color: #fff !important;
}

.points-tabs .nav-tabs li a.nav-link::before {
	display: none;
}

.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
	max-width: 60%;
	margin: 0 auto;
	margin-top: 30px;
}

.points-tabs .cust-dropdown-box .dropdown-kyc-box>div:first-of-type {
	background: rgba(9, 9, 39, 0.8);
	border: none;
	color: #fff;
	padding: 10px 15px;
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
	border-radius: 40px;
}

.points-tabs .cust-dropdown-box .dropdown-kyc-box>div:first-of-type+div {
	margin: 0;
	background: rgba(9, 9, 39, 1);
	color: #fff !important;
	text-align: center;
}

.points-tabs .cust-dropdown-box .dropdown-kyc-box>div:first-of-type+div div {
	background: rgba(9, 9, 39, 1);
	cursor: pointer;
	color: #fff !important;
	text-align: center;
	padding-right: 25px;

}

.points-tabs .cust-dropdown-box .dropdown-kyc-box>div:first-of-type+div>div>div:hover {
	background: #fff;
	color: rgba(9, 9, 39, 1) !important;
}

.points-tabs .cust-dropdown-box .dropdown-kyc-box div {
	color: #fff;
	font-weight: 700;
	font-size: 16px;
	z-index: 99;
	text-align: center;
	justify-content: center;
}

.points-tabs .cust-dropdown-box .dropdown-kyc-box div span {
	display: none;
}

.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn {
	margin: 0px auto;
	margin-top: 40px;
	background: url('../images/common-blue-btn.png')no-repeat center center/100% 100%;
	min-height: 50px;
	padding: 15px 15px 20px;
	font-size: 30px;
	color: #fff;
	text-align: center;
	z-index: 1;
	max-width: 250px;
	width: 100%;
	font-family: "GrotaSansRd";
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96, 2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
}

.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn .main-layer {
	padding: 0;
}

.tab-main-header {
	display: flex;
	/* background-color: #200052; */
	justify-content: space-between;
	align-items: center;
	position: relative;
	/* background: url('../images/img/title-bar1.png') no-repeat center / cover; */


}

.tab-main-header>* {
	position: relative;
	z-index: 1;
}

.tab-main-header::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	mix-blend-mode: overlay;
	/* background: linear-gradient(#000 0%, #1a1a1a 9.46%, #939393 54.73%, #e0e0e0 75.79%, #fff 100%); */
	opacity: 0.1;
}

.tab-main-header .nav-tabs {
	border: none;

	/* border: 2px solid white; */
	/* border-radius: 10px; */
	/* padding: 5px; */

}

.tab-main-header .nav-tabs .nav-item {
	margin: 0;
	padding: 5px 12px;
}

.tab-main-header .nav-tabs .nav-item:first-child {
	padding-left: 20px;
}

.tab-main-header .nav-tabs .nav-item .nav-link {
	padding: 16px 28px;
	font-size: 22px;
	line-height: 25px;
	font-weight: 500;
	color: #fff;
	background: none;
	border: none;
	border-bottom: 5px solid transparent;
	background-color: #3f3083;
	/*background-color: blueviolet;
    */
	border: 2px;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
}

.tab-main-header .nav-tabs .nav-item .nav-link.active {
	/*border-color: #E6D601;
	*/
	/* background-color:#cba600; */
	/* background-image: linear-gradient(#cba600, #cba600); */
	border: 2px;
	border-radius: 30px;
	background: transparent;
	color: #f8a407;
	font-family: "MyriadPro";
	font-size: 30px;
	font-weight: 700;
}

.nav-item::marker {
	display: none;
}

.contact-info {
	margin-top: 40px;
	font-size: 22px;
	line-height: 25px;
	font-weight: 500;
	color: #fff;
}

.contact-info a {
	color: #f4ce00;
}

.modal-btns {
	padding-right: 10px;
}

.modal-btns .btn {
	margin: 0 10px;
}

.yellow-btn {
	padding: 9px 20px;
	/* padding: 38px 15px; */
	font-weight: 800;
	border-radius: 9px;

	font-size: 22px;
	line-height: 25px;
	color: #fff !important;
	font-family: "MyriadPro";
	position: relative;
	border: none;
	/* min-height: 65px; */
	/* border: 2px solid #f4ce00; */
	background: transparent !important;
	text-align: center;
	justify-content: centerMy Joined Games;
}

.yellow-btn::after {
	width: 100%;
	height: 100%;
	/* background: url('../images/btn/edit_button.png')no-repeat center center/150% auto; */
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	background: url('../images/tab-bg.png')no-repeat center center/100% 100%;
	color: #fff !important;
}

.btn-secondary.yellow-btn:hover {
	border-color: #f4ce00;
}

.yellow-btns {
	padding: 12px 20px;
	/* padding: 38px 15px; */
	font-weight: 800;
	border-radius: 9px;
	background-color: #f4ce00;
	font-size: 22px;
	line-height: 25px;
	color: #5F4501;
	font-family: "MyriadPro";
	position: relative;
	border: none;
}


.yellow-btn>* {
	position: relative;
	z-index: 2;
}

/* .yellow-btn:hover 					{ background:linear-gradient(rgb(166, 185, 219) 0%, rgba(166,185,219, 0.5) 13.64%, rgba(166,185,219, 0.4) 43.24%, rgba(166,185,219, 0.15) 86.21%, rgba(166,185,219, 0) 100%) ; } */
.modal-btns .btn span {
	position: relative;
	z-index: 1;
}

/* .modal-btns .btn::before  */
.yellow-btn::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	/* background: linear-gradient(#fff 0%, rgba(255, 255, 255, 0.89) 13.64%, rgba(255, 255, 255, 0.6) 43.24%, rgba(0, 0, 0, 0.45) 86.21%, rgba(0, 0, 0, 0) 100%); */
	mix-blend-mode: overlay;
	border-radius: 9px;
}

.tab-main-body {
	/* background-color: #7443de; */
	position: relative;
	/* height: calc(100% - 147px); */
	/* background: url('../images/img/curtain_bg.png') no-repeat center / 1320px; */

}

.tab-main-body::before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/* background: linear-gradient(#7443de 0%, rgba(20, 53, 169, 0.42) 56.89%, rgba(20, 53, 169, 0) 100%); */
	z-index: 0;
	display: none;
}

.tab-content.tab-inner {
	position: relative;
	z-index: 1;
	height: 100%;
}

.tab-content.tab-inner .tab-pane {
	height: 100%;
}

.tab-content.tab-inner .tab-pane .tab-content {
	height: calc(100% - 94px);
}

.tab-inner .tab-pane .tab-inner-header {
	display: flex;
	padding: 15px 20px;
	justify-content: space-between;
	align-items: center;
	position: relative;
	background: transparent;
}

.tab-inner .tab-pane .tab-inner-header h2 {
	color: #E9CB47;
	font-size: 28px;
}

.tab-inner .tab-pane .tab-inner-header::before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: transparent;
	opacity: 0.1;
	display: none;
}

.tab-inner .tab-pane .nav-tabs {
	border: none;
	position: relative;
	z-index: 1;
	/* border: 2px solid #82adff; */
	border-radius: 6px;
}

.tab-inner .tab-pane .nav-tabs .nav-item {
	margin: 0;
	border: none;
	padding: 20px 10px;
	width: auto;
	box-shadow: none;
	background: none;
	/* background-color: #008C00; */
}

.tab-inner .tab-pane .nav-tabs .nav-item {
	background-color: #553d9e;
	font-size: 22px;
	line-height: 25px;
	font-weight: 500;
	padding: 16px 15px;
	border-radius: 0 !important;
	margin: 1px;
	color: white;
}

.tab-inner .tab-pane .nav-tabs .nav-item.active {
	background-color: #0ad35ea5;

}

/* .tab-inner .tab-pane .nav-tabs .nav-item .nav-link, */
.common-btn {
	/* display: inline-flex;
	justify-content: center;
	padding: 17px 20px;
	min-width: 188px;
	border: none;
	background-color: #A6B9DB;
	font-size: 18px;
	line-height: 18px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #344769;
	border-radius: 9px !important;
	position: relative;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.9);
	max-width: 190px;
	text-align: center;
	align-items: center;
	height: 100%;
	background: url(../images/btn/edit_button.png) no-repeat center center / cover; */

	display: inline-flex;
	justify-content: center;
	padding: 15px 20px;
	min-width: 150px;
	background-color: #A6B9DB;
	font-size: 18px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #fff;
	border-radius: 9px !important;
	text-align: center;
	/* background: url(../images/btn/edit_button.png) no-repeat center center / cover; */
	background: #5ce7ff;
	text-shadow: 3px 0 #3c5c8a, -3px 0 #3c5c8a, 0 3px #3c5c8a, 0 -3px #3c5c8a,
		2px 2px #3c5c8a, -2px -2px #3c5c8a, 2px -2px #3c5c8a, -2px 2px #3c5c8a;
	font-family: "GrotaSansRd";
	margin: 10px;

}

.btn.disabled, .btn:disabled, fieldset:disabled .btn,
.btn:hover {
	background: #5ce7ff;
}

.practice .common-btn {
	display: inline-flex;
	padding: 24px 3px;
}

.tab-inner .tab-pane .nav-tabs .nav-item:last-child .nav-link {
	z-index: 2;
}

.tab-inner .tab-pane .nav-tabs .nav-item .nav-link:hover {
	color: #5F4501;
}

.tab-inner .tab-pane .nav-tabs .nav-item .nav-link span {
	position: relative;
	z-index: 1;
}

/* .tab-inner .tab-pane .nav-tabs .nav-item .nav-link.active, */
.common-btn.active {
	/* background-color: #f4ce00 !important; */
	/* color: #5F4501 !important; */
}

/* .tab-inner .tab-pane .nav-tabs .nav-item .nav-link::before, */
/* .common-btn::before, */
/* .tab-inner .tab-pane .nav-tabs .nav-item .nav-link::after, */
/* .common-btn::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(rgb(166, 185, 219) 0%, rgba(166, 185, 219, 0.5) 13.64%, rgba(166, 185, 219, 0.4) 43.24%, rgba(166, 185, 219, 0.15) 86.21%, rgba(166, 185, 219, 0) 100%);
	mix-blend-mode: overlay;
	border-radius: 9px;
} */

.tab-inner .tab-pane .nav-tabs .nav-item .nav-link.active::before,
.common-btn.active::before {
	background: linear-gradient(#fff 0%, rgba(255, 255, 255, 0.89) 13.64%, rgba(255, 255, 255, 0.6) 43.24%, rgba(0, 0, 0, 0.45) 86.21%, rgba(0, 0, 0, 0) 100%);
}

.tab-inner .tab-pane .nav-tabs .nav-item .nav-link.active::after,
.common-btn.active::after {
	content: none;
}

.list-unstyled.actions {
	display: flex;
	position: relative;
	z-index: 1;
}

.list-unstyled.actions li {
	background: none;
	width: auto;
	box-shadow: none;
}

.list-unstyled.actions li a {
	display: block;
	cursor: pointer;
}

.list-unstyled.actions li a img {
	max-width: 35px;
}

/* .player-filter 						{ margin: 0 -10px; width: calc(100% + 20px);} */

#filter {
	display: block !important;
}

.player-filter .card.card-body>* {
	position: relative;
	z-index: 1;
}

.player-filter .card.card-body {
	margin: 0;
	padding: 12px 40px;
	background-color: #440b25;
	border-radius: 0px;
	position: relative;
	flex-direction: row;
	z-index: 999;
	max-width: 98%;
	margin: 0px auto;
	top: -15px;
}

.player-filter .card.card-body::before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/* background: linear-gradient(#000 0%, #1a1a1a 9.46%, #939393 54.73%, #e0e0e0 85.79%, #fff 100%); */
	opacity: 0.2;
	mix-blend-mode: overlay;
}

.player-filter .card.card-body .dropdown .btn {
	padding: 10px;
	background: #f4ce00;
	font-size: 19px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 800;
	position: relative;
	color: #5F4501;
	border-radius: 9px;
}

.player-filter .card.card-body .dropdown .btn span {
	position: relative;
	z-index: 1;
}

.player-filter .card.card-body .dropdown .btn::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(#fff 0%, rgba(255, 255, 255, 0.89) 13.64%, rgba(255, 255, 255, 0.6) 43.24%, rgba(0, 0, 0, 0.45) 86.21%, rgba(0, 0, 0, 0) 100%);
	mix-blend-mode: overlay;
	border-radius: 9px;
}

.player-filter .card.card-body .dropdown .btn::after {
	border: none;
	width: 19px;
	height: 10px;
	background: url('../images/dropdown-arrow.png') no-repeat center / cover;
	vertical-align: middle;
}

.player-filter .card.card-body .reset {
	margin-left: 25px;
	border: none;
	background: none;
}

.player-filter .card.card-body .dropdown-menu.show {
	padding: 12px 20px;
	border-radius: 10px;
	background-color: #001466;
}

.player-filter .card.card-body .dropdown-menu.show .custom-control.custom-checkbox:not(:last-child) {
	margin-bottom: 10px;
}

.player-filter .card.card-body .dropdown-menu.show .custom-control-label {
	padding-left: 15px;
	font-size: 20px;
	line-height: 25px;
	font-weight: 500;
	color: #fff;
	cursor: pointer;
	font-family: "MyriadPro";
}

.dropdown-menu.show .custom-control-label::before {
	width: 21px;
	height: 21px;
	top: 2px;
}

.dropdown-menu.show .custom-control-input:checked~.custom-control-label::before {
	background: #fff;
	border: none;
}

.dropdown-menu.show .custom-control-input:checked~.custom-control-label::after {
	background-image: url('../images/checked.png');
	background-size: cover;
	left: -22px;
}


.pop-desc {
	z-index: 9999;
}

.pop-desc .show {
	z-index: 9999;
}

.tooltip {
	/* padding: 16px 0; */
	top: -8px !important;
	/* position: relative;
	z-index: 9999999999; */
}

.tooltip:hover {
	display: block;
}

.tooltip-inner {
	padding: 24px 20px;
	background-color: rgba(76, 14, 42, 1);
	border-radius: 15px;
	max-width: 315px;
	border: 1px solid rgba(255, 255, 255, 0.7);
	font-size: 21px;
	line-height: 23px;
	color: #fff;
	font-family: "Ubuntu";
	font-weight: 500;
	/* transition-duration: 0.1s; */
	/* position: relative; */
	/* z-index: 9999999999999; */
}

/* .tooltip .arrow {
	height: auto;
	width: auto;
	top: 17px;
	left: 50% !important;
	transform: translateX(-50%) !important;
}

.tooltip .arrow::after,
.tooltip .arrow::before {
	bottom: calc(100% - 17px);
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tooltip .arrow::before {
	border-bottom-color: #fff;
	border-width: 17px;
	margin-left: -17px;
}
.tooltip .arrow::after {
	border-color: rgba(0, 20, 102, 0);
	border-bottom-color: #001466;
	border-width: 16px;
	margin-left: -16px;
} */


.tab-content.tab-inner .tab-pane .table-responsive {
	height: 98%;
	overflow-y: auto;
	max-width: 98%;
	margin: 0px auto;
}

.tab-content.tab-inner .tab-pane .table-responsive .table thead {
	height: 25px;
}

.table {
	margin: 0;
	color: #fff;
}

.table thead th {
	z-index: 2;
	padding: 12px 12px 14px;
	border: none;
	text-align: center;
	font-size: 20px;
	line-height: 23px;
	font-weight: 500;
	vertical-align: middle;
	background-color: transparent;
	position: sticky;
	top: 0;
	text-transform: capitalize;
	color: #fff;
	border-bottom: 1px solid #fff;
}

.invite-box a:hover {
	text-decoration: underline
}

.table thead th::before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/* background: linear-gradient(#000 60%, #1a1a1a 48.46%, #939393 94.73%, #e0e0e0 35.79%, #fff 100%); */
	opacity: 0.1;
	mix-blend-mode: overlay;
}

.table thead th .th-actions {
	position: relative;
	z-index: 1;
}

.point-table.table-section {
	margin-top: -50px;
}

.point-table .table thead th .th-actions {
	display: grid;
	grid-template-columns: repeat(2, auto);
	grid-template-rows: repeat(2, auto);
	align-items: center;
}

.table thead th .th-actions span {
	/* margin-right: 10px; */
	grid-area: 1 / 1 / 3 / 2;
	text-align: right;
	padding-right: 5px;
	text-transform: capitalize;
}

.table thead th .th-actions button {
	padding: 0;
	background: none;
	border: none;
	display: inline-flex;
	width: 16px;
}

.table tbody td {
	padding: 20px 25px;
	vertical-align: middle;
	font-size: 20px;
	line-height: 23px;
	font-weight: 500;
	text-align: center;
	border: none;
}

.table tbody tr:not(:last-child) td {
	border-bottom: 2px solid #82ADFF;
}

/* .table tbody td button 				{ padding: 12px 45px 18px; border: none; background: url('../images/button-bg.png') no-repeat center / auto 100%; font-size: 27px; line-height: 35px; font-family: "MyriadPro"; font-weight: 800; color: #EBDF00; } */
.common-outline-btn {
	padding: 8px;
	border: none;
	transition-duration: 0.4s;
	background: none;
	font-size: 25px;
	line-height: 32px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #f4ce00;
	overflow: hidden;
	position: relative;
	border-radius: 19px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.31);
}

.common-outline-btn span.main-layer {
	display: inline-block;
	padding: 5px 40px;
	transition-duration: 0.4s;
	background: linear-gradient(180deg, #200052 10%, #200052 50%, #200052 100%);
	position: relative;
	border-radius: 17px;
	color: #fff;
}

.common-outline-btn span.main-layer {
	background: transparent;
}

.common-outline-btn {
	background: transparent;
	position: relative;
	border: none;
	border-radius: 10px;
	box-shadow: none;
	color: #fff;
	background: url('../images/submit-btn.png')no-repeat center center/100% 100%;
}

.common-outline-btn::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	/* background: url('../images//btn/selected_button.png')no-repeat center center/175% auto; */
}

.common-outline-btn span.bg-layer-inner,
.common-outline-btn span.bg-layer {
	background: transparent !important;
}

.common-outline-btn span {
	position: relative;
	z-index: 1;
}

.common-outline-btn span.bg-layer {
	display: inline-block;
	padding: 4px;
	transition-duration: 0.4s;
	border-radius: 19px;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(180deg, rgba(240, 208, 46, 1) 0%, rgba(137, 98, 22, 1) 100%);
}

.common-outline-btn span.bg-layer-inner {
	display: block;
	height: 100%;
	transition-duration: 0.4s;
	background: linear-gradient(180deg, rgba(253, 250, 55, 1) 0%, rgba(169, 123, 28, 1) 100%);
	border-radius: 19px;
	position: relative;
}

.common-outline-btn:hover span.main-layer {
	color: #fff;
	transition-duration: 0.4s;
}

.gender-grp {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.gender-grp div {
	margin-right: 10px;
}

.gender-grp .form-check {
	margin-bottom: 0 !important;
	min-height: unset;
}

.footer-links {
	position: relative;
	z-index: 1;
	background-color: transparent;
	padding: 15px;
}

.point-table .tab-pane.active .table-responsive td {
	background: transparent;
	color: #fff;
	border: none;
}

.footer-links::before,
.footer-links::after {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: transparent;
	opacity: 0.2;
	mix-blend-mode: overlay;
}

.footer-links ul {
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.footer-links ul li {
	padding: 0 20px;
}

.footer-links ul li a {
	display: inline-flex;
	font-size: 15px;
	line-height: 20px;
	font-family: "MyriadPro";
	font-weight: 700;
	color: #fff;
	align-items: center;
}

.footer-links ul li a:hover,
.contact-info a:hover {
	text-decoration: underline;
}

.footer-links ul li a img {
	margin-right: 8px;
}

.myJoinTableModalWrap .model-body {}

/* private table model starts */
.my-join-game-popup {}

.modal .modal-header {
	/* position: relative; */
	/* background-color: #0C207A; */
	/* padding: 10px; */
	/* padding: 15px 36px; */
}

.footer-widget1 p {
	font-size: 12px;
	line-height: 15px;
	font-family: "MyriadPro";
	font-weight: 500;
}

.footer-widget1 p a {
	color: #5ce7ff;
}

.footer-widget1 p a:hover,
.footer-widget1 p a:focus {
	color: #5ce7ff !important;
	text-decoration: underline;
}

.footer-logo {
	margin-bottom: 10px;
}

.support-logo-icon {
	margin-top: 25px;
}

.modal .modal-header .btn-close {
	background: url('../images/Close.png') no-repeat bottom left / 40px;
	height: 40px;
	width: 40px;
	border: none;
}

.modal .modal-header::after {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	mix-blend-mode: overlay;
	background: linear-gradient(#000 0%, #1a1a1a 9.46%, #939393 54.73%, #e0e0e0 75.79%, #fff 100%);
	opacity: 0.1;
}

.modal .modal-header {
	color: #fff;
	display: flex;
	align-items: center;
	border-bottom: 0px;
}

.modal .modal-header>*,
.modal-body>* {
	z-index: 2;
	position: relative;
}

.modal .modal-header h3 {
	/* margin-left: 83px; */
	margin-left: 100px;
	width: 100%;
	font-size: 35px;
	line-height: 38px;
}

.modal-header .close {
	opacity: 1;
	text-shadow: none;
}

.modal-header .close:focus-visible {
	outline: none;
}

.modal-header .close:hover,
.modal-header .close:focus {
	opacity: 0.75;
	box-shadow: none;
}

.modal-dialog {
	max-width: 60%;
}

.modal-body {
	background-color: #220071;
	padding: 31px;
	background: url(../images/img/Popup-Box-2.png) no-repeat center center /109vw;
	border: 1px solid white;
	border-radius: 21px;
}

.backgroundbtn {
	/* padding: 9px 30px; */
	display: block;
	color: #3B0E20;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	border-radius: 8px;
	/* background: #5ce7ff; */
	/* font-family: "MyriadPro"; */
	font-weight: 700;
}


.backgroundbtnInfo {
	background: url("../images/btn/edit_button.png")center / cover no-repeat;
	padding: 13px 11px;
	border: none;

}

.backgroundbtnprofile {
	/* background: url("../images/btn/edit_button.png")center / cover no-repeat; */
	background: #5ce7ff;
	padding: 15px 15px;
	border: none;

}

.backgroundbtnimageKYC {
	background: url('../images/img/UPLOAD_BASE.png')no-repeat center center/125% auto;
	padding: 15px 15px;
	border: none;

}

.backgroundbRedBtn {
	background: url('../images/btn/cancel_button.png')no-repeat center center/100% auto !important;
	padding: 33px 46px !important;
	border: none;
	font-size: 18px;
	font-family: "MyriadPro";
	font-weight: 700;

}

.state-options span+div {
	background-color: transparent;
	border: none;
	color: #fff;
	border-bottom: 1px solid #fff;
	outline: none;
	box-shadow: none;
}

.state-options span+div:hover,
.state-options span+div:focus {
	outline: none;
	box-shadow: none;
	border-color: #fff;
}

.state-options span+div>div>div {
	color: #fff;

}

@media screen and (min-width: 1200px) and (max-width: 1430px) {
	.backgroundbtnprofile {
		padding: 7px 49px !important;
	}
}

@media screen and (min-width: 1430px) and (max-width: 1600px) {
	.backgroundbtnprofile {
		padding: 10px 49px !important;
	}
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.banner-area {
		padding-top: 0;
		height: 86vh;
	}

	.backgroundbtnInfo {
		padding: 0px 25px !important
	}
}


.modal-dialog .modal-content .modal-body button {
	font-size: 25px;
	line-height: 25px;
	font-weight: 700;
}

.modal-body .common-outline-btn span.main-layer {
	padding: 10px 40px;
	background: linear-gradient(180deg, #225cd8 20%, #183bc0 50%, #1123af 100%);
}

.modal-body .theme-orange-btn span {
	padding: 16px 46px;
	font-size: 20px;
	line-height: 20px;
}

.modal-body .theme-orange-btn:hover {
	color: #000;
	text-shadow: 1px 1px #fff;
}

/* .privateTableModal .modal-body div:first-child button { padding: 5px; border: none; background: url('../images/button-bg.png') no-repeat center / 100% 100%; font-size: 27px; line-height: 35px; font-family: "MyriadPro"; font-weight: 800; color: #EBDF00; } */
.privateTableModal .modal-body {
	padding: 120px 31px;
}

.privateTableModal .modal-dialog {
	max-width: 50%;
}

.modal-body::before {
	content: "";
	z-index: 0;
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(#1435a9 0%, rgba(20, 53, 169, 0.42) 56.89%, rgba(20, 53, 169, 0) 100%);
	z-index: -1;
}

.new-create-box {
	/* margin-left: 20px; */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 25px;
}

.new-create-box button {
	max-width: 45% !important;
}

.modal-verify-otp-wrap .new-create-box button.btn.theme-orange-btn:last-of-type {
	background: #5ce7ff !important;
}

.modal-verify-otp-wrap .new-create-box button.btn.theme-orange-btn:last-of-type span {
	color: #fff !important;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;

}

.coomon-popup-sec h3 {
	font-size: 30px;
	margin-bottom: 20px;
	margin-left: 0 !important;
	width: 100%;
	color: #fff;
}

.coomon-popup-sec .modal-header {
	width: 100%;
	text-align: center;
}

.ForgetPasswordWrap.modal-dialog {
	max-width: 650px !important;
}

.ForgetPasswordWrap .coomon-popup-sec h4 {

	max-width: 80%;
	margin: 0 auto;
	margin-bottom: 20px;

}

.coomon-popup-sec button.forget-pass-btn {
	box-shadow: none !important;
	border: none !important;
	padding: 9px 30px !important;
	display: block;
	color: #3B0E20;
	font-size: 18px;
	line-height: 22px;
	background: #01875F;
	text-align: center;
	border-radius: 8px;
	background: #5ce7ff !important;
	font-family: "MyriadPro";
	font-weight: 700;
	padding: 0 !important;
}

.coomon-popup-sec .modal-body {
	flex-grow: 0;
}

.coomon-popup-sec button.forget-pass-btn {
	margin-left: 0 !important;
}

.coomon-popup-sec button.forget-pass-btn span {
	background: transparent !important;
	border: none !important;
	text-shadow: none !important;
	color: #fff !important;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96 !important;
}

.coomon-popup-sec .modal-body {
	background: transparent !important;
	border: none;
	padding: 0 !important;
}

.coomon-popup-sec .modal-header::after,
.coomon-popup-sec .modal-body::before {
	display: none;
}

.coomon-popup-sec button {
	margin-top: 0 !important;
}

.blue-bg {
	background-color: transparent;
	padding: 25px 12px;
	border-radius: 10px;
	border: 2px solid #ebf5ff
}

.new-join-box {
	margin-right: 20px;
}

.modal-body h4 {
	margin-bottom: 30px;
	color: #fff;
}

.modal .btn.focus,
.modal .btn:focus {
	box-shadow: unset;
}

.modal-body .form-group .form-control {
	background-color: #fff;
	font-weight: 500;
	height: auto;
	padding: 13px 40px;
	font-size: 22px;
	line-height: 35px;
	border: none;
	border-radius: 10px;
	/* box-shadow: rgb(89 119 248 / 45%) 5px 5px 10px 0px inset, rgb(89 119 248 / 45%) -5px 6px 10px 0px inset, 3px 4px 17px -1px rgb(0 0 0 / 30%);
	-webkit-box-shadow: rgb(89 119 248 / 45%) 5px 5px 10px 0px inset, rgb(89 119 248 / 45%) -5px 6px 10px 0px inset, 3px 4px 17px -1px rgb(0 0 0 / 30%); */
}

.deposit-cash-page.after-login main {
	padding: 40px 0px 0px;
}

.after-login header nav {
	box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.after-login.deposit-cash-page main {
	background: unset !important;
}

.deposit-wrap {
	/* background: url('../images/cards.png') no-repeat bottom left; */
	color: #fff;
	height: calc(100vh - 100px);
	display: flex;
	align-items: center;
}

/* .deposit-parent-box 	{ padding-top: 40px ; } */
.deposit-parent-box {
	position: relative;
}

.deposit-cash-box {
	background: url('../images/dashboard-bg.png') no-repeat center center / 100% 100%;
	padding: 40px 25px;
	;
	/* max-width: 95%; */
	margin: 0 auto;
	min-height: 550px;
}

.deposit-wrap h2 {
	/* margin-bottom: 42px; */
	font-size: 33px;
	line-height: 38px;
	display: inline-block;

}

.deposite-header {
	background: url('../images/common-header-bg.png') no-repeat center center / cover;
	min-width: 400px;
	padding: 15px 0px;
	margin-bottom: -20px;
	position: absolute;
	left: 70px;
	top: 26px;
	color: #fff;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
	font-family: "GrotaSansRd" !important;

}

.deposit-cash-box button.add-cash-btn {
	background: url('../images/add-cash-btn.png')no-repeat center center/100% 100%;
	border: none;
	box-shadow: none;
	min-width: 250px;
	min-height: 60px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 15px 25px 20px;
	margin-bottom: 15px;
}

.deposit-cash-box h3 {
	margin-bottom: 25px;
	color: #fff;
	font-size: 20px;
	/* color: #5ce7ff; */
	margin-top: 25px;
	font-family: "GrotaSansRd" !important;
}

.deposit-cash-box .form-group {
	width: 100%;
	margin-bottom: 0px;
}

.deposit-cash-form-box {
	max-width: 91%;
	margin: 0 auto;
}

.deposit-cash-form-box button {
	min-width: 170px;
}

.deposit-cash-form-box .form-group span.yellow-txt {
	margin: 22px 20px 0px 0px;
	display: inline-block;
	font-size: 20px;
	line-height: 22px;
	font-weight: 500;
	font-family: "MyriadPro";
}

.deposit-cash-box button {
	display: inline-flex;
	padding: 10px 20px;
	/* border: none; */
	/* background-color: #A6B9DB; */
	font-size: 25px;
	line-height: 30px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #fff !important;
	border-radius: 9px;
	position: relative;
	/* box-shadow: 0 0 7px rgb(0 0 0 / 90%); */
	max-width: 190px;
	text-align: center;
	align-items: center;
	height: 100%;
}

.deposit-cash-box .deposit-cash-form-box button {
	padding: 10px 30px;
	display: block;
	color: #fff;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	border-radius: 8px;
	background: #5ce7ff;
	font-family: "MyriadPro";
	font-weight: 900;
	height: 100%;
	min-height: 40px;
}

.deposit-cash-box .bonus-box {
	min-width: 150px;
}

.deposit-cash-box .bonus-box button {
	margin-bottom: 15.8px;
	width: 100%;
	border: none;
	/* min-width: 150px; */
}

.deposit-cash-box .blue-bg {
	margin-top: 8px;
	margin-bottom: 10px;
	padding: 0;
	border-radius: 18px;
	border: none;
}

.scollbar-box {
	overflow: auto;
	white-space: nowrap;
	justify-content: center;
	/* margin: 0 -15px 25px -15px; */
}

.bonus-btn-box {
	margin: 0px 15px;
	padding: 0px 0px 10px;
}

.prmotion-txt-box {
	margin-top: 25px;
	flex-basis: 100%;
	display: inline-block;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #fff;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #001CA1;
}


main.deposit-page,
.deposit-page {
	margin-top: 80px;
	padding-top: 40px;
}

.deposit-cash-box .blue-bg .prmotion-txt-box p {
	font-size: 25px;
	line-height: 30px;
	font-weight: 500;
	font-family: "MyriadPro";
}

.deposit-cash-box .blue-bg p {
	font-size: 17px;
	line-height: 20px;
	font-weight: 500;
	font-family: "MyriadPro";
}

.deposit-cash-box .form-group input {
	margin-right: 35px;
	background-color: rgba(46, 6, 27, 0.7);
	font-weight: 500;
	height: auto;
	padding: 13px 40px;
	font-size: 22px;
	line-height: 35px;
	border: none;
	border-radius: 10px;
	width: calc(100% - 20px);
	color: #5269A6;
	max-width: 60%;
	margin: 0px auto;
	color: #fff;
	font-weight: 700;
	margin-bottom: 25px;
}

.deposit-cash-box .form-group input::placeholder {
	color: rgba(255, 255, 255, 0.6);
}

.form-control::-webkit-input-placeholder {
	color: #5269A6;
}

.deposit-cash-box>a {
	margin: 30px 0px;
	transition-duration: 0.3s;
	display: inline-block;
	font-size: 20px;
	line-height: 25px;
	font-weight: 700;
}

.deposit-cash-box a:hover {
	color: #00acee;
}

.yellow-txt {
	color: #f4ce00 !important;
	transition-duration: 0.3s;
}

.deposit-cash-box .btn-box1 {
	z-index: 1;
}

.yellow-btn {
	/* background-color: #f4ce00 !important; */
	background: url('../images/btn/edit_button.png')no-repeat center center/100% auto;
	transition-duration: 0.4s;
}

.yellow-btn:hover {
	color: #000;
	transition-duration: 0.4s;
}

.deposit-cash-box .yellow-btn {
	justify-content: center;
}

.deposit-cash-box button,
.deposit-cash-box button span {
	color: #fff;
}

.deposit-cash-box button span {
	z-index: 1;
}

.deposit-cash-box button:hover,
.deposit-cash-box button:hover span {
	/* color: #000; */
}

.deposit-cash-box p {
	color: rgba(255, 255, 255, 0.6);
	font-size: 16px;
	line-height: 30px;
	font-weight: 500;
}

.deposit-cash-form-box button {
	color: #344769;
}

.deposit-cash-form-box button:hover {
	color: #000;
}

.modal-content {
	border: none;
	background-color: transparent;
}

.deposit-cash-box .btn-box1 button {
	font-size: 30px;
	line-height: 35px;
}

.deposit-cash-box .btn-box1 {
	margin-top: 22px;
	padding: 42px 40px;
	background-color: #001466;
}

.btn-box1 button {
	flex-direction: column;
}

.btn-box1 button span {
	z-index: 3;
	position: relative;
}

.btn-box1 button span:first-child {
	margin-right: 5px;
}

.btn-inner-box {
	width: 100%;
}

.deposit-cash-box p a:hover {
	text-decoration: underline;
	color: #f4ce00;
	transition-duration: 0.3s;
}

.JoinModalWrap .modal-dialog {
	max-width: 50%;
}

.CreateModalWrap .modal-dialog {
	max-width: 55%;
}

.JoinModalWrap .modal-body {
	padding: 110px 87px 41px;
}

.JoinModalWrap .form-group {
	margin-bottom: 90px;
}

.player-filter.collapse.show {
	position: relative;
	z-index: 3;
}

/* .modal-body .panel-heading { } */
.modal-body .panel-heading .nav-tabs {
	display: flex;
	width: 100%;
}

/* .modal-body .panel-heading .nav-tabs a.active { } */
.modal-body .panel-heading .nav-tabs li {
	flex-basis: 50%;
	text-align: center;
	background-color: #0C207A;
	color: #fff;
}

/* .modal-body .panel-heading .nav-tabs li a { background-color: ; } */
.modal-body .panel-heading .nav-tabs li.active {
	color: #fff;
}

.modal-body .panel-heading .nav-tabs li a {
	color: #fff;
	font-size: 28px;
	line-height: 30px;
	padding: 15px 20px;
}

.modal-body .panel-heading .nav-tabs .nav-link {
	position: relative;
	border-bottom: 2px solid transparent;
	background-color: rgba(12, 32, 122, 0.9);
}

.modal-body .panel-heading .nav-tabs .nav-link::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(#000 0%, #1a1a1a 9.46%, #939393 54.73%, #e0e0e0 85.79%, #fff 100%);
	opacity: 0.1;
	mix-blend-mode: overlay;
}

.modal-body .panel-heading .nav-tabs .nav-link.active {
	background-color: rgba(12, 32, 122, 0.9);
	border-bottom: 2px solid #FFDA16;
}

.CreateModalWrap .modal-header {
	background-color: #220071;
	color: #fff;
}

.CreateModalWrap .modal-body {
	position: relative;
	padding: 0px;
}

.CreateModalWrap .modal-body .panel-body {
	padding: 0px 70px;
}

.CreateModalWrap .modal-body .panel-info {
	padding: 90px 0px 30px;
}

.CreateModalWrap .modal-body .panel-heading {
	width: 100%;
}

.CreateModalWrap .modal-body .panel-heading .tabs-wrap {
	position: absolute;
	top: 0;
	left: 0;
	align-items: center;
	width: 100%;
}

.CreateModalWrap .modal-body .panel-heading .tabs-wrap>div {
	flex-basis: 50%;
	text-align: center;
}

.CreateModalWrap .modal-body .panel-heading .tabs-wrap>div a {
	color: #fff;
	position: relative;
	z-index: 2;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	font-family: "MyriadPro";
	width: 100%;
	padding: 15px 0px;
	display: inline-block;
	border-bottom: 5px solid transparent;
	background-color: rgba(12, 32, 122, 0.9);
}

.CreateModalWrap .modal-body .panel-heading .tabs-wrap>div a::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(#000 0%, #1a1a1a 9.46%, #939393 54.73%, #e0e0e0 85.79%, #fff 100%);
	opacity: 0.1;
	mix-blend-mode: overlay;
}

.CreateModalWrap .modal-body .panel-heading .tabs-wrap .inner-tab.active {
	border-bottom: 5px solid #FFDA16;
}

.CreateModalWrap .modal-body .panel-heading .tabs-wrap .inner-tab {
	border-bottom: 5px solid transparent;
}

.CreateModalWrap .irs {
	margin: 0 auto;
}

.CreateModalWrap .irs--flat .irs-grid-text {
	color: #fff;
	font-weight: 600;
}

.CreateModalWrap .modal-body .slider-btn-wrapper button {
	position: relative;
	height: 31px;
	width: 31px;
	background-color: #000;
	border-radius: 15px;
}

.CreateModalWrap .pluse-btn::before {
	position: absolute;
	content: '\f067';
	font-family: 'FontAwesome';
	left: 0;
	top: 0;
	height: 31px;
	width: 31px;
	font-size: 18px;
	line-height: 31px;
	color: #fff;
}

.CreateModalWrap .minus-btn::before {
	position: absolute;
	content: '\f068';
	font-family: 'FontAwesome';
	left: 0;
	top: 0;
	height: 31px;
	width: 31px;
	font-size: 18px;
	line-height: 31px;
	color: #fff;
}

.slider-btn-wrapper>div {
	margin: 0px 20px;
}

.CreateModalWrap .modal-body .slider-btn-wrapper button {
	margin-top: 0px;
}

.irs--flat .irs-min,
.irs--flat .irs-max,
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
	display: none;
}

.player-radio-btns-group {
	text-align: center;
}

.player-radio-btns-group {
	margin-bottom: 40px;
	padding: 5px 0px;
	border: 1px solid #00acee;
	border-radius: 10px;
	background-color: #081954;
	display: inline-flex;
	justify-content: center;
}

.player-radio-btns-group button {
	margin-top: 0px;
	position: relative;
}

.player-radio-btns-group label.active,
.player-radio-btns-group button.active {
	background-color: #A6B9DB;
	font-size: 18px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #344769;
	background-color: #A6B9DB;
	border-radius: 10px;
}

.player-radio-btns-group button::before {
	background-color: transparent !important;
}

.player-radio-btns-group label.active::before,
.player-radio-btns-group button.active::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #ecf2ff;
	background-image: linear-gradient(180deg, #ecf2ff 0%, #9fb6da 46%, #556c90 100%);
	border-radius: 9px;
}

.player-radio-btns-group button span {
	z-index: 2;
	position: relative;
}

.player-radio-btns-group button.active span {
	color: #0b1b56;
}

.player-radio-btns-group button:hover {
	color: #fff;
}

/* .player-radio-btns-group label.active::after { content: ""; position: absolute; top: 0; left: 0; height: 100%; width: 100%; background: linear-gradient(rgb(166, 185, 219) 0%, rgba(166,185,219, 0.5) 13.64%, rgba(166,185,219, 0.4) 43.24%, rgba(166,185,219, 0.15) 86.21%, rgba(166,185,219, 0) 100%); mix-blend-mode: overlay; border-radius: 9px; } */

/* .modal-body .panel-heading .nav-tabs .a::after 	{ color: #081954; background: transparent; background:linear-gradient(rgb(166, 185, 219) 0%, rgba(166,185,219, 0.5) 13.64%, rgba(166,185,219, 0.4) 43.24%, rgba(166,185,219, 0.15) 86.21%, rgba(166,185,219, 0) 100%);  }  */
/* .modal-body .panel-heading .nav-tabs li 	{ margin:0px 5px; } */
.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
	margin-left: 5px;
}

.player-radio-btns-group button span,
.modal-dialog .modal-content .modal-body .player-radio-btns-group button {
	margin: 0px 5px;
	border-radius: 10px !important;
	font-family: "MyriadPro";
	padding: 15px 40px;
	font-size: 18px;
	line-height: 22px;
	font-weight: 700;
	color: #fff;
	background: transparent;
	border: none;
}

.player-radio-btns-group button span {
	padding: 10px 15px;
}

.modal-body .tab-content p {
	color: #fff;
	font-size: 16px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 500;
}

.modal-body .panel-body .tab-content p {
	margin: 0px 20px;
}

.modal-body .panel-body .tab-content p span {
	margin-left: 2px;
}

/* rang slider css starts  */
.slider-btn-wrapper .StepRangeSlider__tooltip {
	display: none;
}

/* .point-value-wrap span							{ margin: 0px 37px; }
.point-value-wrap span:first-child 				{ margin-right: auto; margin-left: 0px; }
.point-value-wrap span:last-child { margin-left: auto; margin-right: 0px; } */




.range-slider {
	width: 100%;
	max-width: 75%;
	margin: 0 auto;
	padding-top: 100px;
}

.join-table-content {
	padding: 50px 80px 0px;
}

.CreateModalWrap.modal-dialog {
	max-width: 50%;
}

/* #container-loan-amount-range, */
#container-two-player-range,
#container-six-player-range {
	position: relative;
}

.range-minus {
	position: absolute;
	top: 13px;
	left: -45px;
	padding: 15px;
	cursor: pointer;
}

.range-minus::after {
	position: absolute;
	content: '';
	top: 14px;
	left: 4px;
	width: 20px;
	height: 2px;
	background: #ed5565;
	border-radius: 40px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.range-minus:hover::after {
	background: #FFDA16;
}

.range-plus {
	position: absolute;
	top: 13px;
	right: -45px;
	padding: 15px;
	cursor: pointer;
}

.range-plus::before {
	position: absolute;
	content: '';
	top: 6px;
	left: 12px;
	width: 2px;
	height: 19px;
	background: #ed5565;
	border-radius: 40px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.range-plus::after {
	position: absolute;
	content: '';
	top: 14px;
	left: 4px;
	width: 19px;
	height: 2px;
	background: #ed5565;
	border-radius: 40px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.range-plus:hover::before,
.range-plus:hover::after {
	background: #EBC606;
}

.range-slider-button {
	margin-top: 75px;
}

.range-slider-rep-text {
	width: 100%;
	max-width: 360px;
	margin: 35px auto 0 auto;
	text-align: center;
}

.range-slider-rep-text p {
	line-height: 22px;
	font-size: 14px;
	text-align: center;
	color: #1B1B1B;
}

.range-slider-rep-text p strong {
	font-weight: 700;
	font-size: 20px;
}

.modal-body .range-slider {
	padding-top: 10px;
}

.modal-body .irs--flat .irs-grid-pol {
	display: none;
}

.modal-body .irs--flat .irs-handle>i:first-child {
	display: none;
}

.modal-body .irs--flat .irs-handle,
.StepRangeSlider__thumb {
	height: 16px;
	width: 16px;
	border-radius: 8px;
	box-shadow: 0 0 0 8px rgb(255 218 22 / 60%);
	background: rgb(255, 255, 255);
	background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 218, 22, 1) 71%);
	cursor: pointer;
}

.modal-body .irs--flat .irs-line,
.slider-btn-wrapper .StepRangeSlider__track {
	background-color: #081954;
	height: 7.5px;
}

.modal-body .irs--flat .irs-bar {
	background-color: #fff;
	height: 6.5px;
}

.modal-body .range-minus::after,
.modal-body .range-plus::after,
.modal-body .range-plus::before {
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
}

.modal-body .range-minus::after,
.modal-body .range-plus::after {
	height: 3.5px;
	width: 15px;
}

.modal-body .range-plus::before {
	height: 15px;
	width: 3.5px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.modal-body .range-plus,
.modal-body .range-minus {
	width: 31px;
	height: 31px;
	background-color: #000;
	border-radius: 15px;
}

.modal-body .irs-grid-text,
.range-values-wrap span {
	font-size: 14px;
	line-height: 14px;
	color: #fff;
	font-family: "MyriadPro";
	font-weight: 500;
}

.CreateModalWrap .modal-body button {
	display: block;
	margin: 62px auto 0 auto;
}

/* range slider css ends   */

.joinTableModal .modal-content {
	background-color: transparent;
}

.myJoinTableModalWrap .modal-body {
	padding: 120px 20px;
	background: url('../images/dashboard-bg.png')no-repeat center center /100% 100% !important;
	border: none;
}

.start-game-popup .modal-body {
	background: url('../images/dashboard-bg.png')no-repeat center center /100% 100% !important;
	border: none;
	min-height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.start-game-popup .modal-body::before {
	display: none;
}

.myJoinTableModalWrap .modal-body::before {
	display: none;
}

.joinTableModal .modal-body {
	/* padding: 180px 30px; */
	width: 100%;
	overflow-y: auto;
	flex-direction: column;
	overflow-x: hidden;
}

.joinTableModal .modal {
	display: block !important;
}

.joinTableModal .modal-dialog {
	overflow-y: initial !important;
	max-width: 65%;
}

.joinTableModal .modal-body>.row {
	width: 100%;
}

.joinTableModal .blue-bg p {
	color: #fff;
	font-size: 14px;
	line-height: 14px;
	font-family: "MyriadPro";
	font-weight: 500;
}

/* .joinTableModal .blue-bg p:first-child { margin-bottom: 15px; } */
.joinTableModal .old-game-wrap {
	border: none;
	box-shadow: none;
	margin: 0 auto;
	width: 100%;
	max-width: 307px;
	padding: 5px 12px;
	border-radius: 15px;
	background-color: #1637b5;
	/* background-image: linear-gradient(180deg, #1637b5 54%, #102c97 87%, #081d6b 100%); */
	background: transparent;
	border: 2px solid #c3b4d5;

}

.logoutbackground {
	/* background-color: rgb(34, 0, 113); */
	text-align: center;
	padding: 70px 0;
	/* background: url('../images/pop-up/logout-popup.png') no-repeat center center / 450px; */
	background: url('../images/dashboard-bg.png')no-repeat center center/100% 100%;

}

.addlogout-Tittle {
	font-size: 18px;
	font-family: "MyriadPro";
	font-weight: 800;
	justify-content: center;
	text-align: center;

	padding: 14px 0;
	color: white;
	font-size: 35px;
	font-family: 'Source Serif Pro', serif;
	margin-bottom: 20px;
	/* background: url('../images/pop-up/Tittle-Box.png') no-repeat center center / 300px; */
}

.green-button {
	min-width: 220px;
	font-size: 18px;
	font-family: "GrotaSansRd";
	font-weight: 800;
	color: white;
	text-align: center;
	/* background: url(../images/submit-btn.png) no-repeat center center / 100% 100%; */
	background: #ffffff;
	padding: 15px 0;
	border: none;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
}

.privateTable-button {
	min-width: 350px;
	font-size: 25px;
	font-family: "GrotaSansRd";
	font-weight: 800;

	text-align: center;
	background: url('../images/my-join-games.png') no-repeat center center / 100% 100%;
	text-transform: uppercase;
	padding: 20px 0 15px;
	border: none;
	/* margin-top: -20px; */
	cursor: pointer;
	position: absolute;
	top: 90px;
	right: 50px;
	text-transform: none;
}

.privateTable-button span {
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
}

.privateTable-button:hover,
.privateTable-button:focus {
	background-color: transparent !important;
	border: none !important;
}

.footer-link {
	position: absolute;
	bottom: 90px;
	left: 50%;
	transform: translateX(-50%);
}

.footer-link-2 {
	position: absolute;
	bottom: 90px;
	right: 5%;
	transform: translateX(-5%);
}

.footer-link ul {
	display: flex;
	gap: 20px;
}

.footer-link-2 ul {
	display: flex;
	gap: 20px;
}

.footer-link ul li a {
	color: #fff;
}

.footer-link-2 ul li a {
	color: #fff;
}

.footer-link-2 ul li a img {
	margin-right: 10px;
}

.footer-link ul li a img {
	margin-right: 10px;
}

.privateTable-button:hover {
	background: url('../images/my-join-games.png') no-repeat center center / 100% 100%;
}

.red-button {
	padding: 9px 30px;
	display: block;
	color: #fff;
	font-size: 18px;
	line-height: 22px;
	background: #01875F;
	text-align: center;
	border-radius: 8px;
	background: #5ce7ff;
	font-family: "GrotaSansRd";
	font-weight: 700;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96,
		2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
}

.red-button:hover,
.red-button:focus,
.red-button:active {
	background: #5ce7ff !important;
	color: #fff !important;
}

.joinTableModal .old-game-wrap .old-detail-box {
	min-width: 86px;
	height: 65px;
}

/* .joinTableModal .modal-dialog .modal-content .modal-body button {  } */
/* .joinTableModal .old-game-detail-wrap > div .old-detail-box:nth-child(2) .blue-bg { padding: 10px 4px; } */
.joinTableModal .old-game-detail-wrap>div .old-detail-box .blue-bg {
	display: flex;
	padding: 12px 4px;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.joinTableModal .img-box {
	margin-bottom: 24px;
	position: relative;
}

.joinTableModal .img-box img {
	box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.joinTableModal .img-box>div,
.custom-shape-box .outer-box {
	margin: 0px 4.5px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	;
	/* background-color: #A2A6C2; */
	border-radius: 40px;
	padding: 2px;
}

/* .joinTableModal .img-box > div div {  } */
.joinTableModal .img-box>div div.inner-box,
.custom-shape-box .inner-box {
	position: relative;
	z-index: 2;
}

.joinTableModal .img-box>div div.inner-box::before,
.custom-shape-box .inner-box::before {
	position: absolute;
	top: 15px;
	left: 15px;
	height: calc(100% - 30px);
	width: calc(100% - 30px);
	content: '';
	/* background: linear-gradient(#fff 0%, rgba(255, 255, 255, 0) 100%); */
	background-color: #fff;
	border-radius: 40px;
	mix-blend-mode: overlay;
}

.joinTableModal .modal-header,
.modal-private-modal-wrap.CreateModalWrap .modal-header,
.tournamentModal .modal-header,
.promo-offer-modal .modal-header {
	justify-content: unset;
}

.joinTableModal .modal-header .modal-title,
.modal-private-modal-wrap.CreateModalWrap .modal-header .modal-title,
.tournamentModal .modal-header .modal-title,
.promo-offer-modal .modal-header .modal-title,
.complain-wrap .modal-header .modal-title {
	flex-basis: 80%;
	opacity: 0.9;
}

.myJoinTableModalWrap .modal-body {
	margin: 0px auto;
}

.myJoinTableModalWrap .modal-header {
	padding-bottom: 0;
	justify-content: center !important;
	max-width: 700px;
	margin: 0px auto;
	width: 100%;
}

.myJoinTableModalWrap .modal-title h3 {
	margin-left: 0 !important;
	display: inline-block;
	padding: 20px 60px;
	background-size: 100% auto;
}

.joinTableModal .modal-header button.close,
.modal-private-modal-wrap.CreateModalWrap .modal-header button.close,
.tournamentModal .modal-header button.close,
.promo-offer-modal .modal-header button.close,
.complain-wrap .modal-header button.close,
.qualifier-modal-wrap .modal-header button.close {
	background: url('../images/icons/Close.png') no-repeat center center / cover;
	width: 38px;
	height: 38px;
	margin-left: auto;
	border: none;
}

.joinTableModal .modal-header button.close span,
.modal-private-modal-wrap.CreateModalWrap .modal-header button.close span,
.tournamentModal .modal-header .button.close span,
.promo-offer-modal .modal-header button.close span {
	display: none;
}

.joinTableModal .img-box>div div.inner-box::after,
.custom-shape-box .inner-box::after {
	position: absolute;
	z-index: 1;
	mix-blend-mode: overlay;
	opacity: 0.5;
	content: '';
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	/* background-image: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
}

.joinTableModal .img-box>div .inner-blue-bg,
.custom-shape-box .inner-blue-bg {
	padding: 15px 10px;
	border-radius: 25px;
	background-color: transparent;
	border-radius: 40px;
	color: #fff;
}

.joinTableModal .img-box>div .inner-blue-bg p {
	padding: 12px;
	font-size: 15px;
	line-height: 15px;
	font-family: "MyriadPro";
	font-weight: 700;
	z-index: 2;
}

/* .joinTableModal .img-box p 		{ position: absolute; left: 50%; top: 50%; transform: translate(-50% , -50%); color: #fff; font-size: 15px; line-height: 15px; } */
.joinTableModal .modal-body button {
	margin-top: 30px;
}

/* .joinTableModal .modal-body .theme-orange-btn  { padding: ; } */
/* .old-game-detail-wrap > div .old-detail-box:nth-child(2)  { margin: 0px 12px;  } */
.joinTableModal .modal-body .theme-orange-btn span {
	padding: 10px 40px;
}



/* tournament-tab-wrap starts */
.tournament-tab-wrap .tab-inner-header ul li a.nav-link {
	text-align: center;
	justify-content: center;
}

.tournament-tab-wrap .table thead th .th-actions span {
	margin-right: 0px;
}

.tournament-tab-wrap .theme-btn,
.tournament-tab-wrap .common-outline-btn,
.tournament-tab-wrap .upcoming-bt {
	font-size: 20px;
	line-height: 20px;
	font-weight: 500;
}

.tournament-tab-wrap .common-outline-btn span.main-layer {
	padding: 7px 15px;
	border-radius: 4px;
}

.tournament-tab-wrap .upcoming-btn {
	cursor: not-allowed;
	background-color: #000;
	color: #EBDF00;
	border-radius: 8px;
	padding: 13.5px 12px;
	border: none;
}

.tournamentModal .upcoming-btn {
	padding: 22.5px 35px;
}

.tournament-tab-wrap .common-outline-btn span.bg-layer {
	padding: 4px;
	border-radius: 8px;
}

.tournament-tab-wrap .common-outline-btn span.bg-layer-inner,
.tournament-tab-wrap .common-outline-btn {
	border-radius: 8px;
}

.tournament-tab-wrap .common-outline-btn,
.tournament-tab-wrap .theme-btn,
.tournament-tab-wrap .yellow-btn,
.tournament-tab-wrap .upcoming-btn {
	min-width: 146px;
}

.tournament-tab-wrap .yellow-btn {
	padding: 12.5px;
	z-index: 1;
}

.no-tournment p {
	color: #fff;
	font-size: 27px;
	line-height: 30px;
}

.modal {
	z-index: 10000;
}

/* register modal start */
.registerModal .modal-body {
	padding: 0;
}

.registerModal .modal-body .tournament-outer-box {
	padding: 11px 20px;
}

.registerModal .modal-body .tournament-inner-box {
	padding: 14px 23px;
}

.registerModal {
	color: #fff;
}

.register-heading-box h3 {
	margin-bottom: 8px;
}

.registerModal h3 {
	opacity: 0.8;
	font-size: 24px;
	line-height: 28px;
	font-family: "MyriadPro";
	font-weight: 800;
}

.register-heading-box .timing-wrap .img-box {
	margin-right: 14px;
}

.timing-wrap>div:last-child img {
	margin-left: 5px;
}

.register-heading-box p {
	font-family: "MyriadPro";
	font-weight: 800;
}

.game-title-wrap {
	z-index: 2;
	padding: 10px 15px;
	border: none;
	text-align: center;
	font-size: 20px;
	line-height: 23px;
	font-weight: 500;
	vertical-align: middle;
	background-color: rgba(12, 32, 122, 0.9);
	position: sticky;
	top: 0;
}

.game-title-wrap::after {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(#000 0%, #1a1a1a 9.46%, #939393 54.73%, #e0e0e0 85.79%, #fff 100%);
	opacity: 0.1;
	mix-blend-mode: overlay;
}

.game-title-wrap>*,
.custom-shape-box>* {
	position: relative;
	z-index: 2;
}

.game-detail-left-box {
	flex-basis: 30%;
	text-align: left;
}

.game-detail-right-box {
	flex-basis: 70%;
}

.game-detail-wrap {
	margin-top: 17px;
}

.game-detail-blue-bg {
	padding: 6px 15px;
	background-color: #1c33bd;
	margin: 10px 15px;
	max-width: 70%;
	border-radius: 40px;
}

.game-detail-box .outer-box {
	padding: 2px;
}

.game-detail-box .game-detail-left-box,
.game-detail-box .game-detail-right-box {
	overflow: auto;
	white-space: nowrap;
}

.game-detail-box .game-detail-left-box {
	max-height: 245px;
}

.game-detail-box .game-detail-left-box .game-detail-blue-bg:first-child {
	margin-top: none;
}

.game-detail-box .game-detail-right-box {
	max-height: 205px;
}


/* .game-detail-box */
.prize-row {
	border-bottom: 2px solid #00acee;
	padding: 15px 15px 9px;
}

.prize-row p {
	font-size: 22.5px;
	line-height: 25px;
	font-family: "MyriadPro";
	font-weight: 600;
}

.prize-row img,
.game-round-inner-box img {
	margin-left: 3px;
	max-height: 15px;
}

.tournament-btn-wrap {
	margin: 40px 0px 25px
}

.tournament-btn-wrap .blue-bg {
	background-color: #0e09a8;
	max-width: 330px;
	padding: 21px 20px;
	border-radius: 12px;
	color: #fff;
	position: relative;
	z-index: 3;
}

.tournament-btn-wrap .theme-orange-btn span {
	font-size: 25px;
	line-height: 30px;
	font-family: "MyriadPro";
	font-weight: 800;
}

.tournament-btn-wrap .new-create-box {
	margin-left: 30px;
}

.tournament-btn-wrap h3 {
	opacity: 1;
}

.tournament-inner-footer p {
	font-size: 17px;
	line-height: 22px;
}

.game-detail-wrap .custom-shape-box .inner-blue-bg {
	padding: 5px;
}

.game-detail-wrap .custom-shape-box .outer-box {
	max-width: 80px;
	margin: 0px;
	flex-basis: 75%;
}

.custom-shape-box p {
	font-size: 20px;
	line-height: 25px;
	font-family: "MyriadPro";
	font-weight: 600;
}

.game-detail-wrap .custom-shape-box .inner-box::before {
	top: 6.5px;
	left: 6.5px;
	height: calc(100% - 13px);
	width: calc(100% - 13px);
}

.game-round-inner-box {
	flex-basis: 25%;
}

.game-detail-box {
	padding-top: 10px;
}

.game-detail-right-box .prize-row:last-child {
	border-bottom: none;
}

/* FORGET Pass css starts */
.ForgetPasswordWrap.modal .modal-header {
	position: relative;
	background-color: #0C207A;
}

.ForgetPasswordWrap.modal.CreateModalWrap .modal-body {
	padding: 60px 50px 50px;
}

.ForgetPasswordWrap h4 {
	margin-bottom: 50px;
	font-size: 20px;
	line-height: 24px;
	font-family: "MyriadPro";
	font-weight: 800;
	color: #fff;
}

.signup-screen {
	position: relative;
	border-radius: 30px;
	top: 50px;
}

.signup-screen .nav-tabs {
	text-transform: uppercase;
	color: var(--third-color);
	font-weight: bold;
	border: 0px;
	/* padding: 0px 20px 0px; */
	/* margin-bottom: -110px; */
	/* position: relative; */
	width: 100%;
	margin-top: -55px;
	/* z-index: -1; */
	border-bottom: 1px solid rgb(135, 135, 135);
}

.signup-screen .nav-tabs li {
	width: 50%;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	background-color: #000000;
	border: 1px solid #555161;
}

.login-screen .tab-content {
	width: 100%;
	flex-grow: 1;
	display: flex;
	/* align-items: center; */
	width: 100%;
	justify-content: center;
}

.login-screen .tab-content .tab-pane {
	width: 100%;
}

.sign-detail {
	height: 100%;

}

.login-form {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.login-btn {
	margin-bottom: 40px;
}

.login-btn.regester-btn {
	margin: 0;
	margin-top: 15px !important;
}

.form-check-input {
	cursor: pointer;
}

.form-check-input:checked {
	border-color: #5ce7ff;
	outline: none !important;
	box-shadow: none !important;
}

.form-check-input:checked {
	background-color: #5ce7ff;
}

.login-register {
	/* background: url(../images/pop-up//Select-Button.png) no-repeat center center/cover; */
}

.signup-screen .nav-tabs li::after {
	position: absolute;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
	/* background: url('../images/login-bg.png')no-repeat center center/100% 100% !important; */
	z-index: 1;
	background-color: #000000;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	border: 1px solid #555161;
}

.login-screen li {
	width: 50%;
	text-align: center;
	/* position: relative; */
}



.login-screen {
	/* background: url(../images/pop-up-back-new.png) no-repeat top center/100% auto; */
	height: 370px;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
}

.sign-detail {
	/* min-height: 550px; */
}

.register-detail-img {
	background: url(../images/pop-up/Popup-new-Box.png) no-repeat center center/93% auto;
	/* background: url(../images/img/Popup-Box-2.png) no-repeat center center/105% auto; */
}

.login-screen li:first-child::after {
	display: block;
	content: "";
	height: 55px;
	width: 110%;
	top: 0;
	left: 110%;
	position: absolute;
	transform: scaleX(-1);
	/* transform-origin: left center; */
	z-index: -1;
	/* background: url('../images/tab.png') no-repeat top left / 100% 100%; */
}

.login-screen li.active:first-child::after {
	transform: scaleX(1);
	left: 90%;
}

.login-screen li a {
	color: #e2e6f4;
	padding: 12px;
	display: block;
	cursor: pointer;
	font-size: 22px;
	line-height: 30px;
	text-transform: capitalize;
}

.login-screen li.active a {
	color: var(--third-color);
	background-color: #1f0a4e;
	border-radius: 15px;
}

/* .login-screen .register-tab 	{  } */


/* .login-screen li a:not(.active)::before { background:url('../images/shape.png') no-repeat top right; height: 100%; width: 100%; content: ''; position: absolute; left: 0; top: 0;  } */
.sign-detail {
	/* padding: 30px 20px 40px; */
	margin: -9px -33px;

}

.sign-detail h2 {
	font-size: 28px;
	line-height: 1.3;
	text-align: center;
	margin-bottom: 20px;
	color: #fff;
	font-family: "MyriadPro";
}

.form-control {
	box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.3);
	border: 1px solid #00acee;
}

.form-control:focus {
	box-shadow: none;
	border-color: var(--secondary-color);
}

.form-group {
	margin-bottom: 10px;
}

.form-control {
	height: auto;
	padding: 7px 10px;
}

.forgot-text a {
	color: var(--third-color);
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	display: block;
	margin-bottom: 10px;
	color: #fff !important;
}

.login-btn {
	display: block;
	width: 100%;
	/* background: url(../images/submit-btn.png) no-repeat center center / 100% 100%; */
	border: none;
	min-height: 50px;
	padding: 0;
	background-color: #5ce7ff;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96, 2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
	font-family: "GrotaSansRd" !important;


}

.disabled-btn {
	opacity: 0.6;
	cursor: not-allowed;
}

.or-text {
	color: var(--third-color);
	margin: 15px 0px;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
}

.or-text span {
	position: relative;
	z-index: 1;
	padding: 0px 10px;
	background: #0b2381;
}

.or-text:before {
	content: '';
	display: block;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: var(--third-color);
	height: 2px;
	z-index: -1;
	position: absolute;
	width: 100%;
}

.facebook-btn {
	margin-bottom: 15px;
}

.g-btn {
	color: var(--primary-color);
	transition-duration: 0.4s;
	background-color: #e8ecf3;
	background-image: linear-gradient(180deg, #e8ecf3 0%, #dee2eb 33%, #b4c1d3 66%, #99a9c3 100%);
}

.g-btn,
.fb-btn {
	position: relative;
	display: block;
	width: 100%;
	border: 0px;
	padding: 12px;
	font-family: 'Raleway', sans-serif;
	font-weight: 900;
	transition: 0.5s;
}

.fb-btn {
	background-color: #6bcffb;
	transition-duration: 0.4s;
	background-image: linear-gradient(180deg, #6bcffb 0%, #41a1f7 33%, #220071 66%, #220071 100%);
	color: var(--third-color);
}

.fb-btn:hover,
.fb-btn:focus {
	background: var(--third-color);
	color: #220071;
	transition-duration: 0.4s;
}

.fb-btn:hover:before,
.fb-btn:focus:before {
	color: #220071;
}

.g-btn:hover,
.g-btn:focus {
	background: var(--primary-color);
	color: var(--third-color);
	transition-duration: 0.4s;
}

.fb-btn:before,
.g-btn:before {
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
	font: normal normal normal 14px/1 FontAwesome;
	font-weight: 900;
}

.fb-btn:before {
	content: '\f09a';
	color: var(--third-color);
	font-size: 26px;
	line-height: 26px;
}

.g-btn:before {
	content: '';
	background: url(../images/google-icon.svg) no-repeat center center / cover;
	width: 26px;
	height: 26px;
}

.register-detail .form-control {
	padding: 5px 10px;
}

.register-detail .facebook-btn,
.object-select,
.register-detail .form-group {
	margin: 10px 0px;
}

.register-detail .or-text {
	margin: 5px 0px;
}

.object-select label {
	color: var(--third-color);
	cursor: pointer;
	padding-left: 5px;
	font-size: 18px;
	font-weight: 900;
}

.coomon-popup-sec {
	background: url('../images/dashboard-bg.png')no-repeat center center /100% 100% !important;
	padding: 30px 20px 40px;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.coomon-popup-sec.confirmation-popup {
	min-height: 250px;
}


.coomon-popup-sec.confirmation-popup .new-create-box button.btn.theme-orange-btn:last-of-type {
	background: transparent !important;
	border: 1px solid #fff !important;
	color: #fff !important;
}

.coomon-popup-sec.confirmation-popup button {
	box-shadow: none !important;
	border: none !important;
	padding: 9px 30px !important;
	display: block;
	color: #3B0E20;
	font-size: 18px;
	line-height: 22px;
	background: #01875F;
	text-align: center;
	border-radius: 8px;
	background: #5ce7ff !important;
	font-family: "MyriadPro";
	font-weight: 700;
	padding: 0 !important;
}

.coomon-popup-sec.confirmation-popup button span {
	background: transparent !important;

}

.coomon-popup-sec.confirmation-popup .model-body {
	padding: 0px 15px;
	text-align: center;
}

.coomon-popup-sec #exampleModalLongTitle {
	margin-top: 20px !important;
}

.object-select .form-group {
	margin: 0px;
	position: relative;
}

.agent-id {
	max-width: 150px;
}

.object-select .jcf-focus,
.jcf-radio {
	border-color: #220071 !important;
}

.jcf-radio span {
	background: #220071;
}

.myAlert-top {
	position: absolute;
	top: 100px;
	right: 23px;
	width: 18%;
	z-index: 100000;

}

.myAlert-top.red {
	background: #ee6c62;
	color: #f1eded;
	font-weight: 600;
	border: none;
	outline: none;
}

.myAlert-top.green {
	background: #4caf50;
	color: #000;
	font-weight: 600;
	border: none;
	width: 18%;
	outline: none;
}

.sign-detail button {
	font-size: 22px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 900;
}

.sign-detail .btn-success {
	padding: 10px;
	border: none;
	background-color: #77e153;
	background-image: linear-gradient(180deg, #77e153 0%, #54c133 31%, #33a01c 72%, #278412 100%);

}

.sign-detail form {
	width: 100%;
	max-width: 80%;
	margin: 0px auto;
	margin-top: 30px;
}

.modal-verify-otp-wrap .modal-body {
	background: transparent;
	border: none;
}

.modal-verify-otp-wrap .modal-header::after {
	display: none;
}

.modal-verify-otp-wrap .modal-body::before,
.modal-verify-otp-wrap .modal-body::after {
	display: none;
}

.green-btn {
	background: url('../images/pop-up/Green-Buttons.png') no-repeat bottom right / cover !important;
	border: 3px solid #94ffe9 !important;
	border-radius: 10px !important;
	min-height: 50px !important;
	-webkit-border-radius: 10px !important;
	-moz-border-radius: 10px !important;
	-ms-border-radius: 10px !important;
	-o-border-radius: 10px !important;
}


.sign-detail .btn-success:hover {
	transition-duration: 0.4s;
	background-color: #278412;
	background-image: none;
}

.modal .register-modal-wrap,
.modal .modal-verify-otp-wrap {
	max-width: 40%;
}

/* .modal .register-modal-wrap .modal-title, .modal .modal-verify-otp-wrap .modal-title { text-transform: uppercase; } */
.modal .register-modal-wrap .new-create-box {
	margin-left: 0px;
	margin-top: 50px;
}

.modal .register-modal-wrap .modal-header h3 {
	margin-left: 50px;
}

.modal .modal-verify-otp-wrap .modal-header h3 {
	margin-left: 50px;
}

.modal-verify-otp-wrap .modal-body .form-group.verify-box {
	/* max-width: 60%; */
	margin: 0 auto;
	max-width: 80%;
	/* max-width: 520px; */
}

.modal-verify-otp-wrap .coomon-popup-sec form {
	width: 100%;
}

.modal-verify-otp-wrap .coomon-popup-sec form input {
	margin-top: 20px;
}

.modal-verify-otp-wrap .modal-body .form-group .form-control {
	/* max-width: 80%; */
	background: url('../images/input-bg.png')no-repeat center center / 100% 100%;
	box-shadow: none;
	border: none;
	text-align: center;
}

.modal-verify-otp-wrap {
	position: relative;
}

.modal-verify-otp-wrap .close {
	position: absolute;
	top: 0;
}

.modal-verify-otp-wrap h3 {
	color: #fff;
	margin-left: 0 !important;
	margin-bottom: 20px !important;
	text-align: center;
	margin-top: 10px !important;
	font-family: "MyriadPro";
	font-weight: 700;
}

.modal-verify-otp-wrap h4 {
	font-size: 16px;
	margin-bottom: 10px !important;
	font-family: "MyriadPro";
	font-weight: 700;
}

.modal-verify-otp-wrap .resend-box {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 80%;
	margin-top: 10px !important;
	margin-bottom: 25px !important;
	font-family: "MyriadPro";
}

.modal-verify-otp-wrap .resend-box button,
.modal-verify-otp-wrap .resend-box span {
	font-family: "MyriadPro" !important;
}

.modal-verify-otp-wrap .resend-box button {
	padding: 0;
}

.modal-verify-otp-wrap button.btn.theme-orange-btn {
	/* background: url(../images/submit-btn.png) no-repeat center center / 100% 100% !important; */
	background: #01875F;
	border: none;
	box-shadow: none;
	width: 100%;
	max-width: 80%;
}

.modal-verify-otp-wrap button.btn.theme-orange-btn span {
	background: transparent;
	color: #fff;
	border: none;
	box-shadow: none;
}

button.btn.theme-orange-btn::after,
button.btn.theme-orange-btn::before {
	display: none;
}

.modal-verify-otp-wrap button.btn.theme-orange-btn::after,
.modal-verify-otp-wrap button.btn.theme-orange-btn::before {
	display: none;
}

.banner-footer {
	background-color: #fff;
}

.banner-footer.high-light p {
	color: #6E1038;
}

.banner-footer.high-light li {
	border: none;
}

/* .sign-detail .form-group .number::-webkit-outer-spin-button,
.sign-detail .form-group .number::-webkit-inner-spin-button,
.join-table-content .form-group .number::-webkit-outer-spin-button,
.join-table-content .form-group .number::-webkit-inner-spin-button,
.modal-verify-otp-wrap .modal-body .form-group .number::-webkit-outer-spin-button,
.modal-verify-otp-wrap .modal-body .form-group .number::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
} */

/* Chrome, Safari, Edge, Opera */

:is(.sign-detail, .join-table-content, .modal-verify-otp-wrap .modal-body) .form-group .number::-webkit-outer-spin-button,
:is(.sign-detail, .join-table-content, .modal-verify-otp-wrap .modal-body) .form-group .number::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */

.sign-detail .form-group .number[type=number],
.join-table-content .form-group .number[type=number],
.modal-verify-otp-wrap .modal-body .form-group .number[type=number] {
	-moz-appearance: textfield;
}

.modal-verify-otp-wrap .modal-body .form-group .resend-box {
	/* max-width: 60%; */
	max-width: 100%;
	margin: 0 auto 50px;
}

.modal-verify-otp-wrap .coomon-popup-sec form .form-group {
	position: relative;
}

.modal-verify-otp-wrap .coomon-popup-sec form i {
	position: absolute;
	top: 60%;
	transform: translateY(-50%);
	right: 15px;
	margin: 0 !important;
}

.modal-dialog .modal-content .modal-body button.resend-otp-btn,
.resend-box p {
	color: #fff;
	font-size: 16px;
	line-height: 22px;
}

.modal-dialog .modal-content .modal-body button.resend-otp-btn {
	opacity: 0.3;
	background: transparent;
	border: none;
	outline: none;
}

.modal-dialog .modal-content .modal-body button.resend-otp-btn.active {
	opacity: 1;
}

.modal-verify-otp-wrap .modal-body h4 {
	margin: 0 auto 40px;
	text-align: center;
	font-size: 20px;
}

.modal-verify-otp-wrap .modal-body h4 span {
	color: #5ce7ff;
}

.sign-detail .form-group i {
	margin-left: -30px;
	cursor: pointer;
	/* filter: brightness(0) invert(1); */
	color: #000;
	z-index: 2;
	margin-top: 0;
}

.sign-detail input[type=password] {
	padding-right: 35px;
}

.after-login .tab-main>.tab-main-body>.tab-content>.tab-pane>div {
	height: 100%;
}

.nav-item button span {
	z-index: 1;
	position: relative;
}

.range-values-wrap {
	position: relative;
}

.range-values-wrap span {
	position: absolute;
	top: 5px;
}

.range-values-wrap span:first-child {
	left: -5px;
}

.range-values-wrap span:nth-child(2) {
	left: 20%;
}

.range-values-wrap span:nth-child(3) {
	left: 38.5%;
}

.range-values-wrap span:nth-child(4) {
	left: 59%;
}

.range-values-wrap span:nth-child(5) {
	left: 78%;
}

.range-values-wrap span:last-child {
	left: 99%;
}


.yellow-buttons-box a {
	font-size: 20px;
	line-height: 22px;
	font-weight: 500;
	font-family: "MyriadPro";
	text-decoration: underline;
	cursor: pointer;
}

.yellow-buttons-box a:hover {
	color: #f2f2f2 !important;
}

.promo-wrap img {
	margin-left: 10px;
	max-width: 100%;
}


.modal-dialog .modal-content .promo-wrap tbody td button {
	padding: 12px 30px;
	font-size: 18px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 800;
}

.modal-dialog .modal-content .promo-wrap p {
	font-size: 22px;
	line-height: 25px;
	font-family: "MyriadPro";
}

/* .promo-wrap .empty-list tbody 	{ overflow-y:auto; height: 300px; } */
.promo-offer-modal .empty-list {
	min-height: 300px;
}

body.modal-open {
	overflow: hidden;
}

.promo-offer-modal .modal-body {
	max-height: 400px;
	overflow-y: auto;
	min-height: 300px;
	background: #581232;
}

.footer-content ul {
	padding-left: 0px;
	margin-top: 40px;
}

.modal-id {
	min-width: 16%;
	font-size: 20px;
	line-height: 25px;
}

.tournamentModal .modal-header .modal-title {
	flex-basis: 100% !important;
}

.footer-content .download-btn {
	margin-top: 20px;
}

.footer-content .download-btn a {}

/* logout modal css starts */
/* .modal .modal-dialog.logout-modal { max-width: 30%; } */
.modal .modal-dialog.logout-modal .modal-content {
	background-color: transparent;
}

.modal-dialog.logout-modal .modal-body {
	padding: 20px 30px;
	text-align: center;
	overflow: hidden;
	border-radius: 10px;
}

.modal-dialog.logout-modal p {
	margin-bottom: 30px;
	font-size: 20px;
	line-height: 25px;
	color: #fff;
	font-family: "GrotaSansRd";
}

.modal-dialog.logout-modal button:first-child {
	margin-right: 50px;
}

.modal-dialog.logout-modal .new-create-box {
	margin-left: 0px;
	display: flex;
	justify-content: center;
}

.modal-dialog.logout-modal .new-create-box button {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	border-radius: 10px;
	box-shadow: none;
	border: none;
}

/* Devloper css */
.modal .joinTableModal {
	max-width: 65%;
}

.joinTableModal .modal-dialog {
	max-width: 100%;
}

.react-bootstrap-table-pagination-total,
.react-bootstrap-table-page-btns-ul li:first-child,
.react-bootstrap-table-page-btns-ul li:last-child {
	display: none;
}

.react-bootstrap-table-pagination li,
.react-bootstrap-table-page-btns-ul li {
	margin: 0 5px !important;
}

.react-bootstrap-table-page-btns-ul li:hover {
	background-color: #220071 !important;
}

.react-bootstrap-table-page-btns-ul li:hover .page-link {
	color: #fff;
	background-color: transparent;
	transition: none !important;
	border: 1px solid #0d6efd;
}

.react-bootstrap-table-page-btns-ul li .page-link:focus {
	box-shadow: none !important;
}

.react-bootstrap-table-pagination {
	padding: 51px;
}

.gameTransaction-table {
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	font-family: "MyriadPro";
	font-weight: 700;
	background-color: #fff;
}

.game-transaction-section .table tbody tr td:nth-child(2) {
	color: #FFDA16;
	text-transform: capitalize;
}

#pageDropDown {
	position: relative;
	padding: 10px;
	width: 100px;
	text-align: right;
}

#pageDropDown:focus {
	box-shadow: none;
}

#pageDropDown::before {
	position: absolute;
	content: "Show";
	left: 25%;
	top: 50%;
	width: 50px;
	transform: translate(-50%, -50%);
}

#pageDropDown::after {
	border-top: .5em solid;
	border-right: .5em solid transparent;
	border-left: .5em solid transparent;
	vertical-align: middle
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
	margin-top: 15px;
}

.tab-content .kyc-body p.error-text {
	margin: 4px 0 0 0;
	font-size: 13px;
	line-height: 15px;
}

.error-red {
	color: #e64646 !important
}

.error-orange {
	color: #f8a407;
}

.react-bootstrap-table-pagination-list .page-item {
	text-transform: capitalize;
	border: 0;
}

.react-bootstrap-table-pagination-list .page-item a {
	border: 1px solid #220071 !important;
	color: #000;
}

.react-bootstrap-table-pagination-list .page-item a:hover {
	border: 0;
	color: #220071;
}

.react-bootstrap-table-pagination-list .page-item:nth-child(2) .page-link,
.react-bootstrap-table-pagination-list .page-item.disabled[title] .page-link {
	color: #000;
	border: 2px solid #C4C4C4 !important;
}

.react-bootstrap-table-pagination-list .page-item:nth-child(2):hover .page-link,
.react-bootstrap-table-pagination-list .page-item.disabled:hover[title] .page-link {
	color: #220071;
	border: 2px solid #220071;
}

.react-bootstrap-table-pagination-list .page-item:hover {
	background-color: transparent !important;
}

.game-transaction-section.passbook-wrap .table tbody tr td:nth-child(2) {
	color: inherit;
}


/* *********** */

/* Another css starts */
.my-acc-header {
	box-shadow: rgb(0 0 0 / 56%) 0px 22px 70px 4px;
}

/* Sidebar css starts */
.sidebar-wrap {
	padding: 25px 20px;
	width: 310px;
	/* background-color: #220071; */
	border-top: 3px solid hsla(0, 0%, 100%, .08);
	/* box-shadow: 0 4px 8px rgb(0 0 0 / 25%); */
	/* -webkit-box-shadow: 0 4px 8px rgb(0 0 0 / 25%); */
	/* flex-shrink: 0; */
	/* background: url('../images/img/side_bar.png') no-repeat bottom right / cover; */
	background: #581232;

}

/* .sidebar-wrap ul                    { position: fixed;  } */
.dashboard-main-sec .table thead th {
	position: relative;
}

.sidebar-wrap li:not(:last-child) {
	margin-bottom: 24px;
}

.sidebar-wrap li a {
	font-size: 18px;
	line-height: 20px;
	color: #fff;
	display: flex;
	display: -webkit-flex;
	align-items: center;
}

.sidebar-wrap li a img {
	margin-right: 18px;
	width: 30px;
	height: 30px;
	object-fit: cover;
	object-position: top;
	flex-shrink: 0;
}

.dashboard-main-sec {
	padding: 44px 80px;
	padding-top: 100px;
	min-height: calc(100vh);
}

.dashboard-main-sec {

	/* width: calc(100% - 310px); */
}

/* my acoount start */
/* .dashboard-main-sec		{ background: url('../') no-repeat bottom right / cover; } */

.user-dtl .row .row {
	margin-top: -33px;
}

.dashboard-main-sec .account-title {
	margin-bottom: 30px;
	font-family: "MyriadPro";
	font-weight: 700;
	color: white;
}

.table-cnt .nav-tabs .nav-link {
	border-radius: 0px;
}

.table-cnt ul {
	outline: none;
	border: none;
}

.passbook-wrap .table-cnt ul,
.personal-detail .table-cnt ul.nav {
	margin-bottom: 30px;
	padding: 10px;
	background: url('../images/tabbar-bg.png')no-repeat center center / cover;
	/* max-width: 60%; */
	/* width: 100%; */
	margin: 0 auto !important;
	margin-bottom: 20px !important;
}

.table-cnt ul {
	gap: 0px;
	display: inline-flex !important;
	width: auto;
	justify-content: space-between;
}

.table-cnt ul li {
	/* width: calc((100% / 3) - 20px); */
	text-align: center;
	outline: none;
	border: none;
	min-width: 180px;
	margin: 0px 10px;
}

.table-cnt ul.nav-tabs li a.nav-link {
	color: #fff;
	width: 100%;
	outline: none;
	box-shadow: none;
	position: relative;
	z-index: 2;
	transition-duration: 0.4s;
	font-size: 20px;
	font-weight: 700;
	line-height: 25px;
	font-family: "GrotaSansRd";
	width: 100%;
	padding: 12px 0px 8px;
	display: inline-block;
	background: url(../images/tab-bg-gray.png) no-repeat;
	background-size: 100% 100%;
}

.table-cnt ul li a:hover {
	color: #FFDA16;
	cursor: pointer;
	transition-duration: 0.4s;
}

.table-cnt ul li a::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.table-cnt ul li a.nav-link.active {
	background: url(../images/tab-bg.png) no-repeat;
	background-size: 100% 100%;
	color: #fff;
}

.personal-detail {
	position: relative;
}

.personal-detail .point-table {
	/* margin-top: 97px; */
	padding: 85px 70px 90px 40px;
	/* background: url(../images/common-bg.png) no-repeat; */
	background-size: 100% 100%;
	background-position: center;
	position: relative;
	z-index: 2;
}

.table-cnt {
	text-align: center;
}

.personal-detail .point-table .tab-content {
	background: url('../images/my-account-bg.png')no-repeat center center / cover;
	text-align: left;
	padding: 20px;
}

.personal-detail .point-table .tab-content .tab-pane {
	padding: 0;
	background: #000;
	max-height: calc(100vh - 500px);
	overflow-y: auto;
	overflow-x: hidden;
}

.personal-detail .table-heading {
	position: absolute;
	top: 20px;
	left: -100px;
	/* transform: translateX(-50%); */
	width: 100%;
	max-width: 400px;
	background: url(../images/common-header-bg.png) no-repeat;
	background-size: 100% 100%;
	background-position: center;
	padding: 20px 0;
	font-size: 25px;
	font-weight: 700;
	font-family: "MyriadPro";
	color: #fff;
	text-align: center;
	z-index: 1;
}

.personal-detail .point-table .tab-content {

	overflow: hidden;
	overflow-y: auto;
}

.my-profile.personal-detail {
	width: 100%;
}

.user-dtl {
	margin-bottom: 30px;
	padding: 37px 0;
}

.user-dtl .row .row>div {
	margin-top: 33px;
}

.user-dtl p {
	margin-bottom: 16px;
	color: #fff;
}

.table-cnt-box {
	box-shadow: none;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	font-family: "MyriadPro";
	font-weight: 700;
	background: transparent;
}

.user-dtl input,
.user-dtl input:disabled,
.user-dtl .basic-txt {
	padding-bottom: 4px;
	background-color: transparent;
	color:#fff;
}

.user-dtl input::placeholder {
	color: #fff;
}

.user-dtl input {
	width: 100%;
	font-size: 20px;
	line-height: 23px;
	font-family: "MyriadPro";
	font-weight: 700;
	border: transparent;
	border-bottom: 1px solid transparent;
}

.edit-user-dtl {
	position: absolute;
	top: 37px;
	right: 40px;
	border: none;
	background: transparent !important;
}

.edit-user-dtl img {
	filter: brightness(0) invert(1);
}


.dashboard-main-sec {
	/* background: url('../images/img/BG_.png') no-repeat bottom right / cover; */
	/* background: rgba(76, 14, 42, 1); */
	background: url('../images/dashboard-common-bg.jpg') no-repeat center center / cover;
}

.tab-content .acc-dtl {
	z-index: 3;
	background-color: #fff;
	padding: 30px 0px 20px;
	height: calc(100% - 35px);
	/* background: url('../images/pop-up/Popup-Box.png')no-repeat center center / 112%; */
	background: transparent;
	box-shadow: none;


}

.tab-content .user-dtl.user-dtl-box {
	margin-bottom: 35px;
	padding: 37px 38px;
	/* background-color: #fff; */
	/* background: url(../images/pop-up/logout-popup.png) no-repeat center center / 187%; */
	background: transparent;
	box-shadow: none;
}

.acc-dtl .inner-title {
	margin-bottom: 15px;
}

.acc-dtl .inner-title,
.tab-content .acc-dtl input,
.acc-dtl .basic-txt,
.tab-content .acc-dtl .basic-txt {
	font-size: 16px;
	line-height: 20px;
	font-family: "MyriadPro";
	font-weight: 700;
}

.account-inner-box {
	padding: 0px 25px 0px 38px;
}

.tab-content .acc-dtl input {
	margin-bottom: 10px;
}

.acc-dtl .account-inner-box img {
	margin-top: -3px;
	margin-right: 5px;
	width: 20px;
}

.kyc-header {
	background-color: transparent;
	padding: 24px 35px 16px;
}

.account-container .point-table .tab-pane.active {
	padding: 0px 15px;
}

.tab-content .kyc-body {
	padding: 40px 17px 34px;
}

.tab-content .kyc-body p,
.cust_dropdown_box select {
	font-weight: 700;
	font-size: 22px;
	line-height: 22px;
	font-family: "MyriadPro";
}

.tab-content .kyc-body p {
	margin-bottom: 5px;
}

/* custom dropdown with arrow image */

.cust_dropdown_box select,
.kyc-dropdown-box .dropdown-kyc-box .inner-dropdown__control {
	padding: 10px 30px 10px 20px;
	border-radius: 12px;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	font-family: "MyriadPro";
	appearance: none;
	-webkit-appearance: none;
	background: url('../images/icons/dropdown-arrow.png') right 10px top 50% / 15px 10px #f2f2f2 no-repeat;
	color: #000;
}

.cust_dropdown_box select,
.transaction-btn-wrap select {
	border: none;
	appearance: none;
	-webkit-appearance: none;
}

.cust-down-aroow {
	position: absolute;
	top: 50%;
	right: 18px;
	transform: translateY(-50%);
	height: auto;
	width: 24px;
}

.kyc-dropdown-box .inner-dropdown__input {
	min-width: unset;
	color: #000;
	font-weight: 700;
	font-size: 22px;
	line-height: 22px;
	font-family: "MyriadPro";
}

.kyc-dropdown-box .inner-dropdown__option {
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	font-family: "MyriadPro";
}

.kyc-dropdown-box .inner-dropdown__option.inner-dropdown__option--is-focused,
.kyc-dropdown-box .inner-dropdown__option.inner-dropdown__option--is-selected {
	background-color: #2a51d0;
	color: #fff;
}

.kyc-dropdown-box .inner-dropdown__control--is-focused {
	border: none;
	outline: none;
}

.cust-dropdown-box {
	position: relative;
}

.change-psw-section form .form-group input,
.inner-withdraw-box input,
.change-psw-section form select,
.my-profile form .form-group input,
form .form-group .css-1pahdxg-control,
form .form-group .css-yk16xz-control,
form .form-group textarea,
.register-detail .form-control {
	padding: 14px;
	height: auto;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	font-size: 18px;
	line-height: 21px;
	color: white;
	width: 100%;
	transition-duration: .3s;
	-webkit-transition-duration: .3s;
	box-shadow: unset;
	background-color: transparent;
	outline: none;
	/* border: none; */
	border: 1px solid rgba(255, 255, 255, 0.5);
}

.small-text a {
	color: #50e1ff;
}

.tab-content .kyc-body input[type='file'],
.report-inner-box .custom-file input[type='file'] {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.custom-file.grey-box {
	border: 1px solid rgba(255, 255, 255, 0.5);
	;
	border-radius: 10px;
	margin-left: auto;
	padding: 35px 35px;
	min-width: 140px;
	max-width: 255px;
	background-color: transparent;
	text-align: center;
	display: block;
	height: 144px;
	/* background-image: url("../images/img/UPLOAD_CARD_BASE.png")no-repeat center center / cover; */

}

.custom-file.grey-box p {
	font-size: 15px;
	line-height: 20px;
	font-family: "MyriadPro";
	font-weight: 700;
	color: white
}

.custom-file.grey-box img {
	margin-bottom: 7px;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	/* filter: brightness(0) invert(1); */
}

.kyc-med-text {
	text-align: center;
	font-size: 18px;
	margin: 15px 0;

}

.uploadicon-img {
	filter: brightness(0) invert(1);
}

.other-kyc .custom-file img {}

.remove-img-btn img {
	filter: brightness(0) invert(1);
}

.custom-file.grey-box span {
	color: #fff;
}

.report-inner-box .custom-file,
.custom-file {
	position: relative;
	width: auto;
	height: 100%;
}

.kyc-box {
	margin-bottom: 30px;
	z-index: 3;
	/* background-color: #fff; */
	background-image: url("../images/img/POP-UP_BASE.png")no-repeat center center / cover;
}

.kyc-box .kyc-body {
	padding-right: 120px;
}

.bank-details {
	padding: 20px;
}

.bank-details-wrap label {
	font-size: 22px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 700;
}

.bank-details-wrap .form-group {
	margin-bottom: 20px;
	color: white;
}

.bank-details-wrap button {
	text-transform: capitalize;
	padding: 14px 28px;
	border: none;
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
}



.my-acc-header .logged-in {
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.my-acc-header .logged-in .navbar {
	position: unset;
}

.account-container {
	/* height: calc(100vh - 80px); */
	display: flex;
	padding: 40px 0;
	padding-top: 100px;
	background: url('../images/main-slider-bg.jpg') no-repeat center / cover;
	/* background: url(../images/after-login-bg.png) no-repeat center / cover; */
	/* height: calc(100vh - 80px); */
	height: 100vh;

}

.sidebar-wrap {
	height: 100%;
}

.sidebar-wrap>ul {
	height: 100%;
	overflow-y: auto;
}

.dashboard-main-sec {
	height: 100%;
	overflow-y: auto;
	/* box-shadow: inset 10px 20px 30px rgb(0 0 0 / 76%); */
	display: flex;
	/* align-items: center; */
	justify-content: center;
	width: 100%;
}

.sign-detail .object-select input[type=radio] {
	opacity: 1 !important;
	width: 15px;
	height: 15px;
}

/* .sign-detail .object-select label { margin-left: 20px; } */
.register-detail .form-control,
.signup-screen .form-control {
	padding: 10px;
	/* background: url('../images/input-bg.png')no-repeat center center / 100% 100%; */
	color: white;
	font-family: "MyriadPro";
	font-family: 400;
	color: #000;
	/* border-image: linear-gradient(#699ad3, #ebebeb) 10; */
	position: relative;
	min-height: 45px;
	margin-top: 0 !important;
	background-color: #e9e9e9;
	border-radius: 8px;

}

.sign-detail .form-group i {
	/* margin-top: -10px; */
}

.register-detail .form-group {
	margin-bottom: 0px;
}

.sign-detail .female-btn>* {
	margin-top: 0px;
	margin-bottom: 0px;
}

.sign-detail .female-btn input {
	margin: 0px !important;
}



/* chnage password css */
.change-psw-section .kyc-body {
	padding: 40px 35px;
}

.change-psw-section .kyc-body,
.invite-wrap .kyc-body {
	margin: 0 auto;
	/* max-width: 70%; */
	color: white;
}

.change-psw-section .table-cnt-box {
	font-family: "MyriadPro";
	font-size: 20px;
	line-height: 22px;
	background: url('../images/img/POP-UP_BASE.png')no-repeat center center / 200%;
	border: 2px solid white;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
}

.change-psw-section .table-cnt-box .form-group:not(:last-child) {
	margin-bottom: 31px;
	color: white;

}

.change-psw-section .table-cnt-box .form-group:last-child {
	margin-bottom: 0px !important;
}

.change-psw-section .table-cnt-box .form-group input {
	margin-top: 5px;
}

.change-psw-section .table-cnt-box button {
	font-size: 22px;
	line-height: 25px;
	text-transform: capitalize;
	color: #5F4501;
}

.change-psw-section .table-cnt-box button:hover {
	color: #000;
}

.change-psw-section .table-cnt-box form button {
	margin-top: 53px;
}

.change-psw-section .form-group i {
	margin-left: -35px;
	cursor: pointer;
	margin-top: 18px;
}

.joinTableModal.ForgetPasswordWrap .modal-body {
	padding: 60px 30px;
}

.joinTableModal.ForgetPasswordWrap .modal-body .form-group .form-control {
	width: 60%;
	margin: 0 auto;
}

.joinTableModal.ForgetPasswordWrap .modal-body .theme-orange-btn span {
	padding: 16px 46px;
}

.joinTableModal.ForgetPasswordWrap .modal-body .new-create-box {
	margin-top: 35px;
}

.sign-detail .forgot-text a {
	display: inline-block;
	cursor: pointer;
	font-size: 18px;
	font-weight: 900;
	margin-top: 10px;
}

.sign-detail .forgot-text a:hover {
	color: #FFDA16 !important;
}


/* game transaction tab starts */

.dashboard-main-sec .game-transaction-heading {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 400px;
	background: url(../images/common-header-bg.png) no-repeat;
	background-size: 100% 100%;
	background-position: center;
	padding: 20px 0;
	font-size: 25px;
	font-weight: 700;
	font-family: "MyriadPro";
	color: #fff;
	text-align: center;
	z-index: 1;
}

.passbook-wrap .game-transaction-section {
	margin-top: 0;
}

.game-transaction-section {
	position: relative;
	/* padding-top: 20px; */
	margin-top: 90px;
	max-width: 90%;
	margin-left: 30px;
	padding: 20px;
	background: url('../images/my-account-bg.png')no-repeat center center / cover;
}

.game-transaction-section.bonus-transaction-section {
	padding-top: 25px;
}

.game-transaction-section .game-transaction-table {
	/* background: url(../images/common-bg.png) no-repeat; */
	background-size: 100% 100%;
	/* padding: 80px 120px; */
	position: relative;
	z-index: 2;
}

.game-transaction-section .table-cnt-box {
	max-height: 300px;
	overflow: hidden;
	overflow-y: auto;
	background: #000;
}

.game-transaction-section .table tbody tr td {
	border-bottom: 2px solid #C4C4C4;
	font-size: 18px;
	padding: 20px 10px;
}

.game-transaction-section .table tbody td {
	min-width: 100px;
	background-color: transparent;
	color: #fff;
}

.game-transaction-section .table {
	color: #000;
	font-size: 17px;
	line-height: 20px;
	font-family: "MyriadPro";
	font-weight: 700;
}

.game-transaction-section .table th {
	color: #fff;
}

.blue-txt {
	color: #1841C9;
}

.transaction-btn-wrap {
	/* background-color: #fff; */
	padding: 20px 0px;
	/* box-shadow: 0 0 10px rgb(0 0 0 / 8%); */
}

.transaction-btn-wrap .cust-dropdown-wrap {
	display: inline-block;
	width: 20%;
}

.transaction-btn-wrap .pagination-wrap {
	width: 30%;
	margin-left: auto;
	margin-right: 10px;
}

.transaction-btn-wrap .cust-dropdown-box {
	max-width: 120px;
	margin: 0 auto;
}

/* Pagination css */
#pageDropDown,
.dropdown-blue-box .inner-dropdown__control,
/* .transaction-btn-wrap .cust-dropdown-box select { padding: 10px 30px 10px 10px; color: #fff; background-color: #4067dd; background-image: linear-gradient(180deg, #4067dd 0%, #2d55d5 46%, #2649c6 100%); font-size: 16px; font-family: "MyriadPro"; font-weight: 500;  } */
.transaction-btn-wrap .cust-dropdown-box select {
	appearance: none;
	position: relative;
	padding: 5px 30px 5px 10px;
	color: #fff;
	font-size: 16px;
	font-family: "MyriadPro";
	border-color: transparent !important;
	box-shadow: none !important;
	font-weight: 500;
	background: url('../images/icons/down-arrow.png') right 10px top 50% / 15px 10px #5ce7ff no-repeat;
}

.dropdown-blue-box .inner-dropdown__control:hover,
.dropdown-blue-box .inner-dropdown__control:focus {
	border-color: transparent !important;
}

.dropdown-blue-box .inner-dropdown__value-container,
.dropdown-kyc-box .inner-dropdown__value-container {
	padding: 0px;
}

/* .inner-dropdown__menu li {
	background: red !important;
} */

.dropdown-blue-box .inner-dropdown__single-value,
.dropdown-blue-box .inner-dropdown__input-container,
.dropdown-kyc-box .inner-dropdown__single-value,
.dropdown-kyc-box .inner-dropdown__input-container {
	margin: 0px;
}

.dropdown-blue-box .inner-dropdown__single-value,
.dropdown-blue-box .inner-dropdown__input-container {
	color: #fff;
}

.dropdown-blue-box .inner-dropdown__input {
	min-width: unset;
	color: #fff;
	font-size: 16px;
	font-family: "MyriadPro";
	font-weight: 500;
}

.dropdown-blue-box .inner-dropdown__option {
	font-size: 16px;
	font-family: "MyriadPro";
	font-weight: 500;
}

.dropdown-blue-box .inner-dropdown__option.inner-dropdown__option--is-focused,
.dropdown-blue-box .inner-dropdown__option.inner-dropdown__option--is-selected {
	background-color: #2a51d0;
	color: #fff;
}

.transaction-btn-wrap .cust-down-aroow {
	right: 5px;
	width: 18px;
}

.pagination-wrap .pagination {
	justify-content: center;
}

.pagination .prev-btn span:first-child,
.pagination .next-btn span:first-child {
	display: none;
}

.pagination .page-item .prev-btn,
.pagination .page-item .next-btn {
	min-width: 90px;
	/* border: 2px solid #C4C4C4 !important; */
	text-transform: capitalize;
}

.pagination .page-item .prev-btn {
	margin-right: 6px !important;
}

.pagination .next-btn {
	margin-left: 6px !important;
}

.pagination-wrap .pagination {
	align-items: center;
}

/* logout modal css starts */
.modal .modal-dialog.logout-modal {
	max-width: 30%;
}

.modal-dialog.logout-modal .modal-body {
	padding: 20px 30px;
	text-align: center;
}

.modal-dialog.logout-modal p {
	margin-bottom: 30px;
	font-size: 18px;
	line-height: 25px;
	color: #fff;
	font-family: "MyriadPro";
}

.modal-dialog.logout-modal button:first-child {
	margin-right: 20px;
}

.modal-dialog.logout-modal .new-create-box {
	margin-left: 0px;
}

/* Passbook start */

.passbook-section-heading {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 400px;
	background: url(../images/common-header-bg.png) no-repeat;
	background-size: 100% 100%;
	background-position: center;
	padding: 20px 0;
	font-size: 25px;
	font-weight: 700;
	font-family: "MyriadPro";
	color: #fff;
	text-align: center;
	z-index: 1;
}

.passbook-wrap .point-table {
	position: relative;
	padding-top: 20px;
}

.passbook-wrap .point-table>.table-cnt {
	/* padding: 85px 120px 90px; */
	/* background: url(../images/common-bg.png) no-repeat; */
	background-size: 100% 100%;
	background-position: center;
	position: relative;
	z-index: 2;
}

.passbook-wrap .point-table .table-cnt ul {
	/* padding: 0px 40px; */
}

.insufficientChips-popup p {
	font-size: 25px !important;
}

.passbook-wrap .tab-content ul li {
	width: auto;
	margin: 0;
}

.passbook-wrap .table-cnt ul li a::before {
	content: unset;
}

.passbook-wrap .tab-content ul li {
	box-shadow: none;
}

.passbook-wrap .table thead th {
	background-color: transparent;
}

.passbook-wrap .table-cnt ul.pagination {
	margin-bottom: 0px;
}

.passbook-wrap .table-cnt ul.pagination li {
	min-width: unset;
}

.txt-success {
	color: #008C00;
}

.txt-pending {
	color: #BE0000;
}

.page-item .page-link {
	outline: none !important;
	border: solid 1px !important;
	border-color: #dee2e6 !important;
}

.page-item.active .page-link {
	background-color: #5ce7ff;
	outline: none !important;
	background: #5ce7ff;
}

.page-item.active .page-link:hover {}

.page-item .page-link:hover,
.pagination .page-item .prev-btn:hover {
	background-color: #5ce7ff !important;
	background: unset;
	box-shadow: unset;
	color: #fff;
	border-color: #fff;
	border: 1px solid;
}

.page-item.active .page-link:hover {
	background-color: #5ce7ff !important;
	color: #fff !important;
}

.pagination .page-item .page-link::before {
	display: none;
}

.pagination .page-item .page-link:focus {
	box-shadow: none;
}

.page-item:not(:first-child) .page-link {
	margin-left: 0px;
}

.txt-grey {
	color: #707070;
	font-family: "MyriadPro";
	font-weight: 500;
}

.inner-withdraw-box .txt-grey {
	font-size: 18px;
	line-height: 20px;
}

.inner-withdraw-box {
	padding: 40px;
	font-family: "MyriadPro";
	font-weight: 700;
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.inner-withdraw-box input {
	width: 55%;
	margin: 0 auto 35px;
	font-size: 22px;
	line-height: 25px;
	color: #707070;
	font-weight: 700;
}

.inner-withdraw-box [type=number]::-webkit-inner-spin-button,
.inner-withdraw-box [type=number]::-webkit-outer-spin-button {
	display: none;
}

.inner-withdraw-box h4.big {
	margin-bottom: 17px;
	line-height: 25px;
}

.inner-withdraw-box p {
	font-size: 18px;
	line-height: 20px;
}

.passbook-withdraw-wrap .inner-withdraw-box button {
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	border: none;
}

.passbook-withdraw-wrap .inner-withdraw-box button:first-child {
	margin-right: 45px;
}

.passbook-withdraw-wrap .inner-withdraw-box button:last-child {
	margin-left: 45px;
}

.passbook-withdraw-wrap .inner-withdraw-box {
	background-color: #fff;
}

.passbook-withdraw-wrap .inner-withdraw-box p {
	margin-bottom: 21px;
}

/* QA changes for the filter showing 29Apr */
.hide {
	display: none;
}

.collapse.player-filter,
.player-filter.show {
	position: absolute;
	left: 0;
	top: 95px;
	width: 100%;
	display: block;
}

.change-psw-section button.common-btn {
	min-width: 146px;
	padding: 13px 25px;
}

.page-link {
	color: #284cca;
}


/* Withdraw tab starts */
.withdraw-page {
	position: relative;
	/* padding-top: 20px; */
	margin-top: 80px;
	width: auto;
	/* width: 100%; */
	max-width: 90%;
	margin-left: 40px;
	background: url('../images/my-account-bg.png')no-repeat center center / cover;
	padding: 20px;
}

.withdraw-page .account-title {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 400px;
	background: url(../images/common-header-bg.png) no-repeat;
	background-size: 100% 100%;
	background-position: center;
	padding: 20px 0;
	font-size: 25px;
	font-weight: 700;
	font-family: "MyriadPro";
	color: #fff;
	text-align: center;
	z-index: 1;
}

.withdraw-blue-box {
	position: relative;
	min-height: 400px;
	padding: 40px 35px;
	margin: 0 auto;
	/* max-width: 90%; */
	/* background: url(../images/common-bg.png)no-repeat; */
	/* background: #000; */
	background-size: 100% 100%;
	z-index: 2;
}

.withdraw-blue-box p {
	margin-bottom: 30px;
	color: #fff;
	font-size: 17px;
	line-height: 22px;
	font-family: "MyriadPro";
	font-weight: 700;
}

.withdraw-blue-box .form-group {
	margin-bottom: 57px;
}

.withdraw-blue-box .form-control {
	padding: 15px 30px;
	border-radius: 10px;
	font-size: 17px;
	line-height: 21px;
}

/* Report problem starts */
.dashboard-main-sec .reporting-box .account-title {
	margin-bottom: 83px;
	max-width: 90%;
}

.report-inner-box {
	max-width: 90%;
	margin: 0 auto 10% 0;
	color: white;
}

.report-inner-box label {
	font-size: 22px;
	line-height: 25px;
	font-family: "MyriadPro";
	font-weight: 700;
}

/* .reporting-box.change-psw-section button  { border-radius: 13px; } */
.reporting-box .form-control:focus {
	background-color: transparent;
	color: #fff;
	border-color: #fff;
}

.change-psw-section form select {
	appearance: none;
	position: relative;
	background: url('../images/icons/Rummy\ Round\ Web1.png') right 10px top 50% / 25px 16px #f2f2f2 no-repeat;
}

/* .change-psw-section form  select::after { content: ''; position: absolute; right: 0; top: 0; height: 24px; width: 24px; background: url('../../Assets/images/icons/down-arrow.png') #000; } */
.browse-file-box {
	margin-bottom: 0px;
}

.browse-file-box input {
	max-width: 90%;
}

/* .browse-file-box .problem-attach { } */
.report-inner-box button {
	padding: 15px 40px;
	display: inline-block;
	border: none;
	/* box-shadow: rgb(0 0 0 / 20%) 0px 0px 15px; */
	z-index: 0;
}

.btn-boxshadow {
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 15px !important;
	padding: 10px 30px;
	border: none;
}

.report-inner-box .problem-attach input[type="text"] {
	height: 100%;
	z-index: 2;
}

.change-psw-section .report-inner-box form .form-group select,
.change-psw-section .report-inner-box .problem-attach input {
	padding: 20px 14px;
}

.report-inner-box p {
	color: #fff;
	font-family: "MyriadPro";
	font-weight: 700;
}

.problem-btn-box {
	/* margin-top: 50px; */
	max-width: 37%;
}

.report-inner-box .problem-btn-box button {
	z-index: 1;
}

.report-inner-box .problem-btn-box button.common-outline-btn {
	padding: 10px 8px;
}

.select-custom__placeholder {
	color: #fff !important;
}

/* Invite page starts */
.invite-wrap {
	position: relative;
	padding-top: 15px;
	width: 100%;
}

.invite-wrap .account-title {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 400px;
	background: url(../images/common-header-bg.png) no-repeat;
	background-size: 100% 100%;
	background-position: center;
	padding: 20px 0;
	font-size: 25px;
	font-weight: 700;
	font-family: "MyriadPro";
	color: #fff;
	text-align: center;
	z-index: 1;
}

.invite-card {
	position: relative;
	margin: 70px auto 0;
	max-width: 70%;
	background: url(../images/my-account-bg.png) no-repeat;
	background-size: 100% 100%;
	z-index: 2;
}

.kyc-body {
	padding: 30px 50px 20px;
}

.invite-box h3 {
	font-size: 26px;
	line-height: 30px;
	font-family: "MyriadPro", sans-serif;
	font-weight: 700;
	color: white;
}

.copy-button {
	background: transparent;
	color: red;
}

.share-button button {
	gap: 11px;
	display: inline-flex;
	align-items: center;

}

.code-display {
	gap: 15px;
	display: inline-flex;
	align-items: center;

}

.invite-box a {
	display: inline-block;
	font-size: 19px;
	line-height: 22px;
	font-family: "MyriadPro", sans-serif;
	font-weight: 700;
	color: white;
	margin-bottom: 30px;
}

.invite-img-box {
	margin: 30px 0;
}

.invite-img-box img {
	filter: brightness(0) invert(1);
}

.invite-info-box {
	position: absolute;
	top: 30px;
	right: 30px;
}

.invite-info-box img {
	width: 30px;
	filter: brightness(0) invert(1);
}

/* Additional styles for layout and responsiveness */
.invite-box-shadow {
	position: relative;
}

.d-flex {
	display: flex;
}

.align-items-center {
	align-items: center;
}

.justify-content-center {
	justify-content: center;
}

.text-center {
	text-align: center;
}

/* Responsive adjustments may be needed */
@media (max-width: 768px) {
	.invite-card {
		max-width: 90%;
	}

	.kyc-body {
		padding: 20px 30px 15px;
	}

	.invite-box h3 {
		font-size: 22px;
	}
}




/* Reported page starts */
.reported-inner-box.report-inner-box {
	max-width: 88%;
	margin: 0 auto;
}

.reported-inner-box label {
	color: #fff;
	font-size: 20px;
}

.modal-body .reported-modal-box .form-group .form-control {
	background-color: #163a95;
	box-shadow: none;
	color: #fff;
	padding: 13px 17px;
}

.modal-body .reported-modal-box .change-psw-section form .form-group input {
	background-color: #163a95;
	box-shadow: none;
	color: #fff;
}

.modal-body .reported-modal-box .problem-btn-box {
	max-width: unset;
}

.modal-body .complain-form button {
	margin: 0 auto;
	border-radius: 12px;
	padding: 18px 35px;
}

.red-btn {
	color: #fff;
	background-color: #ec154b;
	background-image: linear-gradient(180deg, #ec154b 0%, #e70100 25%, #9c0001 64%, #990000 89%, #a70000 100%);
}

.modal-dialog.modal-dialog-centered.complain-wrap {
	max-width: 953px;
}

.complain-wrap .modal-body {
	padding: 50px 0px 30px;
}

.complain-form>div {
	margin-bottom: 0px !important;
}

.complain-form {
	width: 81%;
	margin: 0 auto;
}

.complain-form label {
	text-align: right;
	padding: 0;
	font-size: 20px;
	line-height: 25px;
	font-family: "MyriadPro";
	font-weight: 700;
	color: #fff;
}

.modal-body .complain-form .form-group .form-control,
.complain-form input:focus,
.complain-form input,
.complain-form textarea.form-control {
	margin-bottom: 27px;
	border-radius: 8px;
	box-shadow: none;
	padding: 14px 17px;
	font-size: 20px;
	line-height: 25px;
	background: #1c0e4a;
	border-color: transparent;
	color: #fff;
}

textarea.form-control::placeholder {
	color: #fff;
}

.complain-form input[type="text"]::-webkit-input-placeholder,
.complain-form input[type="password"]::-webkit-input-placeholder,
.complain-form input[type="email"]::-webkit-input-placeholder {
	color: #fff;
	font-size: 20px;
	line-height: 25px;
}

.complain-form .form-check {
	margin-top: 40px;
}


.sidebar-wrap ul li {
	position: relative;
}

.sidebar-wrap ul li::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: -12px;
	left: 0;
	background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.7) 25%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 100%);
}

.sidebar-wrap ul li:last-child::after {
	content: unset;
}

.dropdown-blue-box span,
.dropdown-blue-box svg,
.dropdown-blue-box .inner-dropdown__indicators {
	display: none;
}

.kyc-dropdown-box .inner-dropdown__indicator,
.kyc-dropdown-box .inner-dropdown__indicator-separator {
	display: none;
}


/* 23 May 2022 */
.banner-area img {
	max-width: 100%;
}

/* loader css */
.loader {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0%;
	left: 0%;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	flex-direction: column;
	-webkit-flex-direction: column;
	visibility: visible;
	z-index: 300000;
	background: rgba(0, 0, 0, 0.50);
	opacity: 1;
	transition-duration: .3s;
	-webkit-transition-duration: .3s;
}

.load-img {
	text-align: center;
	margin-bottom: 15px;
}

.load-img img {
	max-height: 55px;
	max-width: 225px;
}

.load-me {
	width: 142px;
	height: 40px;
	background: transparent;
	filter: contrast(20);
	position: relative;
}

.load-me .dot {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 12px;
	left: 15px;
	background: #f4ce00;
	border-radius: 50%;
	transform: translateX(0);
	animation: dot 2.8s infinite;
}


@keyframes dot {
	50% {
		transform: translateX(96px);
	}
}

@-webkit-keyframes dot {
	50% {
		transform: translateX(96px);
	}
}

.load-me .dots {
	transform: translateX(0);
	margin-top: 12px;
	margin-left: 31px;
	animation: dots 2.8s infinite;
}

@keyframes dots {
	50% {
		transform: translateX(-31px);
	}
}

@-webkit-keyframes dots {
	50% {
		transform: translateX(-31px);
	}
}

.load-me .dots span {
	display: block;
	float: left;
	width: 16px;
	height: 16px;
	margin-left: 16px;
	background: #fff;
	border-radius: 50%;
}

.practice .tournament-main-wrap .common-btn {
	padding: 20px 20px;
}

.register-modal-wrap button.close,
.modal-verify-otp-wrap button.close {
	background-color: unset;
	border: none;
}

.new-create-box button:last-child {
	margin-left: 20px;
}

.sign-detail .google-btn,
.sign-detail .facebook-btn {
	position: relative;
}

.sign-detail .facebook-btn button,
.sign-detail .g-btn button {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 0 !important;
}

.user-dtl .react-date-picker__wrapper,
.user-dtl .react-date-picker--disabled {
	border: unset;
	background: transparent;
}

.react-date-picker__calendar {
	z-index: 4;
	inset: 100% auto auto 0px !important;
}

.state-options {
	z-index: 5;
}

.side-btn button:last-child {
	margin-left: 25px;
}

.side-btn .common-btn:disabled,
.kyc-doc-btns .common-btn:disabled,
.change-password-wrapper .submit-btn.common-btn:disabled,
.withdraw-blue-box .common-btn.yellow-btn:disabled,
.problem-btn-box button:disabled,
.upload-btn-box input:disabled+button {
	opacity: 0.3;
}

.no-data {
	margin-top: 45%;
	flex-direction: column;
	width: 100%;
}

.tournament-tab-wrap,
.no-data-wrap {
	height: 100%;
}

.no-data img {
	margin-bottom: 30px;
	width: 100px;
}

/* 14 jun 22 */
.game-detail-left-box h3 {
	padding-left: 30px;
}

.empty-list {
	height: 100%;
}

.verified-box img {
	margin-left: 5px;
	height: 20px;
	width: 20px;
}

.wallet-sec a img {
	width: 25px;
}

.kyc-document-box {
	position: relative;
}

.remove-img-btn {
	position: absolute;
	top: 5px;
	right: 5px;
}

/* .kyc-body p.error-text 				{ position: absolute; top: 0; left: 0; } */
.err-msg-box {
	padding-bottom: 15px;
}

.tab-content .kyc-body .err-msg-box p.error-text {
	position: absolute;
	bottom: 0px;
	right: 40px;
}

.tab-content .kyc-body .kyc-document-box .err-msg-box p.error-text {
	bottom: -15px;
	left: 0px;
}

/* 21jun 22 */
.back-btn {
	background-color: #4067dd;
	margin-bottom: 15px;
	padding: 0px;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.back-btn:hover {
	background-color: #FFD325;
	transition-duration: 0.4s;
}

.table-transaction.back-btn {
	background-color: #FFD325;
	position: absolute;
	top: -30px;
	left: 20px;
}

.back-btn img {
	width: 22px;
	height: 22px;
	object-fit: cover;
	object-position: left;
	transition-duration: 0.4s;
}

.back-btn:hover img {
	object-position: right;
}

.table-transaction.back-btn:hover {
	background-color: #4067dd;

}

.back-btn:hover {
	color: #FFD325;
}

.game-transaction-section .back-btn.common-btn {
	background-color: #fff;
}

/* 27 jun 22  */

.before-login .navbar-collapse .navbar-nav>.nav-item {
	padding: 0px 25px;
}

.about-us-content p,
.contact-area p,
.highlight-list li {
	font-family: "MyriadPro";
	font-weight: 400;
}

.form-inline .form-group .form-control {
	box-shadow: none;
	border: 1px solid #ced4da;
}

.block-desc ul,
.download-area ul,
.how-to-play-area ul {
	padding-left: 0px;
}

.contact-detail h5 {
	font-family: "MyriadPro";
	font-weight: 700;
}

/* 28 jun 22 */
.pagination-wrap ul.pagination {
	/* padding-right: 40px; */
	background: transparent;
}

/* Download page */
.hero-content .company-name {
	color: #01875F;
}

.hero-content>figure>img {
	max-width: 75px;
}

.hero-content h2 {
	margin-bottom: 5px;
}

.hero-content .company-desc {
	color: #7B7B7B;
}

.stats-content li p:last-child {
	font-size: 10px;
	line-height: 15px;
	color: #7b7b7b;
}

.stats-content li:not(:first-child) {
	border-left: 2px solid #d9d9d9;
	padding-left: 20px;
}

.rating {
	border: 1px solid #000000;
	padding: 5px;
	display: inline-block;
}

.download-icon {
	margin-right: 5px;
	font-weight: bold;
}

.download-btn a {
	padding: 9px 30px !important;
	display: block;
	color: #fff;
	font-size: 18px;
	line-height: 22px;
	background: #01875F;
	text-align: center;
	border-radius: 8px;
	background: #01875F;
	background-color: #5ce7ff;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96, 2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
	font-family: "GrotaSansRd" !important;
	font-weight: 700;
}

.game-screenshot li {
	width: calc(100% / 3 - 10px);
}

.game-screenshot li img {
	border-radius: 10px;
}

.review-content p {
	color: #7B7B7B;
	margin-bottom: 15px;
}

.review-content ol {
	margin-top: 25px;
}

.review-content ol li {
	margin-bottom: 15px;
	color: #7B7B7B;
}

.new-highlight-list ul {
	padding-left: 15px;
}

.new-highlight-list ul li {
	list-style: disclosure-closed;
}

.rating-title {
	padding-top: 25px;
}

.star-list {
	color: #01875f;
}

.rating-list li {
	margin: 10px 0px;
}

.rating-list p {
	width: 35px;
}

.rating-list .progress {
	width: calc(100% - 35px);
}

.progress-bar {
	background: #01875f;
}

.download-area {
	margin-top: 80px;
}

/* .navbar-nav .app-links .nav-link:hover:before
 a:hover {  } */
.navbar-nav.app-links .nav-link.active:before,
.navbar-nav.app-links .nav-link:hover:before,
.navbar-nav.app-links .nav-link:focus:before {
	width: 0px !important;
}

.report-inner-box .custom-file input[type='file'] {
	z-index: 2;
}

.report-inner-box .upload-btn-box {
	margin-left: auto;
}

.report-inner-box .custom-file>div>p {
	padding-top: 10px;
}

.report-inner-box .close-btn {
	box-shadow: unset;
	min-width: 16px;
}

.report-inner-box .mb-30 {
	margin-bottom: 30px;
}

.select-custom__indicators {
	width: 45px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAANCAIAAAA8DjmHAAABS2lDQ1BIUCAyMmVzAAAYlWNgYLyRk5xbzMLAwJCbV1IU5O6kEBEZpcD+jIGZgZOBi0GVgSMxubjAMSDAB6iEAUajgm/XGBhB9GVdkFnpy5xPeF5azs+tVxF29f5kFkz1KIArJbU4GUj/AWKt5IKiEgYGRg0gW7m8pADEBtknUgR0FJCdAGKnQ9glIHYShN0BVhMS5AxkzwGyBZIzElOA7DVAtk4Skng6Ejs3pzQZ6gaQ63mgbD4glmHwYAhgUGAwAsJUhmIGhpLUCpB9DM75BZVFmekZJQqOBQU5qQqeecl6OgpGBkZGDAygcIMY8fk82ERGiSSEWKo0A4PxCqAgJ0IsS46BYfc6BgbB6Qgx9blAp61iYDjQU5BYlAgPJcZvLMVpxkYQNo8UAwPrgf//P/1nYGAHhsnfc////57x///faQwMzF8YGPb7AQA1bVxlcz7d9AAAAAlwSFlzAAALEwAACxMBAJqcGAAAAO9JREFUKJGl0jGOhSAQANDJZxMK7WhptccL4AXwBuIR9Cp6AFqtiJ6ITgsSSeYEW/iza3b9xN1PBTPDA8JA27bw9ui6DhBRa/2O0jQNIgIihhCUUv9TqqoKITwhRPTeSyn/qpRl6b0/hCeEiOu6CiHuK0VRbNv2tf0bQkTnXJZld5Q8z51z572Pc5oxtiwL5zyucM7neWaMnYOPO0V3DvsJHde21iZJ8juVpqm19vL5FxAACCGmaaKUnoOU0nEcX33INQQAUkpjDCHkWBJCjDGRFnkJAYBSqu/7Yz4MQ7xpPyI5ANBa7/sOAHVdxys/AXm7gsGV6uqGAAAAAElFTkSuQmCC) right 10px top 50% / 20px 12px #f2f2f2 no-repeat !important;
}

.select-custom__indicator>svg,
.select-custom__indicator-separator {
	display: none;
}

/* 6 July */
.file-wrapper {
	width: 50%;
	max-width: 100%;
	background: #f2f2f2;
	padding: 15px;
}

.file-wrapper .file-details {
	padding-top: 10px;
}

.file-wrapper .file-details p {
	color: #000;
}

.sizing-error p {
	margin-right: -20px;
}

#exampleModalLongTitle {
	/* margin-left: 120px; */
	color: #fff;
	margin-bottom: 20px;
}

/* Reported problem after developement changes */
.complain-wrap .modal-header .modal-title h3 {
	margin-left: 100px;
}

/* QA changes */
.problem-btn-box .common-outline-btn {
	font-size: 22px;
	line-height: 25px;
}

/* .select-custom__option-is-selected { background-color: red !important; } */
/* .select-custom__option		{ background-color: #4067dd; } */
.select-custom__option--is-selected {
	background-color: red;
}

.select-custom__menu-list {
	background-color: #fff !important;
	color: #000 !important;
}

.select-custom__option--is-focused,
.select-custom__option--is-selected {
	background: #5ce7ff !important;
	color: #000 !important;
}

.select-custom__menu {
	background-color: red !important;
}

.select-custom__single-value {
	color: white !important;
}

/* 12 jul QA new requirement */
.club-info {
	padding: 5px;
	/* background-color: #14006A; */
	background: url(../images/img/BG_.png) no-repeat center / cover;
	border-radius: 5px;

}

.club-info p {
	padding: 0px;
	color: #f3f3f3;
	font-size: 10px;
	line-height: 10px;
}

.user-dtl .common-btn {
	min-width: unset !important;
	max-width: 100%;
}

.user-dtl .common-btn {
	font-size: 15px;
	line-height: 15px;
	padding: 18px 21px;
	border: none;
}

.modal .agentModal-wrap .modal-header h3 {
	margin-left: 13%;
}

.app-download-box .app-links li:not(last-child) {
	margin-bottom: 15px;
}

.app-download-box .app-links img {
	max-width: 200px;
	width: 200px;
	height: auto;
	max-height: unset;
}

.app-download-box ul {
	padding: 116px;
	text-align: left;
}

.rotate-box {
	position: absolute;
	content: '';
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999999999999;
	width: 100%;
	height: 100vh;
	/* background: rgba(0,0,0,0.1); */
	background: rgba(0, 0, 0, .7);
}

.rotate-box,
.rotate-box>div {
	min-height: 100vh;
	display: none;
	align-items: center;
	justify-content: center;
}

.rotate-box div {
	text-align: center;
}

.rotate-box img {
	max-width: 100%;
}

/* 18 jul new designing  */
.practice .tournament-main-wrap .common-btn.yellow-btn {
	color: #5F4501;
}

.practice .tournament-main-wrap .common-btn.yellow-btn:hover {
	color: #000;
}

.tournament-main-wrap .common-btn {
	min-width: 177px
}

.tournament-main-wrap .actions {
	/* max-height: 52px; */
}

.my-ticket-modal .modal-header h3 {
	margin-left: 220px;
}

/* 19jul qa changes */
.point-table .tab-pane.active .table-responsive {
	height: calc(100% - 30px);
}

.point-table .tab-pane.active .table-responsive .empty-list {
	height: 100%;
}

/* 21Jul22 QA new reuirement */
.user-dtl-box input.editable {
	padding: 5px 10px;
	border-bottom: 1px solid #ababab;
}

.react-date-picker--enabled {
	padding: 2px 10px;
	border-bottom: 1px solid #ababab;
}

.react-date-picker__inputGroup__divider {
	color: #fff;
}

/* 28 Jul */
/* .qualifier-modal-wrap .modal-header h3 { margin-left: 0px; } */
.qualifier-modal-wrap h5.modal-title {
	text-align: center;
	width: calc(100% - 40px);
}

.qualifier-modal-wrap .modal-header h3 {
	margin-left: 0px;
}

.qualifier-modal-wrap tr {
	background-color: #220071;
}

.qualifier-modal-wrap .table-responsive {
	max-height: 400px;
	overflow-y: auto;
}

.qualifier-modal-wrap .modal-content>p {
	background-color: #0C207A;
	color: #fff;
	padding-top: 10px;
}

.qualifier-modal-wrap .modal-body {
	background-color: #220071;
}

.qualifier-modal-wrap .data-not-found {
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #220071;
	color: #fff;
}

.qualifier-modal-wrap img {
	margin-right: 5px
}

.wait-list {
	margin-left: 5px;
	font-size: 20px;
	line-height: 23px;
	font-weight: 500;
}

#mountNode {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 30px;
	/* background-color: rgba(88, 18, 50,0.8); */
	width: 100%;
	height: 100%;
	max-width: 90%;
	z-index: 99;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background: rgba(0, 0, 0, 0.5);
}

#mountNode .qr-code-sec {
	background: url('../images/dashboard-bg.png') no-repeat center center / 100% 100%;
	width: 550px;
	height: 450px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
}

#mountNode .qr-code-sec img {
	width: 70px;
	margin: 20px 0 10px;
}

#mountNode .qr-code-sec p {
	max-width: 80%;
	font-size: 12px;
	line-height: 14px;
}

#mountNode h2 {
	font-size: 18px;
	line-height: 22px;
	max-width: 80%;
	margin-bottom: 25px;
}

.assiatnce-footer {
	margin-top: 5px;
	color: #fff;
}

.assiatnce-footer a {
	color: #5ce7ff;
}

.navbar-nav.footer-download-btn li.nav-item {
	padding: 0 !important;
	margin: auto;
}


/****** Media-Query CSS Start *******/
@media (min-width: 1400px) and (max-width: 1600px) {
	.after-login .navbar-nav>.nav-item {
		padding: 0 40px;
	}

	.after-login .navbar-nav>.nav-item:nth-last-child(-n+3) {
		padding: 0 12px;
	}

	.joinTableModal .row>div {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.joinTableModal .row>div:not(:last-child) {
		margin-bottom: 30px;
	}

	.modal-id {
		min-width: 17%;
		font-size: 18px;
		line-height: 22px;
	}

	#exampleModalLongTitle {
		margin-left: 90px;
	}

}

@media (min-width: 1200px) and (max-width: 1335px) {
	.game-detail-wrap .custom-shape-box .outer-box {
		max-width: 60px;
		padding: 0px;
	}


}

@media (min-width: 1200px) and (max-width: 1399px) {
	.navbar-nav>.nav-item {
		padding: 0 30px;
	}

	.joinTableModal .row>div {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.joinTableModal .row>div:not(:last-child) {
		margin-bottom: 30px;
	}

	.common-btn {
		padding: 27px 59px;
	}

	.modal-id {
		min-width: 17%;
		font-size: 18px;
		line-height: 22px;
	}

	.modal-dialog.registerModal {
		max-width: 70%;
	}

	.join-table-content {
		padding: 40px 20px 0px;
	}

	.modal .modal-dialog.logout-modal {
		max-width: 40%;
	}

	/* my account tab media css */
	.user-dtl input,
	.user-dtl input:disabled,
	.user-dtl .basic-txt {
		font-size: 16px;
		line-height: 20px;
	}

	.invite-box-shadow .google-btn>button svg {
		width: 40px !important;
	}

	.no-tournment p {
		font-size: 22px;
		line-height: 25px;
	}

	#exampleModalLongTitle {
		margin-left: 100px;
	}

	.joinTableModal .modal-body {
		padding: 180px 14px;
	}

	.myJoinTableModalWrap .modal-body {
		padding: 180px 14px;
	}

	.personal-detail .point-table {
		/* padding: 70px 90px 90px; */
	}

	.personal-detail .table-heading {
		font-size: 22px;
		padding: 15px 0;
		top: -32px;
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		padding: 15px 0;
	}

}



@media (min-width: 768px) and (max-width: 1199px) {

	.after-login .navbar,
	.navbar {
		padding: 10px 10px 25px !important;
	}

	.navbar-nav>.nav-item:nth-last-child(-n+3) {
		padding: 0px 15px !important;
	}

	.after-login .navbar-fixed.navbar {
		padding: 5px 10px 20px
	}

	.after-login .navbar-nav>.nav-item,
	.navbar-nav>.nav-item {
		padding: 0 15px !important;
	}

	.logged-in .navbar-nav>.nav-item a {
		font-size: 12px;
		line-height: 18px;
	}

	.tab-main-header .nav-tabs .nav-item {
		padding: 0 20px;
	}

	.tab-main-header .nav-tabs .nav-item .nav-link {
		padding: 20px;
		font-size: 18px;
		line-height: 18px;
	}

	.modal-btns .btn {
		padding: 10px 20px;
		font-size: 18px;
		line-height: 18px;
	}

	.tab-inner .tab-pane .nav-tabs .nav-item .nav-link {
		font-size: 16px;
		line-height: 18px;
		min-width: 160px
	}

	.list-unstyled.actions li a img {
		max-width: 50px;
	}

	.table thead th,
	.table tbody td {
		font-size: 18px;
		line-height: 22px;
	}

	.table tbody td button {
		font-size: 20px;
		line-height: 22px;
	}

	.after-login main

	/* ,	.table-section  */
		{
		height: auto;
	}

	.player-filter .card.card-body .dropdown .btn {
		font-size: 17px;
		line-height: 22px;
	}

	.player-filter .card.card-body .dropdown-menu.show .custom-control-label {
		font-size: 18px;
		line-height: 24px;
	}

	.deposit-cash-box .btn-box1 button {
		min-width: 140px;
		font-size: 25px;
		line-height: 30px;
	}

	.deposit-cash-box button {
		font-size: 26px;
		line-height: 26px;
		min-width: 100px;
	}
	.deposit-cash-box .bonus-box {
		min-width: 100px;
	}
	.deposit-cash-box {
		padding-top: 90px;
	}
	.tournament-tab-wrap .tab-inner-header ul li a.nav-link {
		min-width: 180px;
	}

	.tournament-tab-wrap button {
		min-width: 146px;
	}

	/* .joinTableModal .col-sm-6 			{ flex: 0 0 100%; max-width: 100%; } */
	.joinTableModal .row>div:not(:last-child) {
		margin-bottom: 30px;
	}

	.modal .modal-header h3 {
		font-size: 25px;
		line-height: 30px;
	}

	.joinTableModal .modal-body {
		padding: 70px 0px;
	}

	/* .joinTableModal .old-game-detail-wrap > div .old-detail-box:last-child .blue-bg { margin: 20px; } */
	.common-btn {
		padding: 13px 20px;
		/* min-width: 150px; */
	}

	.tournament-inner-box .row>div:not(:last-child) {
		margin-bottom: 20px;
	}

	.game-detail-blue-bg {
		max-width: 100%;
	}

	.registerModal h3 {
		font-size: 21px;
		line-height: 25px;
	}

	.register-heading-box .row>div {
		width: 50%;
	}

	/* create table popup css  */
	.player-radio-btns-group button span,
	.modal-dialog .modal-content .modal-body .player-radio-btns-group button {
		padding: 15px 20px;

	}

	.modal-id {
		min-width: 19%;
		font-size: 16px;
		line-height: 18px;
	}

	.modal-dialog.registerModal {
		max-width: 75%;
	}

	.join-table-content {
		padding: 30px 0px 0px;
	}

	.modal .modal-dialog.logout-modal {
		max-width: 40%;
	}

	.register-detail .form-control,
	.signup-screen .form-control {
		padding: 5px;
		margin-bottom: 20px;
		min-height: 40px;
	}

	.game-detail-left-box h3 {
		padding-left: 20px;
	}

	/* my account tab media css */
	.user-dtl input,
	.user-dtl input:disabled,
	.user-dtl .basic-txt {
		font-size: 16px;
		line-height: 20px;
	}

	.invite-wrap .kyc-body {
		max-width: 80%;
	}

	.invite-box-shadow .google-btn>button svg {
		width: 40px !important;
	}

	.problem-btn-box {
		max-width: 80%;
	}

	.no-tournment p {
		font-size: 20px;
		line-height: 25px;
	}

	#exampleModalLongTitle {
		margin-left: 61px;
	}

	.user-dtl .common-btn {
		font-size: 12px;
		line-height: 12px;
		padding: 21px 33px;
	}

	/* 14jul 22 */
	.pagination-wrap ul.pagination {
		padding-right: 0px !important;
	}

	#pageDropDown,
	.dropdown-blue-box .inner-dropdown__control,
	.transaction-btn-wrap .cust-dropdown-box select {
		padding: 5px 20px 5px 10px;
	}

	.modal .modal-header #exampleModalLongTitle1 {
		margin-left: 50px !important;
	}

	.tournament-main-wrap .common-btn {
		min-width: 145px
	}
}

/* hiding download buttons in big screens */
@media (min-width: 1024px) {
	.app-download-box {
		display: none;
	}
}

/* hiding download buttons in big screens ends here */

/* hiding lobby etc in small screen */
@media (max-width: 1023px) {

	/* .user-credential-box ,#root 		{ display: none; } */
	/* body 					{ overflow: hidden; } */
	/* .user-credential-box {
		display: none;
	} */

	/* .rotate-box,
	.rotate-box>div {
		/* display: flex !important; */

}

/* hiding lobby etc in small screen ends here */

@media (min-width: 1200px) and (max-width: 1399px) and (min-height: 600px) and (max-height: 800px) {
	.after-login .navbar {
		padding: 15px 10px 25px;
	}

	.after-login .navbar-fixed.navbar {
		padding: 5px 10px 20px
	}

	.after-login .navbar-nav>.nav-item {
		padding: 0 15px;
	}

	.tab-main-header .nav-tabs .nav-item {
		padding: 0 20px;
	}

	.tab-main-header .nav-tabs .nav-item .nav-link {
		padding: 20px;
		font-size: 18px;
		line-height: 24px;
	}

	.modal-btns .btn {
		padding: 8px 20px;
		font-size: 18px;
		line-height: 24px;
	}

	.tab-inner .tab-pane .nav-tabs .nav-item .nav-link {
		font-size: 16px;
		line-height: 24px;
	}

	.list-unstyled.actions li a img {
		max-width: 50px;
	}

	.table thead th,
	.table tbody td {
		font-size: 18px;
		line-height: 24px;
	}

	.table tbody td button {
		font-size: 20px;
		line-height: 24px;
	}

	.after-login main

	/* ,	.table-section  */
		{
		height: auto;
	}

	.player-filter .card.card-body .dropdown .btn {
		font-size: 17px;
		line-height: 22px;
	}

	.player-filter .card.card-body .dropdown-menu.show .custom-control-label {
		font-size: 18px;
		line-height: 24px;
	}


	.dashboard-main-sec {
		padding: 25px 80px;
		padding-top: 200px;
	}

	.personal-detail .point-table {
		padding: 45px 75px 60px;
	}

	.personal-detail .point-table .tab-content {
		max-height: calc(100vh - 415px);
	}

	.personal-detail .table-heading {
		font-size: 20px;
		padding: 10px 0;
		top: -27px;
		max-width: 250px;
	}

}

/* -------- Desktop Device Start ------- */
@media (min-width: 992px) and (max-width: 1199px) {

	/* -------- Comman Spaciing ------- */
	/* Header */
	.navbar-nav>.nav-item {
		padding: 0 30px;
	}

	.navbar-brand img {
		max-width: 200px;
	}

	.app-links img {
		max-height: 30px;
	}

	.app-download-box .app-links img {
		max-height: unset;
	}

	header .app-links li:not(:last-child) {
		margin-right: 10px;
	}

	.site-content {
		/* padding-top: 60px; */
	}

	.banner-area {
		height: calc(100vh - 120px);
	}

	.banner-slider .slick-slide img {
		max-width: 500px;
	}

	.card-block {
		width: calc(100% / 2 - 60px);
		margin: 45px 15px 0px;
		max-width: 375px;
	}

	.game-logo {
		transform: translateX(-45px) scale(1);
	}

	.game-logo img {
		transform: scale(1.55);
	}

	.block-desc {
		margin: 10px 5px;
	}

	.block-content h5 {
		font-size: 13px;
		line-height: 20px;
	}

	.highlight-list li {
		font-size: 11px;
		line-height: 20px;
	}

	.highlight-list li:not(:last-child) {
		margin-bottom: 5px;
	}

	/* Highlight */
	.high-light li {
		padding: 0 40px;
	}

	/* Blog */
	.blog-hero {
		height: 200px;
	}

	.blog-desc {
		padding: 10px;
	}

	.banner-area .row .col-md-5 {
		width: 50% !important;
	}

	.pf-rating {
		bottom: 30px !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box {
		max-width: 100% !important;
	}

}

/* -------- Desktop Device End ------- */
/* -------- Tablet Device Start ------- */
@media (min-width: 768px) and (max-width: 991px) {

	/* -------- Comman Spaciing ------- */
	h3 {
		font-size: 22px;
		line-height: 30px;
	}

	h4 {
		font-size: 16px;
		line-height: 28px;
	}

	p {
		font-size: 14px;
		line-height: 24px;
	}

	.mar-btm-50 {
		margin-bottom: 35px;
	}

	.mar-top-50 {
		margin-top: 35px;
	}

	.cmn-pad-top-50 {
		padding-top: 30px;
	}

	.cmn-pad {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	/* Header */
	/* .navbar { padding: 10px; } */
	.navbar-brand img {
		max-width: 200px;
	}

	.navbar-nav>.nav-item {
		padding: 0 20px;
	}

	/* .navbar-expand-md .navbar-nav { margin-right: unset !important; } */
	/* header .app-links { display: none !important; } */
	.navbar-nav .nav-link::before {
		bottom: -5px;
	}

	.navbar-fixed+.site-content {
		padding-top: 51px;
	}

	/* .navbar-fixed.navbar-expand-md .navbar-nav .nav-link { margin: 0 10px; } */
	/* Banner */
	.site-content {
		/* padding-top: 45px; */
	}

	.banner-area {
		height: calc(100vh - 115px);
	}

	.form-control {
		font-size: 14px;
		line-height: 22px;
	}

	.login-screen li a {
		padding: 12px;
	}

	.g-btn,
	.fb-btn {
		padding: 5px;
		font-size: 12px;
		line-height: 22px;
	}

	.fb-btn::before {
		font-size: 15px;
		line-height: 25px;
	}

	.g-btn::before {
		width: 15px;
		height: 16px;
	}

	.or-text {
		margin: 13px 0px;
	}

	.object-select .form-group {
		text-align: center;
	}

	.agent-id {
		max-width: 100px;
	}

	.object-select label {
		font-size: 12px;
		line-height: 15px;
	}

	.or-text span {
		background: #081c69;
	}

	/* Highlight */
	.high-light li {
		padding: 0 15px;
	}

	.high-light p {
		font-size: 13px;
		line-height: 20px;
	}

	/* Block Section */
	.card-block {
		margin: 30px auto 0px;
		width: 100%;
	}

	.sign-up-btn {
		margin: 15px 0px 0px;
	}

	/* Game Overview */
	.game-overview h3 {
		margin-bottom: 10px;
	}

	.block-content {
		padding: 0px 15px;
	}

	.game-overview p:not(:last-child) {
		margin-bottom: 10px;
	}

	.game-overview+.mar-top-50 {
		margin-top: 15px;
	}

	/* Player Slider */
	.player-bio {
		padding: 10px 15px;
	}

	.user-name {
		margin: 5px 0;
	}

	.player-testimonial .slick-prev {
		left: 5px;
		width: 30px;
		height: 30px;
	}

	.player-testimonial .slick-next {
		right: 5px;
		width: 30px;
		height: 30px;
	}

	.player-testimonial .slick-next::before,
	.player-testimonial .slick-prev::before {
		font-size: 30px;
		line-height: 30px;
	}

	/* Footer */
	.app-links img {
		max-height: 25px;
	}

	/* Blog */
	.blog-hero {
		height: 200px;
	}

	.blog-desc {
		padding: 10px;
	}

	.short-desc {
		-webkit-line-clamp: 1;
		height: 20px;
	}

	/* Blog Inner */
	.blog-inner-content .blog-hero {
		height: 350px;
	}

	.blog-inner-content .blog-hero.recent-post {
		height: 200px;
	}

	.recent-blog .social-icon,
	.blog-inner-desc ul {
		margin-bottom: 15px;
	}

	.recent-blog {
		padding: 15px;
	}

	.blog-inner-content .mar-top-25 {
		margin-top: 15px;
	}

	/* About Us */
	.about-us-content {
		padding: 20px;
	}

	.withdraw-page {
		margin-top: 100px;
	}
	
}

/* -------- Tablet Device End ------- */
/* -------- Mobile Device Start ------- */
@media (max-width: 767px) {

	/* -------- Comman Spaciing ------- */
	h1 {
		font-size: 30px;
		line-height: 40px;
	}

	h2 {
		font-size: 25px;
		line-height: 30px;
	}

	h3 {
		font-size: 22px;
		line-height: 30px;
	}

	p {
		font-size: 14px;
		line-height: 22px;
	}

	.mar-btm-50 {
		margin-bottom: 50px;
	}

	.mar-top-50 {
		margin-top: 25px;
	}

	.mar-top-25 {
		margin-top: 15px;
	}

	.page-title {
		margin-bottom: 15px;
	}

	.page-title h3 {
		padding-bottom: 10px;
	}

	.cmn-pad-top-50 {
		padding-top: 25px;
	}

	.cmn-pad {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.cmn-pad+.cmn-pad {
		padding-top: 0px;
	}

	span.br {
		display: none;
	}

	.theme-btn {
		padding: 10px 20px;
	}

	/* Header */
	.navbar-brand img {
		max-width: 175px;
	}

	.navbar {
		padding: 15px;
	}

	.navbar-fixed.navbar {
		padding: 5px 15px 20px;
	}

	.navbar-nav>.nav-item {
		padding: 0;
	}

	.navbar-toggler {
		padding: 0px;
	}

	.navbar-toggler-icon {
		height: auto;
		width: auto;
	}

	.navbar-toggler span {
		width: 25px;
		height: 2px;
		display: block;
		background: #ffffff;
		transition: all 0.3s ease-in-out;
		position: relative;
	}

	.navbar-toggler span:not(:last-child) {
		margin-bottom: 7px;
	}

	.navbar-toggler.active span:first-child {
		transform: rotate(45deg);
		top: 12px;
	}

	.navbar-toggler.active span:nth-child(2n) {
		opacity: 0;
	}

	.navbar-toggler.active span:last-child {
		transform: rotate(-45deg);
		top: -6px;
	}

	.app-links img {
		max-height: 30px;
	}

	.navbar-collapse {
		background: #ffffff;
		margin-top: 10px;
	}

	header .navbar-collapse .nav-link {
		color: var(--secondary-color);
		transition: 0.5s;
	}

	header .navbar-collapse .nav-link.active,
	.navbar-expand-md .navbar-nav .nav-link:hover {
		color: var(--third-color);
		background: var(--primary-color);
	}

	.navbar-expand-md .navbar-nav .nav-link:before {
		display: none;
	}

	.navbar-expand-md .navbar-nav .nav-link {
		padding: 5px 0px;
		text-align: center;
		transition: 0.5s;
		margin: 0px;
	}

	header .navbar-collapse .nav-link:focus,
	header .navbar-collapse .nav-link:hover {
		color: var(--primary-color);
	}

	.navbar-collapse .app-links {
		justify-content: center;
		margin: 10px 0px;
	}

	.navbar-expand-lg .navbar-nav .nav-link {
		margin: 0px;
		padding: 5px 15px;
	}

	.navbar-fixed+.site-content {
		padding-top: 0px;
	}

	/* Banner */
	.site-content {
		/* padding-top: 50px; */
	}

	.banner-area .slick-slider {
		margin: 30px 0px 10px;
	}

	.banner-area {
		height: 100%;
	}

	.high-light li {
		padding: 0 15px;
	}

	.high-light .d-flex.justify-content-center {
		flex-direction: column;
		align-items: center;
		padding-left: 0px;
	}

	.high-light li:not(:last-child) {
		border-bottom: 2px solid #220071;
		border-right: 0px;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}

	.high-light figure {
		width: 50px;
	}

	/*  */
	.card-block {
		width: 100%;
		flex-wrap: wrap;
	}

	.block-desc {
		width: 100%;
		margin: 0px 15px 10px;
	}

	.game-logo {
		margin: 0 auto;
		transform: translate(0);
	}

	.game-logo figure {
		margin: 0 auto;
		text-align: center;
	}

	.block-content .row:first-child::before {
		display: none;
	}

	.game-logo img {
		margin: 0px 0px 10px;
		max-width: 100px;
	}

	.sign-up-btn {
		margin: 20px 0px 0px;
	}

	/* Sidebar Sec */
	.game-overview h3 {
		margin-bottom: 15px;
	}

	.game-overview p:not(:last-child) {
		margin-bottom: 10px;
	}

	.sidebar-sec {
		padding: 20px 0px;
	}

	.tabbable.d-flex {
		flex-wrap: wrap;
	}

	.sidebar-sec .tab-content {
		width: 100%;
		margin-right: 0px;
	}

	.tabbable .nav-tabs {
		max-width: 100%;
		margin-right: 0px;
	}

	.tabbable .nav-tabs li a {
		padding: 10px;
	}

	/* Player Section */
	.user-img {
		width: 75px;
		height: 75px;
	}

	.user-name {
		margin: 10px 0px;
	}

	.slick-current.slick-active .player-bio::after {
		width: 0px;
	}

	.player-role {
		flex-wrap: wrap;
	}

	.player-role li {
		width: 100%;
	}

	.player-role li:first-child::before {
		background: url(../images/divider.svg) no-repeat center center / 100% auto;
		width: 100%;
		height: 10px;
		top: auto;
		bottom: -5px;
	}

	.player-testimonial .slick-prev,
	.player-testimonial .slick-next {
		width: 30px;
		height: 30px;
	}

	.player-testimonial .slick-next::before,
	.player-testimonial .slick-prev::before {
		font-size: 30px;
		line-height: 30px;
	}

	.slick-prev {
		left: -10px;
	}

	.slick-next {
		right: -10px;
	}

	/* Footer */
	.footer-logo img {
		margin-bottom: 15px;
	}

	.widget-title {
		margin-top: 15px;
	}

	.follow-area {
		display: flex;
	}

	.follow-area .follow-text {
		margin-right: 15px;
	}

	/* Blog */
	.blog-hero {
		height: 255px;
	}

	.blog-content {
		margin-bottom: 15px;
	}

	/* Blog Inner */
	.blog-inner-content .blog-hero {
		height: 250px;
	}

	.blog-inner-desc ul {
		margin-bottom: 15px;
	}

	.cmn-content ol,
	.cmn-content ul {
		padding-left: 10px;
	}

	.recent-blog {
		padding: 15px;
	}

	.blog-inner-content .blog-hero.recent-post {
		height: 150px;
	}

	.recent-blog .recent-post,
	.recent-blog .social-icon {
		margin-bottom: 15px;
	}

	/* About Us */
	.contact-area,
	.about-us-content {
		padding: 15px;
	}

	/* Contact Page */
	.form-inline label {
		margin-bottom: 10px;
	}

	.form-inline .form-control {
		width: 100%;
	}

	.form-inline .theme-btn {
		margin-left: 0px;
	}

	.contact-detail li a {
		padding-bottom: 5px;
	}

	.contact-detail {
		margin: 15px 0px 0px;
	}

	.contact-detail li:not(:last-child) {
		margin-bottom: 10px;
	}

	.desclaimer ul {
		padding-left: 15px;
	}

	/* Download */
	.download-btn a {
		padding: 7px 25px;
	}

	.app-links li:not(:last-child) {
		margin-right: 0px !important;
	}

	.navbar-nav>.nav-item:last-child {
		padding-right: 25px !important;
	}

	.navbar-nav.app-links>.nav-item:last-child {
		padding-right: 0px !important;
	}

	.sign-detail button {
		font-size: 15px;
		line-height: 18px;
	}

	.form-control,
	.register-detail .form-control {
		font-size: 15px;
		line-height: 18px;
	}

	.tab-content ul li {
		margin: 0px 10px;
		padding: 0px !important;
	}

	.form-inline .form-group {
		flex-direction: column;
	}

	.sign-detail {
		margin-bottom: 30px;
	}

	.footer-widget2 .app-links li:not(:last-child) {
		margin-right: 10px !important;
	}

	.contact-detail ul {
		padding-left: 0px;
	}

	.modal .joinTableModal,
	.modal .register-modal-wrap,
	.modal .modal-verify-otp-wrap,
	.modal .modal-dialog.logout-modal {
		max-width: 95%;
	}

	.modal .modal-header h3 {
		margin-left: 50px;
		font-size: 20px;
		line-height: 22px;
	}

	.ForgetPasswordWrap h4 {
		font-size: 16px;
		line-height: 22px;
	}

	.new-create-box {
		margin-left: 0px;
	}

	.joinTableModal.ForgetPasswordWrap .modal-body .form-group .form-control {
		width: 100%;
	}

	.modal-body .form-group .form-control {
		font-size: 16px;
		line-height: 22px;
	}

	.ForgetPasswordWrap h4 {
		margin-bottom: 40px;
	}

	.joinTableModal.ForgetPasswordWrap .modal-body .new-create-box {
		margin-top: 25px;
	}

	/* .modal-verify-otp-wrap { } */
	/* .register-modal-wrap button.close, .modal-verify-otp-wrap button.close {  } */
	.register-modal-wrap button.close img,
	.modal-verify-otp-wrap button.close img {
		width: 25px;
	}

	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		max-width: 100%;
		margin: 0 auto;
	}

	.modal-verify-otp-wrap .modal-body .form-group .resend-box {
		align-items: center;
	}

	.modal-dialog.logout-modal p {
		margin-bottom: 30px;
		font-size: 18px;
		line-height: 22px;
	}

	.modal-body .theme-orange-btn span {
		padding: 10px 35px;
		font-size: 18px;
		line-height: 18px;
	}

	.modal-dialog.logout-modal button:first-child {
		margin-right: 15px;
	}

	.myAlert-top {
		width: 80%;
	}

	.app-download-box .app-links img {
		width: 150px;
	}

	.nav-items {
		flex-direction: row !important;
	}
	.nav-items a {
		min-height: 50px;
		display: flex !important;
		align-items: center;
		justify-content: center;
		font-size: 14px !important;
		background: url('../images/dash-list-bg-mobile.png')no-repeat center center/100% 100% !important;
	}
	.nav-items li {
		min-width: 100px;
		min-height: 60px;
		margin: 5px !important;
	}
	.main-nav {
		width: 100%;
		position: fixed;
		top: 0 !important;
		left: 0;
		padding-left: 0 !important;
	}
	
}





/* -------- Mobile Device End ------- */

@media (min-width: 1025px) and (max-width: 1199px) {
	.kyc-box .kyc-body {
		padding-right: 15px;
	}

	.custom-file.grey-box {
		border-radius: 10px;
		margin-left: auto;
		padding: 30px 30px;
		max-width: 140px;
	}

	.tab-content .kyc-body p,
	.cust_dropdown_box select {
		font-size: 15px;
		line-height: 20px;
	}

	.cust-down-aroow {
		width: 18px;
	}

	.dashboard-main-sec .table thead th,
	.dashboard-main-sec .table tbody td {
		font-size: 15px;
		line-height: 18px;
	}

	.dashboard-main-sec .table tbody td {
		padding: 15px 20px
	}

	.transaction-btn-wrap .pagination-wrap {
		width: 40%;
	}

	.pagination .page-item .prev-btn,
	.pagination .next-btn {
		min-width: auto;
	}


	.mb-40 {
		margin-bottom: 20px !important;
	}

	.problem-btn-box {
		max-width: 50%;
	}

	.report-inner-box label {
		font-size: 18px;
		line-height: 22px;
	}

	.change-psw-section .report-inner-box form .form-group select,
	.change-psw-section .report-inner-box .problem-attach input {
		padding: 15px 14px;
	}

	.change-psw-section form select,
	.change-psw-section.reporting-box .cust-dropdown-box .form1 .select-custom1 {
		background: #000 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAANCAIAAAA8DjmHAAABS2lDQ1BIUCAyMmVzAAAYlWNgYLyRk5xbzMLAwJCbV1IU5O6kEBEZpcD+jIGZgZOBi0GVgSMxubjAMSDAB6iEAUajgm/XGBhB9GVdkFnpy5xPeF5azs+tVxF29f5kFkz1KIArJbU4GUj/AWKt5IKiEgYGRg0gW7m8pADEBtknUgR0FJCdAGKnQ9glIHYShN0BVhMS5AxkzwGyBZIzElOA7DVAtk4Skng6Ejs3pzQZ6gaQ63mgbD4glmHwYAhgUGAwAsJUhmIGhpLUCpB9DM75BZVFmekZJQqOBQU5qQqeecl6OgpGBkZGDAygcIMY8fk82ERGiSSEWKo0A4PxCqAgJ0IsS46BYfc6BgbB6Qgx9blAp61iYDjQU5BYlAgPJcZvLMVpxkYQNo8UAwPrgf//P/1nYGAHhsnfc////57x///faQwMzF8YGPb7AQA1bVxlcz7d9AAAAAlwSFlzAAALEwAACxMBAJqcGAAAAO9JREFUKJGl0jGOhSAQANDJZxMK7WhptccL4AXwBuIR9Cp6AFqtiJ6ITgsSSeYEW/iza3b9xN1PBTPDA8JA27bw9ui6DhBRa/2O0jQNIgIihhCUUv9TqqoKITwhRPTeSyn/qpRl6b0/hCeEiOu6CiHuK0VRbNv2tf0bQkTnXJZld5Q8z51z572Pc5oxtiwL5zyucM7neWaMnYOPO0V3DvsJHde21iZJ8juVpqm19vL5FxAACCGmaaKUnoOU0nEcX33INQQAUkpjDCHkWBJCjDGRFnkJAYBSqu/7Yz4MQ7xpPyI5ANBa7/sOAHVdxys/AXm7gsGV6uqGAAAAAElFTkSuQmCC) right 10px top 50% / 20px 12px #f2f2f2 no-repeat !important;
	}

	.css-1n7v3ny-option {
		color: #000 !important;
		font-weight: bold;
	}

	.css-yt9ioa-option {
		color: #000 !important;
	}

	.report-inner-box .custom-file {
		margin: 25px 0px 8px;
	}

	.report-inner-box .mb-30 {
		margin-bottom: 15px;
	}

	.modal-dialog.modal-dialog-centered.complain-wrap {
		max-width: 80%;
	}

	.complain-form label {
		font-size: 19px;
		line-height: 22px;
	}

	.modal-dialog .modal-content .modal-body button {
		font-size: 22px;
		line-height: 22px;
	}

	.modal .modal-dialog.logout-modal {
		max-width: 40%;
	}

	.agent-id {
		max-width: 130px;
	}

	.sign-detail .object-select input[type=radio] {
		width: 12px;
		height: 12px;
	}

	button.close span img {
		height: 35px;
		width: 35px;
	}

	.modal-body .theme-orange-btn span {
		padding: 14px 30px;
		font-size: 18px;
		line-height: 18px;
	}

	.file-details {
		text-align: left;
	}

	.wait-list {
		margin-left: 3px;
		font-size: 16px;
		line-height: 20px;
	}

}

@media (min-width: 1200px) and (max-width: 1399px) {

	.after-login .navbar-nav>.nav-item,
	.navbar-nav>.nav-item {
		padding: 0 15px !important;
	}

	.logged-in .navbar-nav>.nav-item a {
		font-size: 13px;
		line-height: 18px;
	}

	.pagination .page-item .prev-btn,
	.pagination .next-btn {
		min-width: 80px;
	}

	.mb-40 {
		margin-bottom: 30px !important;
	}

	.problem-btn-box {
		max-width: 70%;
	}

	.tab-content .kyc-body p,
	.cust_dropdown_box select {
		font-size: 16px;
		line-height: 20px;
	}

	.custom-file.grey-box {
		padding: 35px 22px;
	}

	.kyc-box .kyc-body {
		padding-right: 35px;
	}

	.kyc-document-box .custom-file.grey-box {
		width: 30%;
	}

	.cust_dropdown_box select {
		padding: 16px 50px 20px 20px;
	}
}



@media (min-width: 1400px) and (max-width: 1599px) {

	.after-login .navbar-nav>.nav-item,
	.navbar-nav>.nav-item {
		padding: 0 25px !important;
	}

	.problem-btn-box {
		max-width: 60%;
	}
}

.css-1n7v3ny-option {
	color: #000 !important;
	font-weight: bold;
}

.css-yt9ioa-option {
	color: #000 !important;
}

.Report_Problem_Comments::placeholder {
	color: hsl(0, 0%, 50%);
}

.inputFileReportProblem {
	background-color: red !important;
	width: 140px !important;
	/* margin-left: 110px !important; */
}

.file-wrapper {
	background: transparent;
	width: auto;
	margin-right: 15px;
	color: #fff;
}

.ragisters-detail {
	margin: -10px -35px;
}

@media (min-width: 1200px) and (max-width: 1400px) {

	.register-detail .form-control,
	.signup-screen .form-control {
		padding: 10px;
	}

	.form-group {
		margin-bottom: 0;
	}

	.tab-content p:not(:last-child) {
		margin-bottom: 0;
	}

	.login-screen li a {
		padding: 15px;
	}

	.sign-detail {
		/* padding: 30px 56px; */
		/* min-height: 340px; */
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		margin-top: 10px;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn {
		margin-top: 10px;
	}

	.game-transaction-section .game-transaction-table {
		/* padding: 70px 80px 80px; */
	}

	.dashboard-main-sec .game-transaction-heading {
		font-size: 22px;
		padding: 15px 0;
		top: -20px;
	}

	.passbook-wrap .point-table>.table-cnt {
		/* padding: 70px 80px 80px; */
		margin-top: 50px;
	}

	.dashboard-main-sec .account-title {
		font-size: 22px;
		padding: 15px 0;
		top: -20px;
	}

	.withdraw-page .account-title {
		font-size: 22px;
		padding: 15px 0;
		top: -20px;
	}

	.invite-wrap .kyc-body {
		max-width: 100%;
		padding: 50px 40px 20px;
	}

	.invite-box h3 {
		font-size: 22px;
	}

	.invite-box .invite-img-box img {
		max-width: 200px;
	}

	.about-page-section .page-title {
		font-size: 22px;
		min-width: 300px;
	}

	.faq-content {
		padding: 60px 70px 80px;
	}

	.how-to-play-page .how-to-play-title {
		font-size: 22px;
		padding: 18px 0;
		top: -35px;
		max-width: 300px;
	}

	.how-to-play-content {
		padding: 60px 80px 80px;
	}

	.footer-link ul {
		/* flex-direction: column; */
		align-items: center;
		flex-wrap: wrap;
		gap: 15px;
	}

	.footer-link {
		bottom: 110px;
		width: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.lobby-headeing.table-heading {
		top: 40px;
	}

}

@media (min-width: 1025px) and (max-width: 1200px) {

	.register-detail .form-control,
	.signup-screen .form-control {
		padding: 4px;
		margin-bottom: 10px !important
	}

	.contact-page-sec {
		/* background: #2f1440;
		padding-top: 20px !important;
		padding-bottom: 20px !important;
		margin-bottom: 50px !important;
		border-radius: 15px; */
	}



	.form-group {
		margin-bottom: 0;
	}

	.tab-content p:not(:last-child) {
		margin-bottom: 0;
	}

	.login-screen li a {
		padding: 10px;
	}

	.g-btn {
		padding: 4px;
	}

	.fb-btn {
		padding: 4px;
	}

	.sign-detail .btn-success {
		padding: 6px;
	}

	.or-text {
		margin: 7px 0;
	}

	.forgot-text a {
		margin-bottom: 7px;
	}

	.ragisters-detail {
		margin: -10px;
	}

	.green-btn {
		min-height: 40px !important;
	}

	.sign-detail {
		/* min-height: 340px; */
		padding: 37px 56px !important;
	}

	/* .login-screen {
		min-height: 300px;
	} */

	.login-btn {
		min-width: 254px;
		min-height: 57px;
	}

	.tab-main-header .nav-tabs .nav-item {
		padding: 8px;
	}

	.privateTable-button {
		/* padding: 15px 14px !important */
	}

	.footer-link ul,
	.footer-link-2 ul {
		flex-direction: column;
		align-items: center;
		gap: 15px;
	}



}

@media (min-width :1200px) {
	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		/* min-width: 500px; */
	}
}

@media(min-width:992px) and (max-width :1199px) {
	.sign-detail {
		justify-content: flex-start;
		padding: 0 !important;
		margin-top: 30px;
	}

	.login-screen {
		min-height: 450px;
	}

	.signup-screen .nav-tabs {
		margin-top: -15px;
	}

	.sign-detail button {
		min-height: 45px;
	}

	.trusted-items-list .card-block {
		width: calc(100% / 2 - 20px);
	}

	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		min-width: 550px;
	}

	.modal .register-modal-wrap,
	.modal .modal-verify-otp-wrap {
		max-width: 70%;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		margin-top: 10px;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn {
		margin-top: 10px;
	}

	.points-tabs .nav-tabs,
	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		max-width: 80%;
	}

	.privateTable-button {
		margin-top: 0;
		right: 40px;
	}

	.points-rummy-inner-sec {
		max-width: 100%;
	}

	.point-table.table-section {
		margin-top: -65px;
	}

	.dashboard-main-sec {
		padding: 25px 40px;
		padding-top: 100px;
	}

	.personal-detail .table-heading {
		font-size: 22px;
		padding: 18px 0;
		top: -38px;
		max-width: 300px;
	}

	.personal-detail .point-table .tab-content {
		max-height: calc(100vh - 450px);
	}

	.personal-detail .point-table {
		padding: 90px 60px 40px;
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		font-size: 18px;
		padding: 15px 0;
	}

	.game-transaction-section .game-transaction-table {
		/* padding: 70px 80px 80px; */
	}

	.dashboard-main-sec .game-transaction-heading {
		font-size: 22px;
		padding: 18px 0;
		top: -28px;
		max-width: 300px;
	}

	.passbook-wrap .point-table>.table-cnt {
		/* padding: 70px 80px 80px; */
	}
	.point-table .tab-pane.active .table-responsive {
		
	}
	.invite-wrap .invite-card {
		max-width: 80%;
	}
	.game-transaction-section {
		max-height: 400px;
		overflow-y: auto;
	}
	.passbook-section-heading {
		font-size: 22px;
		padding: 18px 0;
		top: -28px;
		max-width: 300px;
	}

	.withdraw-page .account-title {
		font-size: 22px;
		padding: 18px 0;
		top: -28px;
		max-width: 300px;
	}

	.invite-wrap .account-title {
		font-size: 22px;
		padding: 18px 0;
		top: -28px;
		max-width: 300px;
	}

	.invite-wrap .kyc-body {
		max-width: 100%;
		padding: 50px 40px 20px;
	}

	.invite-box div:first-child img {
		width: 20px;
	}

	.invite-info-box img {
		width: 20px;
	}

	.invite-box h3 {
		font-size: 22px;
	}

	.invite-box .invite-img-box img {
		max-width: 200px;
	}

	.about-page-section .page-title {
		font-size: 20px;
		min-width: 250px;
	}

	.contact-area, .about-us-content {
		padding: 120px 70px 80px;
	}

	.faq-content {
		padding: 60px 70px 80px;
	}

	.how-to-play-page .how-to-play-title {
		font-size: 22px;
		padding: 18px 0;
		top: -35px;
		max-width: 300px;
	}

	.how-to-play-content {
		padding: 60px 70px 80px;
	}

	.contact-support .contact-support-title {
		top: 47px !important;
	}

	.contact-support .row {
		margin-top: 50px !important;
	}

	.pf-container {
		max-width: 90% !important;
		padding: 0px !important;
	}

	.footer-link {
		width: 100%;
	}

	.footer-link ul, .footer-link-2 ul {
		flex-direction: row;
		justify-content: center;
	}
	.nav-items li {
		margin-bottom: 10px !important;
	}
	.main-nav {
		/* left: -10px !important; */
		padding-left: 30px !important;
	}
	.deposit-cash-box {
		padding-top: 100px;
	}


}

@media(min-width:768px) and (max-width :991px) {
	.sign-detail {
		justify-content: flex-start;
		padding: 0px !important;
		margin-top: auto;
	}

	.contact-support .contact-support-title {
		top: 47px !important;
	}

	.contact-support .row {
		margin-top: 50px !important;
	}

	.high-light-banner {
		max-height: 315px;
	}

	.ragisters-detail {
		margin: -8px -8px;
	}

	.pf-container {
		max-width: 90% !important;
		padding: 0px !important;
	}

	.sign-detail {
		margin: 0px !important;
	}

	.pf-rating {
		bottom: 30px !important;
	}

	.sidebar-sec {
		width: 100%;
		padding: 15px;
	}

	.banner-area .row .col-md-5 {
		width: 55% !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box {
		max-width: 100% !important;
	}

	.slick-dots {
		bottom: 20px !important;
		right: calc(25%) !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>img {
		right: 35px !important;
	}

	.login-screen li a {
		font-size: 14px;
	}

	.game-overview p {
		font-size: 12px;
	}

	.sign-detail .forgot-text a {
		font-size: 15px;
	}

	.sign-detail form {
		max-width: 90%;
		margin-top: 15px;
	}

	.sign-detail button {
		min-height: 35px;
	}

	.login-screen {
		min-height: 380px;
	}

	.signup-screen .nav-tabs {
		margin-top: -25px;
	}

	.trusted-items-list .card-block {
		width: calc(100% / 2 - 20px);
	}

	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		min-width: 500px;
	}

	.modal .register-modal-wrap,
	.modal .modal-verify-otp-wrap {
		max-width: 70%;
	}

	.register-detail .form-control,
	.signup-screen .form-control {
		min-height: 15px !important;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 0 !important;
	}

	.sign-detail h2 {
		font-size: 20px;
	}

	.sign-detail button {
		font-size: 16px;
	}

	.navbar-brand img {
		max-width: 160px;
	}

	.navbar:before {
		display: none;
	}

	.after-login .navbar,
	.navbar {
		padding: 15px 10px !important;
	}

	.partner-listing img {
		max-width: 65px;
	}

	.footer-logo img {
		max-width: 150px;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		margin-top: 10px;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn {
		margin-top: 10px;
		font-size: 22px;
		padding: 14px 11px 14px;
		max-width: 182px;
	}

	.points-tabs .nav-tabs,
	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		max-width: 100%;
	}

	.privateTable-button {
		margin-top: 0;
		right: 20px;
		top: 105px;
		font-size: 20px;
		min-width: 280px;
	}

	.point-table.table-section {
		margin-top: -65px;
	}

	.points-rummy-inner-sec {
		max-width: 100%;
	}

	.dashboard-main-sec {
		padding: 25px 30px;
		padding-top: 70px;
		display: block;
	}

	.footer-link ul,
	.footer-link-2 ul {
		flex-direction: column;
		align-items: flex-end;
		gap: 5px;

	}

	.personal-detail .table-heading {
		font-size: 20px;
		padding: 15px 0;
		top: -30px;
		max-width: 300px;
	}

	.personal-detail .point-table {
		padding: 90px 30px 80px;
	}

	.personal-detail .point-table .tab-content {
		max-height: calc(100vh - 400px);
	}
	.personal-detail .point-table .tab-content .tab-pane {
		max-height: calc(100vh - 500px);;
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		font-size: 16px;
		padding: 10px 0;
	}
	.table-cnt-box .row {
		flex-direction: column;
	}
	.account-cnt {
		max-width: calc(100% - 230px);
		padding-bottom: 70px;
	}
	.game-transaction-section .game-transaction-table {
		/* padding: 60px 70px 70px; */
	}
	.table-cnt ul li {
		min-width: 130px;
		margin: 5px 5px;
	}
	.kyc-box .kyc-body {
		padding-right: 10px;
	}
	.passbook-wrap .point-table {
		padding-top: 90px;
	}
	.transaction-btn-wrap {
		overflow-x: auto;

	}
	.dashboard-main-sec .game-transaction-heading {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 300px;
	}

	.passbook-wrap .point-table>.table-cnt {
		/* padding: 60px 70px 70px; */
	}

	.passbook-section-heading {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 300px;
	}
	.game-transaction-section .table-cnt-box {
		max-height: 600px;
		min-height: 500px;
	}

	.passbook-wrap .point-table .table-cnt ul {
		padding: 0px;
		gap: 10px !important;
	}

	.withdraw-page .account-title {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 300px;
	}

	.invite-wrap .account-title {
		font-size: 22px;
		padding: 18px 0;
		top: -28px;
		max-width: 300px;
	}

	.invite-wrap .kyc-body {
		max-width: 100%;
		padding: 50px 40px 20px;
	}

	.invite-box div:first-child img {
		width: 20px;
	}

	.invite-info-box img {
		width: 20px;
	}

	.invite-box h3 {
		font-size: 22px;
	}

	.invite-box .invite-img-box img {
		max-width: 200px;
	}

	.about-page-section .page-title {
		font-size: 20px;
		min-width: 290px;
	}

	.contact-area, .about-us-content,
	.faq-content {
		padding: 60px 70px 80px;
	}

	.faq-page .faq-title {
		font-size: 22px;
		padding: 18px 0;
		top: -35px;
		max-width: 300px;
	}

	.how-to-play-page .how-to-play-title {
		font-size: 22px;
		padding: 18px 0;
		top: -35px;
		max-width: 300px;
	}

	.how-to-play-content {
		padding: 60px 70px 80px;
	}

	.about-us-content .text-right {
		text-align: left !important;
	}

	.contact-left-bar:after {
		display: none;
	}

	.contactForm {
		padding: 10px !important;
	}

	.contact-page-sec .row {
		flex-direction: column-reverse;
	}

	.footer-link {
		width: 100%;
		bottom: 110px;
	}

	.footer-link ul, .footer-link-2 ul {
		flex-direction: row;
		justify-content: center;
	}

	.modal .modal-dialog.logout-modal {
		max-width: 60%;
	}

	.lobby-headeing.table-heading {
		left: 40px;
		top: 50px;
		font-size: 26px;
		max-width: 300px;
	}

	.main-nav {
		padding-left: 20px !important;
		min-width: 200px !important;
	}

}

@media(max-width :767px) {
	.sign-detail {
		justify-content: flex-start;
		/* padding: 35px 15px !important; */
		padding-bottom: 60px !important;
	}

	.banner-area .row .col-md-5 {
		width: 50% !important;
	}


	.sign-detail button {
		min-height: 35px;
	}

	.trusted-items-list .card-block {
		width: calc(100%);
		margin: 0;
		margin-bottom: 20px;
		padding: 15px;
	}

	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		min-width: 360px;
	}

	.modal .register-modal-wrap,
	.modal .modal-verify-otp-wrap {
		max-width: 100%;
	}

	.register-detail .form-control,
	.signup-screen .form-control {
		min-height: 30px;
		font-size: 14px;
		line-height: 20px;
	}

	.modal-verify-otp-wrap .modal-body {
		padding: 10px;
	}

	.sign-detail h2 {
		font-size: 20px;
	}

	.sign-detail button {
		font-size: 16px;
	}

	.navbar-brand img {
		max-width: 140px;
		margin-top: -20px;
	}

	.navbar:before {
		display: none;
	}

	.after-login .navbar,
	.navbar {
		padding: 25px 10px !important;
	}

	.partner-listing img {
		max-width: 65px;
	}

	.footer-logo img {
		max-width: 150px;
	}

	.user-credential-box {
		position: absolute;
		top: unset;
		bottom: 150px;
		left: 50%;
		transform: translateX(-50%);
		min-height: 300px;
		width: 90%;
		background: transparent !important;
		padding: 0px !important;
	}
	.login-screen .tab-content .login-btn {
		margin-bottom: 50px;
	}
	.banner-area {
		position: relative;
	}

	.banner-area .row .col-md-5 {
		position: unset;
	}

	.sign-detail {
		min-height: 300px;
		justify-content: center;
		margin: 0;
		max-width: 90%;
		margin: 0 auto;
		padding-bottom: 50px;
	}

	.banner-footer.high-light ul {
		flex-direction: row !important;
		flex-wrap: wrap;
	}

	.banner-footer.high-light li {
		width: 50%;
		border-right: 2px solid #6E1038;
		border-bottom: 0;
		display: flex;
		align-items: center;
	}

	.sign-detail h2 {
		margin-bottom: 10px;
	}

	.banner-footer.high-light li:nth-child(even) {
		border: none;
	}

	.banner-area {
		min-height: 90vh;
		text-align: center;
	}

	.banner-area .rani-img {
		margin: 0;
		max-height: 45vh;
		margin-top: 20px !important;
	}

	#exampleModalLongTitle {
		margin-bottom: 10px !important;
		font-size: 18px;
	}

	.modal-verify-otp-wrap .modal-body h4 {
		font-size: 14px;
	}


	.modal-verify-otp-wrap .modal-body .form-group .form-control {
		padding: 7px 20px;
	}

	.modal-verify-otp-wrap .modal-body .form-group .resend-box {
		margin: 0px auto 5px !important;
	}

	.modal-verify-otp-wrap button.btn.theme-orange-btn {
		margin-bottom: 30px;
	}

	.navbar-toggler {
		position: fixed;
		top: 20px;
		right: 15px;
		outline: none;
	}

	.navbar-toggler:focus {
		outline: none;
		box-shadow: none;
	}

	.navbar-collapse {
		margin-top: 50px;
		background: #0f0626;
		padding: 15px 0 10px;
	}

	.before-login .navbar-collapse .navbar-nav>.nav-item {
		padding: 0 !important;
		margin-bottom: 15px;
		background: transparent;
	}

	.before-login .navbar-collapse .navbar-nav>.nav-item {
		background: transparent;
		text-align: center;
	}

	.before-login .navbar-collapse .navbar-nav>.nav-item a {
		color: #fff;

	}

	.download-btn {
		margin-right: -25px;
	}

	.download-btn>li {
		padding-right: 0 !important;
		padding-left: 0 !important;
		/* min-width: 180px; */
	}

	.download-btn>li>a {
		min-width: 180px;
	}

	header .navbar-collapse .nav-link.active,
	.navbar-expand-md .navbar-nav .nav-link:hover {
		background: transparent;
		color: #5ce7ff;
	}

	body.sticky .navbar .navbar-toggler {
		/* top: 10px !important;
		right: 6px; */
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		margin-top: 10px;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn {
		margin-top: 25px;
	}

	.points-tabs .nav-tabs,
	.points-tabs .tab-content.tab-inner .tab-pane .tab-content {
		max-width: 100%;
	}

	.privateTable-button {
		margin-top: 0;
		top: 130px;
		right: 10px;
		min-width: 250px;
		font-size: 18px;

	}

	.point-table.table-section {
		margin-top: -65px;
	}

	.points-rummy-inner-sec {
		max-width: 100%;
	}

	.point-rummy-sec {
		max-width: 90%;
		padding: 10px 15px;
		margin-top: 75px;
	}

	.point-table.table-section .container .tab-main {
		padding-bottom: 120px;
		padding-top: 15px;
		min-height: 600px;
		align-items: flex-end;
	}

	.lobby-headeing.table-heading {
		top: 28px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 310px;
		font-size: 24px;
	}

	.point-table.table-section .tab-main {
		height: auto;
	}

	.main-nav+.table-heading {
		max-width: 80%;
	}

	.footer-link-2 {
		bottom: -80px;
		right: 0;
		transform: none;
	}

	.footer-link ul,
	.footer-link-2 ul {
		flex-direction: row;
		flex-wrap: wrap;
	}
	.footer-link ul {
		gap : 0px;
		justify-content: center;
	}
	.footer-link ul li {
		margin: 2px 5px;
	}
	.footer-link {
		bottom: 70px;
		left: 0;
		transform: none;
	}

	.deposit-cash-box .blue-bg .prmotion-txt-box p {
		font-size: 14px;
		line-height: 18px;
	}

	.deposit-cash-box .form-group input {
		margin-bottom: 10px;
		width: 100%;
		max-width: 100%;
	}

	.yellow-buttons-box {
		display: none !important;
	}

	.deposit-cash-box h3 {
		margin-bottom: 15px;
		margin-top: 10px;
	}

	.prmotion-txt-box {
		margin-top: 0;
	}

	.deposit-cash-box p {
		font-size: 14px;
		line-height: 18px;
	}

	.about-us-content .text-right {
		text-align: left !important;
	}

	.report-inner-box label {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 5px;
	}

	.report-inner-box p {
		font-size: 12px;
		line-height: 16px;
	}

	.about-page-section .report-inner-box .problem-btn-box button {
		min-width: 130px;
		padding: 10px !important;
	}

	.report-probblem-sec.cmn-banner-bg.about-page-section {
		padding-top: 200px !important;
	}

	.contact-left-bar:after {
		display: none;
	}

	.contactForm {
		padding: 10px !important;
	}

	.contact-page-sec {
		background: #2f1440;
		padding-top: 20px !important;
		padding-bottom: 20px !important;
		margin-bottom: 50px !important;
		border-radius: 15px;
	}

	.contact-page-sec .row {
		flex-direction: column-reverse;
	}

	.about-page-section .page-title.contact-title {
		margin-bottom: 10px;
	}

	.kyc-document-box {
		flex-direction: column;
	}

	.after-login main, .after-login .main-content {
		padding-top: 150px;
	}

	.table-cnt ul li {
		min-width: 140px;
		padding: 10px 0;
	}
	.main-nav {
		background: #0f0626 !important;
	}
}

@media(min-width:576px) and (max-width :767px) {
	.dashboard-main-sec {
		padding: 25px 20px;
		padding-top: 200px;
		display: block;
	}

	.personal-detail .point-table {
		padding: 50px 50px 70px;
	}

	.personal-detail .table-heading {
		font-size: 18px;
		padding: 15px 0;
		top: -29px;
		max-width: 300px;
	}

	.personal-detail .point-table .tab-content {
		max-height: calc(100vh - 520px);
	}

	.table-cnt ul li {
		width: calc(50% - 10px);
	}

	.game-transaction-section .game-transaction-table {
		padding: 60px 70px 70px;
	}

	.dashboard-main-sec .game-transaction-heading {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 300px;
	}

	.passbook-wrap .point-table>.table-cnt {
		padding: 60px 50px 70px;
	}

	.passbook-section-heading {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 300px;
	}

	.passbook-wrap .point-table .table-cnt ul {
		padding: 0px;
		margin-bottom: 10px;
		gap: 24px 10px !important;
	}

	.table thead th {
		font-size: 16px;
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		padding: 10px 0;
		font-size: 16px;
	}

	.withdraw-page .account-title {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 240px;
	}

	.invite-wrap .invite-card {
		max-width: 100%;
	}

	.invite-wrap .account-title {
		font-size: 20px;
		padding: 15px 0;
		top: -19px;
		max-width: 240px;
	}

	.invite-wrap .kyc-body {
		max-width: 100%;
		padding: 50px 40px 30px;
	}

	.invite-box div:first-child img {
		width: 20px;
	}

	.invite-info-box img {
		width: 20px;
	}

	.invite-box h3 {
		font-size: 18px;
	}

	.invite-box .invite-img-box img {
		max-width: 180px;
	}

	.invite-box>a {
		margin-bottom: 5px;
	}

	.invite-box .google-btn button {
		width: 35px;
		height: 35px;
		font-size: 18px;
	}

	.invite-box .google-btn button svg {
		width: 100%;
	}

	.about-page-section .page-title {
		font-size: 20px;
		min-width: 280px;
		min-height: 55px;
	}

	.contact-area, .about-us-content,
	.faq-content {
		padding: 60px 50px 80px;
	}

	.faq-page .faq-title {
		font-size: 22px;
		padding: 18px 0;
		top: -35px;
		max-width: 200px;
	}

	.about-details-sec h3 {
		font-size: 22px !important;
		margin-bottom: 10px !important;
		line-height: 1 !important;
	}

	.how-to-play-page .how-to-play-title {
		font-size: 20px;
		padding: 15px 0;
		top: -30px;
		max-width: 240px;
	}

	.how-to-play-content {
		padding: 60px 50px 80px;
	}

	.lobby-headeing.table-heading {
		font-size: 20px;
		max-width: 260px;
	}

}

@media(min-width:320px) and (max-width :575px) {
	.dashboard-main-sec {
		padding: 25px 15px;
		padding-top: 200px;
		display: block;
	}



	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box {
		max-width: 100% !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div {
		width: 150px;
		right: 17px !important;
	}

	.pf-container {
		max-width: 100% !important;
	}

	.pf-quote {
		margin: 21px 75px 10px;
	}

	.pf-card {
		padding: 50px 0px !important;
	}

	.pf-avatar {
		width: 150px !important;
		height: 150px !important;
	}

	.pf-content {
		flex-direction: column;
	}

	.slick-dots {
		bottom: -35px !important;
		right: calc(-2%) !important;
	}

	.feature, .features-container {
		flex-direction: column;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box {
		margin: 0px !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>img {
		right: 0 !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>h1 {
		font-size: 15px !important;
	}



	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>img {

		top: 0px !important;
	}

	.personal-detail .point-table {
		padding: 35px 30px 60px;
	}

	.personal-detail .table-heading {
		font-size: 18px;
		padding: 15px 0;
		top: -29px;
		max-width: 178px;
	}

	.personal-detail .point-table .tab-content {
		max-height: calc(100vh - 600px);
	}

	.table-cnt ul li {
		/* width: calc(50% - 10px); */
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		font-size: 16px;
		padding: 15px 0;
	}
	.tab-content .user-dtl.user-dtl-box {
		padding: 10px;
	}
	.nav-items {
		flex-wrap: wrap;
	}
	.personal-detail .point-table .tab-content .tab-pane {
		max-height: calc(100vh - 650px);
	}
	.user-dtl .row .row {
		margin-top: 10px;
	}
	.account-inner-box {
		padding: 0px 10px;
	}
	.tab-content .acc-dtl {
		padding-top: 0;
		margin-bottom: 0;
	}
	.user-dtl .row .row>div {
		margin-top: 0;
		margin-bottom: 10px;
	}
	.common-btn {
		min-width: 120px;
		margin: 5px;
	}
	.side-btn.tournament-tab-wrap {
		padding-bottom: 15px;
	}
	.nav-items a {
		padding: 10px !important;
	}

	.nav-items li:not(:last-child)::after {
		right: -2px !important;
	}

	.transaction-btn-wrap {
		flex-direction: column;
		gap: 10px;
		align-items: center !important;
	}

	.transaction-btn-wrap .pagination-wrap {
		width: unset;
		margin: unset;
	}

	.pagination-wrap ul.pagination {
		padding: 0;
	}

	.transaction-btn-wrap .cust-dropdown-wrap {
		width: 100%;
	}

	.game-transaction-section .game-transaction-table {
		/* padding: 60px 30px 70px; */
	}
	.dashboard-inner-sec {}

	.dashboard-main-sec .game-transaction-heading {
		font-size: 18px;
		padding: 15px 0;
		top: -15px;
		max-width: 220px;
	}
	.game-transaction-section {
		padding-bottom: 30px;
	}

	.game-transaction-section .table-cnt-box{
		max-height: calc(100vh - 600px);
		min-height: 350px;
	}
	.pagination .page-item .prev-btn, .pagination .page-item .next-btn {
		min-width: 50px;
	}

	.table thead th,
	.game-transaction-section .table tbody tr td {
		font-size: 16px;
	}

	.passbook-wrap .point-table>.table-cnt {
		padding: 25px 30px 60px;
	}

	.passbook-section-heading {
		font-size: 18px;
		padding: 15px 0;
		top: -15px;
		max-width: 160px;
	}
	/* .account-cnt.passbook-wrap {
		max-height: calc(100vh - 400px);
	} */
	.withdraw-page {
		margin-left: 0;
		margin: 0 auto;
		margin-bottom: 25px;
	}
	.withdraw-blue-box {
		min-height: auto;
		margin-top: 25px;
	}
	.passbook-wrap .point-table .table-cnt ul {
		padding: 0px;
		margin-bottom: 10px;
		gap: 0px !important;
		justify-content: center !important;
	}
	.point-table .tab-pane.active .table-responsive td {
		padding: 8px 15px;
		font-size: 12px;
		line-height: 16px;
	}
	.passbook-wrap .table thead th {
		font-size: 14px;
		padding: 5px 10px;
	}
	.passbook-wrap .game-transaction-section .table-cnt-box {
		max-height:calc(100vh - 750px) ;
		min-height: 180px;
	}
	.transaction-btn-wrap {
		padding: 5px 0px;
	}
	.table thead th {
		font-size: 16px;
	}
	.passbook-wrap .point-table .table-cnt ul.pagination {
		margin-bottom: 0 !important;
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		padding: 10px 0;
		font-size: 16px;
	}

	.withdraw-blue-box {
		max-width: 100%;
	}

	.withdraw-page .account-title {
		font-size: 18px;
		padding: 15px 0;
		top: -18px;
		max-width: 210px;
	}

	.invite-wrap .invite-card {
		max-width: 80%;
		padding: 20px 40px 20px;
		margin-top: 10px;
		margin-bottom: 45px;
	}

	.invite-wrap .account-title {
		font-size: 18px;
		padding: 15px 0;
		top: -18px;
		max-width: 210px;
	}

	.invite-wrap .kyc-body {
		max-width: 100%;
		padding: 30px 10px 10px;
	}

	.invite-box div:first-child img {
		width: 15px;
		margin: 0;
		margin-right: 30px;
	}

	.invite-info-box {
		right: 10px;
		top: 10px;
	}

	.invite-info-box img {
		width: 15px;
	}

	.invite-box h3 {
		font-size: 15px;
		line-height: 1.5;
	}

	.invite-box .invite-img-box img {
		max-width: 120px;
	}

	.invite-box>a {
		margin-bottom: 5px;
	}

	.invite-box .google-btn button {
		width: 30px;
		height: 30px;
		font-size: 18px;
	}

	.invite-box .google-btn button svg {
		width: 100%;
	}

	.about-page-section .page-title {
		font-size: 18px;
		min-width: 210px;
		min-height: 50px;
	}

	.contact-area, .about-us-content,
	.faq-content {
		padding: 60px 30px 90px;
	}

	.faq-page .faq-title {
		font-size: 18px;
		padding: 15px 0;
		top: -28px;
		max-width: 180px;
	}

	.how-to-play-page .how-to-play-title {
		font-size: 18px;
		padding: 15px 0;
		top: -28px;
		max-width: 180px;
	}

	.how-to-play-content {
		padding: 60px 30px 90px;
	}

	.about-details-sec h3 {
		font-size: 20px !important;
		margin-bottom: 10px !important;
		line-height: 1 !important;
	}

	header.logged-in .collapse {
		display: block !important;
		position: fixed;
		width: 100%;
		left: 0;

	}

	header.logged-in .collapse ul {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		gap: 10px;
	}

	header.logged-in .collapse ul li {
		padding: 0px 5px !important;
		color: #fff;
	}

	header.logged-in .collapse ul li.username {
		position: absolute;
		top: -52px;
		right: 150px;
		padding-right: 0 !important;
	}

	header.logged-in .collapse ul li.username img {
		margin-right: 0px;
	}

	header.logged-in .collapse .red-button {
		position: absolute;
		top: -52px;
		right: 10px;
	}

	header.logged-in .navbar-toggler {
		display: none;
	}

	.navbar-nav>.nav-item.add-wallet>div span {
		padding: 0px 5px;
	}

	.navbar-nav>.nav-item.add-wallet img {
		max-width: 20px;
	}

	.navbar-nav>.nav-item.add-wallet span {
		font-size: 16px;
	}

	.navbar-nav>.nav-item.add-wallet .wallet-sec {
		margin: 0;
	}

	header.logged-in .navbar-collapse {
		padding: 8px 0px;
	}

	header.logged-in .navbar-expand-md .navbar-nav .nav-link {
		color: #fff;
	}

	.points-rummy-section {
		margin-top: 50px !important;
	}

	.main-nav {
		top: 60px !important;
	}
	.table-cnt ul.nav-tabs li a.nav-link {
		font-size: 16px !important;
		padding: 8px 0 !important;
	}

	.dashboard-main-sec {
		padding-top: 250px;
	}

	.scollbar-box {
		display: none !important;
	}

	.deposit-cash-box {
		background-size: 100% 100%;
		min-height: 470px;
		padding-top: 120px;
	}
	.deposit-cash-box p {
		margin-top: 15px;
	}
	.deposit-wrap h2 {
		/* max-width: 80%; */
		min-width: 80%;
		background-size: 100% 100%;
	}
	#mountNode h2 {
		font-size: 14px;
		line-height: 18px;
	}

	.joinTableModal .old-game-wrap {
		margin-bottom: 30px;
	}

	.myJoinTableModalWrap .modal-body {
		padding: 80px 20px;
	}

	.privateTable-button {
		min-width: 300px;
		margin-bottom: -10px;
		font-size: 14px;
	}

	.points-tabs .tab-content.tab-inner .tab-pane .tab-content .play-btn {
		font-size: 22px;
	}

	.main-nav+.table-heading {
		font-size: 20px;
		padding: 20px 0;
		left: 0;
		transform: none;
	}
	.account-cnt {
		margin-top: 15px;
		max-width: 100%;
	}
	.game-transaction-section {
		margin-left: 0;
		max-width: 100%;
		
		max-width: 90%;
		margin: 20px auto;
		/* margin-top: 20px; */
	}
	.navbar-expand-md .navbar-nav .nav-link {
		font-size: 12px !important;
	}

	header.logged-in .collapse ul li.username {
		max-width: 100px;
	}

	.points-rummy-section {
		margin-top: 0 !important;
	}

	.sign-detail form {
		max-width: 100%;
	}

	.sign-detail {
		padding: 0 10px !important;
	}

	.login-screen {
		min-height: 465px;
		/* padding: 30px 0px; */
	}

	.register-detail .form-control, .signup-screen .form-control {
		margin-bottom: 10px !important;
	}

	.signup-screen .nav-tabs {
		margin-top: 2px;
	}

	.coomon-popup-sec {
		padding: 30px 30px 40px;
	}

	.ForgetPasswordWrap .coomon-popup-sec h4 {
		max-width: 90%;
	}

	.modal-verify-otp-wrap .coomon-popup-sec {
		min-height: 300px;
	}

	.modal-verify-otp-wrap .coomon-popup-sec .sign-detail {
		min-height: unset;
	}

	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		min-width: auto;
	}

	.modal-verify-otp-wrap button.btn.theme-orange-btn {
		margin-bottom: 0;
	}

	.new-create-box {
		margin-top: 10px;
	}

	.coomon-popup-sec.confirmation-popup {
		min-height: 230px;
	}

	.gender-grp div {
		margin-bottom: 5px !important;
	}

	.high-light-banner {
		height: 300px !important;
		margin: -30px 0px -50px 0px !important
	}

	.high-light-banner>div>h1:first-child {
		font-size: 20px !important;
	}

	.high-light-banner>div>h1:last-child {
		font-size: 14px !important;
	}

	.high-light-banner>.img {
		margin-top: 12px !important;
	}

	.download-btn a {
		padding: 6px 24px !important;
	}

	.download-btn>li>a {
		min-width: 0px !important;
	}

	.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>h1 {
		width: 222px !important;
	}
	#mountNode {
		max-width: 100%;
		padding: 10px;
	}
}

@media (max-width :360px) {
	.modal-verify-otp-wrap .modal-body .form-group.verify-box {
		min-width: 100%;
	}
	.nav-items a {
		font-size: 12px !important;
		padding: 7px !important;
	}

	.modal-verify-otp-wrap .modal-body h4 {
		font-size: 12px;
		line-height: 14px;
	}

	.modal-dialog .modal-content .modal-body button,
	.modal-dialog .modal-content .modal-body button.resend-otp-btn, .resend-box p {
		font-size: 14px !important;
		line-height: 18px !important;
	}

	.modal-dialog .modal-content .modal-body button {
		min-height: 20px;
	}

	.modal-verify-otp-wrap .modal-body .form-group .resend-box {
		margin-bottom: 12px !important;
	}

	#exampleModalLongTitle {
		margin-top: 0 !important;
	}

	.navbar-brand img {
		max-width: 120px;
	}

	.points-rummy-section {
		margin-top: 0 !important;
	}
}

@media (min-height: 300px) and (max-height: 500px) {
	.personal-detail .point-table {
		padding: 50px 70px 70px;
	}

	.personal-detail .point-table .tab-content {
		max-height: calc(100vh - 200px);
	}

	.table-cnt ul {
		gap: 10px;
	}

	.table-cnt ul li {
		width: calc((100% / 3) - 10px);
	}

	.table-cnt ul.nav-tabs li a.nav-link {
		font-size: 18px;
		padding: 16px 0;
	}

}

iframe {
	display: none !important;
}

.dashboard-inner-sec {
	display: flex;
	background: url('../images/dashboard-bg.png')no-repeat center center/100% 100%;
	position: relative;
	padding-top: 50px;
	width: 100%;
}

.main-nav {
	background-color: transparent;
	/* border-top: 2px solid #ffd700; */
	/* border-bottom: 2px solid #ffd700; */
	padding: 0;
	/* position: fixed; */
	/* width: 100%; */
	top: 75px;
	left: 0;
	z-index: 999;
	min-width: 250px;
	padding-left: 40px;
	padding-top: 80px;
}

.nav-items {
	list-style-type: none;
	display: flex;
	margin: 0;
	padding: 0;
	justify-content: center;
	flex-direction: column;
}

.nav-items li {
	margin: 0;
	position: relative;
	margin-bottom: 15px;
}

.nav-items li:not(:last-child)::after {
	content: '|';
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	color: #ffd700;
	display: none;
}

.nav-items a {
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	padding: 20px 20px;
	display: block;
	text-align: center;
	background: url('../images/dash-list-bg.png')no-repeat center center/100% 100%;
	font-family: "GrotaSansRd";
	text-shadow: 3px 0 #3c5c8a, -3px 0 #3c5c8a, 0 3px #3c5c8a, 0 -3px #3c5c8a,
		2px 2px #3c5c8a, -2px -2px #3c5c8a, 2px -2px #3c5c8a, -2px 2px #3c5c8a;
}
.nav-items a.active {
	background: url('../images/dash-list-bg-mobile-active.png') no-repeat center center/100% 100% !important;
}
.nav-items a:hover {
	/* background-color: #4a0e1e; */
}

.nav-items a.active {
	color: #fff;
	background: url('../images/dash-list-bg-active.png')no-repeat center center/100% 100%;
}

.account-cnt {
	flex-grow: 1;
}

.about-details-sec h3 {
	font-size: 26px;
	line-height: 30px;
	margin-bottom: 10px;
}

.about-details-sec h5 {
	font-size: 18px;
	margin-bottom: 20px;
}

.about-details-sec p {
	margin-top: 0 !important;
	margin-bottom: 20px !important;
	font-size: 14px;
}

.about-details-sec ul {
	padding-left: 30px;
	list-style-type: disc;
	margin-bottom: 20px;
}

.about-details-sec ul li {
	font-size: 16px;
	margin-bottom: 5px;
}

.about-details-sec ul li span {
	font-weight: 700;
}

.color-red {
	color: red;
}

.color-green {
	color: green;
}

.kyc-status p {
	font-size: 16px;
	color: #e8be44 !important;
}

.report-probblem-sec.cmn-banner-bg.about-page-section {
	padding-top: 150px !important;
	text-align: center;
	min-height: 100vh;
}

.report-probblem-sec.about-page-section .report-inner-box {
	margin: 0;
	max-width: 100%;
	margin-top: -10px;
}

.report-probblem-sec.about-page-section .report-inner-box .form-group {
	margin-bottom: 20px;
	padding: 0px;
}

.report-probblem-sec.about-page-section .report-inner-box .form-group textarea {
	height: 150px;
}

.report-probblem-sec.about-page-section .report-inner-box .form-group textarea:focus {
	background: rgba(76, 14, 42, 1);
	color: #fff;
	border-color: rgba(255, 255, 255, 0.5);
}

.report-probblem-sec.about-page-section .select-custom__indicators {
	background: url('../images/icons/down-arrow.png')no-repeat center center/cover !important;
	width: 20px;
	height: 10px;
	margin-top: 10px;
}

.report-inner-box p.error-text {
	margin-top: 5px;
	text-align: left;
	color: red !important;
}

.report-probblem-sec.about-page-section .text-left {
	text-align: left;
}

.report-probblem-sec.about-page-section .attchmets-sec {
	text-align: left;
}

.file-wrapper .file-details p {
	color: #fff;
}

.report-inner-box .close-btn {
	filter: brightness(0) invert(1);
}

.report-inner-box .problem-btn-box {
	margin-top: 25px;
}

.report-inner-box .problem-btn-box button {
	padding: 12px 30px !important;
	display: block;
	color: #3B0E20;
	font-size: 18px;
	line-height: 22px;
	background: #01875F;
	text-align: center;
	border-radius: 8px;
	background: #5ce7ff !important;
	font-family: "MyriadPro";
	font-weight: 700;
	box-shadow: none;
	margin: 0px 10px;
	min-width: 150px;
}

.report-inner-box .problem-btn-box button.backgroundbRedBtn {
	color: #fff;
	background: rgba(76, 14, 42, 1) !important;
}

.maintainace-bg {
	border: none;
}

.maintainace-bg.modal-body::before {
	display: none;
}

.maintainace-bg .txt-msg-box {
	margin-top: 20px;
	font-family: 'Source Serif Pro', serif;
	;
}

.maintainace-bg button span {
	padding: 9px 30px !important;
	display: block;
	color: #3B0E20;
	font-size: 18px !important;
	line-height: 22px !important;
	text-align: center;
	border-radius: 8px;
	background: #5ce7ff;
	font-family: "MyriadPro";
	font-weight: 700;
	/* min-width: 130px; */
	min-height: 40px;
}

.modal-dialog.logout-modal .new-create-box button {
	min-width: 130px;
}

.verify-header {
	font-size: 24px;
	font-weight: 900;
	color: #5ce7ff;
	margin-bottom: 20px;
	display: block;
}

.kyc-documents div {
	font-size: 18px;
	font-weight: 900;
	margin-bottom: 20px;
}

.kyc-documents span {
	font-size: 18px;
	font-weight: 900;
	color: #5ce7ff;
	margin-right: 5px;
}







.contactForm {
	padding: 40px;
	border-radius: 20px;
	color: #ffffff;
	font-family: 'Arial', sans-serif;
}

.contactForm h2 {
	color: #ffd700;
	font-size: 28px;
	margin-bottom: 30px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	border-bottom: 2px solid #ffd700;
	padding-bottom: 10px;
}

.formGroup {
	margin-bottom: 25px;
	position: relative;
}

.formGroup label {
	display: block;
	margin-bottom: 5px;
	color: #fff;
	font-size: 16px;
	transition: all 0.3s ease;
	text-align: justify;
}

.formGroup input,
.formGroup textarea {
	width: 100%;
	padding: 15px;
	border: 2px solid #5ce7ff;
	background-color: rgba(255, 215, 0, 0.1);
	color: #ffffff;
	font-size: 16px;
	transition: all 0.3s ease;
	border-radius: 8px;
}

.formGroup input:focus,
.formGroup textarea:focus {
	background-color: rgba(255, 215, 0, 0.2);
	border-color: #ffffff;
	outline: none;
}

.formGroup textarea {
	height: 120px;
	resize: vertical;
}

.submitButton {
	width: 180px;
	padding: 16px 10px !important;
	background-color: #2b875f;
	color: #fff;
	border: none;
	border-radius: 8px;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: auto;
	display: block;
	background-color: #5ce7ff;
	text-shadow: 3px 0 #081C96, -3px 0 #081C96, 0 3px #081C96, 0 -3px #081C96, 2px 2px #081C96, -2px -2px #081C96, 2px -2px #081C96, -2px 2px #081C96;
	font-family: "GrotaSansRd" !important;
	font-weight: 700;
}

.submitButton:hover {
	/* background-color: #ffffff;
	color: #4a0823; */
	/* transform: translateY(-3px);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}

@media (max-width: 600px) {
	.contactForm {
		padding: 30px;
	}

	.contactForm h2 {
		font-size: 24px;
	}

	.formGroup input,
	.formGroup textarea,
	.submitButton {
		font-size: 14px;
	}
}

.error-message {
	color: red;
	font-size: 1em;
	margin-top: 5px;
	text-align: justify;
}

.high-light-banner {
	padding: 25px 0px 0px;
	height: 600px;
	background: url(../images/background-4.png) no-repeat center center / 103%;
	place-content: center;
	text-align: center;

}

.high-light-banner>div {
	color: #000;
	bottom: 31px;
	position: relative;
}

.high-light-banner>div>h1:last-child {
	color: #8154fa;
	font-size: 25px;
}

.high-light-banner>div>h1:first-child {
	font-size: 40px;
}

.high-light-banner>.img {
	margin: auto;
	width: 50%;
	margin-top: 46px;

}

.slider-bar {
	background: url(../images/background-6.png) center center / cover;

}

.pf-container {
	max-width: 70%;
	margin: 0 auto;
	padding: 20px;

}

.pf-container>h1 {
	text-align: center;
	margin-bottom: 15px;
}

.pf-card {
	background-color: #eeeeee;
	border-radius: 25px;
	padding: 70px 83px;
	position: relative;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 40px;
}

.pf-content {
	display: flex;
	align-items: flex-start;
}

.pf-profile {
	position: relative;
	margin-right: 20px;
}

.pf-avatar {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	border: 6px solid white;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	object-fit: cover;
	object-position: top center;
}

.pf-name-badge {
	background-color: #4a0e4e;
	color: white;
	padding: 12px 30px;
	position: absolute;
	bottom: -8px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	background: url(../images/background-cover.png) no-repeat center center / 100% auto;

}

.pf-name-badge p {
	margin: 0;
	font-size: 18px;
	font-weight: bold;
}

.pf-text {
	flex: 1;
}

.pf-quote {
	margin: 21px 75px 10px;
	font-size: 1.2em;

}

.pf-cta {
	font-weight: bold;
	margin-bottom: 10px;
	margin-left: 80px;
	top: 19px;
	position: relative;
	font-size: 1.2em;

}

.pf-rating {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 70px;
	left: 390px;
	width: 74px;
}

.pf-star {
	color: gold;
	font-size: 60px;
	margin-right: 5px;
}

.pf-rating-value {
	font-size: 50px;
	color: #3b0f9e;
}

.pf-dot {
	width: 8px;
	height: 8px;
	background-color: #4a0e4e;
	border-radius: 50%;
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
}

/* Slider customization */
.slick-dots {
	bottom: 30px;
	right: calc(35%)
}

.slick-dots li button:before {
	font-size: 12px;
}

.slick-dots li.slick-active button:before {
	color: #1e0838;
}

learn-rummy-overview>.play-rummy-online>.game-overview {
	margin: 0px;
	height: 400px;
}

.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box {
	max-width: 65%;
	background-color: #0f0626;
	margin: 120px auto;
	border-radius: 15px;
	max-height: 190px;
	display: flex;
	color: whitesmoke;
}

.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div {
	width: 260px;
	margin: auto;
}

.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>img {
	width: 100%;
	position: relative;
	top: -130px;
	right: 70px;
}

.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>ul>li {
	margin: auto !important;
}

.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>ul>li>a {
	background-color: #5ce7ff !important;
	color: #fff !important;
}

.learn-rummy-overview>.play-rummy-online>.game-overview>.learn-box>div>h1 {
	line-height: 36px;
	margin-bottom: 15px;
	width: 256px;
}


.game-overview {
	background: #fff;
	padding: 25px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
	text-align: center;
	border-radius: 15px;
	margin-top: 22px;
}

.game-overview h3 {
	margin-bottom: 25px;
	text-transform: capitalize;
	color: #0f0626;
	font-family: "  Serif 4", serif;
	font-size: 30px;
	font-weight: 600 !important;
}

.game-overview p {
	color: var(--primary-color);
	text-align: justify;
	font-weight: 600;
	text-align: center;
	font-family: "MyriadPro";
	font-size: 15px;
	margin-bottom: 15px;
}

.play-rummy-online>.game-overview {
	margin: 40px 0 0 0px;
	background: #e4e3e3 !important;
	border-radius: 0px;
}

.rr-info-tabs p {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
}

.game-overview p {
	color: black;
	max-width: 80%;
	margin: auto;
	margin-top: 15px;
}

.tabbable .nav-tabs {
	flex-direction: column;
	margin-right: 30px;
	width: 100%;
	max-width: 350px;
	border: 0px;
}

.play-rummy-online>.game-overview {
	margin: 40px 0 0 0px;
	background: #d4d4d4;
	border-radius: 0px;
}

.download-btn-home>ul {
	margin-top: 10px;
}

.download-btn-home {
	text-align: center;
}

.download-icon {
	margin-right: 5px;
	font-weight: bold;
}

.download-btn-home>ul>li {
	margin: auto;
	margin-top: 10px;
}

.download-btn-home>div {
	color: #1b0a48;
	font-size: 22px;
	font-weight: 900;
}

.download-btn-home>ul>li>a {
	background-color: #5ce7ff !important;
}

.features-container {
	display: flex;
	justify-content: space-around;
	padding: 20px;
	background-color: #0f0626;
}

.feature {
	display: flex;
	align-items: center;
	padding: 15px;

}

.feature img {
	width: 80px;
	height: 80px;
	margin-right: 15px;
	border: 2px solid #615076;
	padding: 4px;
}

.feature-text {
	color: white;
}

.feature-text h3 {
	margin: 0;
	font-size: 18px;
}

.feature-text p {
	margin: 5px 0 0;
	font-size: 14px;
}

.pf-container>div>button {
	background-color: #0f0826;
}
iframe.payment-iframe {
	display: block !important;
	max-width: 100%;
	max-height: 80vh;
}

.social-links li a { font-size: 40px; color: #fff; }
.social-links li a:hover,
.social-links li a:focus {
	color: #5ce7ff;
}
@media (max-width :767px){
	.social-links {
		margin-top: 10px !important;
	}
	.social-links li a {
		font-size: 35px;
	}
	.m-pt-5 {
		padding-top: 40px;
	}
}